import { Column, ColumnAlign } from '@dock/react';

import cardDetailsLang from '../../../../lang/cardDetails';

export const cardTransactionsColumns: Column[] = [
    { key: 'expand', name: '' },
    {
        key: 'transactionId',
        name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.TX_ID,
    },
    { key: 'status', name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.STATUS },
    {
        align: ColumnAlign.RIGHT,
        key: 'amount',
        name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.AMOUNT,
    },
    { key: 'currency', name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.CURRENCY },
    {
        key: 'completedTime',
        name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.COMPLETED_TIME,
        sortable: true,
    },
    {
        key: 'paymentSource',
        name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.PAYMENT_SOURCE,
    },
    {
        key: 'merchantName',
        name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.MERCHANT_NAME,
    },
    { key: 'type', name: cardDetailsLang.CARD_TRANSACTIONS_TABLE.TYPE },
    { key: 'actions', name: '' },
];
