import { useParams } from 'react-router-dom';

import { PageContainer } from '@dock/react';

import { CardAuthorizationDetailTable } from './components/CardAuthorizationDetailTable';
import { CardAuthorizationEventsDetail } from './components/CardAuthorizationEventDetail';

export function CardAuthorizationDetails() {
    const { authorizationId = '', id: cardId = '' } = useParams();

    return (
        <PageContainer testId="cardAuthorizationDetailsPage">
            <CardAuthorizationDetailTable cardId={cardId} authorizationID={authorizationId} />
            <CardAuthorizationEventsDetail cardId={cardId} authorizationID={authorizationId} />
        </PageContainer>
    );
}
