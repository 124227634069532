import { BankTransferBankTransferTransactionSepaKind } from '@dock/types-dock-partner';

import bankTransferDetailsLang from '../../lang/bankTransferDetails';

export const bankTransferTransactionSepaKindKindMap: Record<
    BankTransferBankTransferTransactionSepaKind,
    string
> = {
    CREDIT_TRANSFER: bankTransferDetailsLang.CREDIT_TRANSFER,
    DIRECT_DEBIT: bankTransferDetailsLang.DIRECT_DEBIT,
    INSTANT_CREDIT_TRANSFER: bankTransferDetailsLang.INSTANT_CREDIT_TRANSFER,
};
