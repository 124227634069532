import { Box } from '@mui/material';

import { prefillDatesFromAlways } from '@dock/common';
import { SearchBox, SimpleTable, FilterButton, DateRangeDropDown } from '@dock/react';
import { useModal } from '@dock/react-hooks';

import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import accountsLang from '../../../../lang/accounts';
import { useAccountsList } from '../../../../services';
import { AccountFilterModal } from './AccountFilterModal';
import { AccountsRow } from './AccountsRow';
import { accountsTableColumns } from './accountsTableColumns';
import { transformAccountsListParams } from './transformAccountsListParams';
import { useAccountsListFilters } from './useAccountsListFilters';

export function AccountsTable() {
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useAccountsListFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useAccountsList({
        params: transformAccountsListParams(filters),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={accountsLang.SEARCH_FOR_ACCOUNT_ID}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />

                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <AccountFilterModal
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                    status: filters.status || [],
                }}
            />
            <SimpleTable
                columns={accountsTableColumns}
                RowComponent={AccountsRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                error={error}
                onReachingBottom={fetchNextPage}
                onSortClick={handleSortOrder}
                sorting={sorting}
            />
        </>
    );
}
