import {
    listBankTransferHolders,
    listBankTransferInstruments,
    createBankTransferInstrument as createBankTransferInstrumentService,
    createBankTransferAccountHolder as createBankTransferAccountHolderService,
    listTransactions,
} from '@dock/services';

import { apiGet, apiPost } from './instance';

export const getBankTransfersInstruments = apiGet(listBankTransferInstruments);
export const getBankTransfersTransactions = apiGet(listTransactions);
export const getBankTransfersHolders = apiGet(listBankTransferHolders);
export const createBankTransferInstrument = apiPost(createBankTransferInstrumentService);
export const createBankTransferAccountHolder = apiPost(createBankTransferAccountHolderService);
