export default {
    CARD_AUTHORIZATIONS_EVENTS_TABLE: {
        AMOUNT: 'Amount',
        AUTHORIZATION_ID: 'Authorization ID',
        CARD_ID: 'Card ID',
        CURRENCY: 'Currency',
        OUTCOME_REASON: 'Outcome reason',
        STATUS: 'Status',
        TIME_INITIATED: 'Time initiated',
        TYPE: 'Type',
    },
    CARD_AUTHORIZATIONS_TABLE: {
        AMOUNT: 'Amount',
        AUTHORIZATION_ID: 'Authorization ID',
        CARD_ID: 'Card ID',
        CURRENCY: 'Currency',
        MERCHANT_NAME: 'Merchant name',
        PAYMENT_SOURCE: 'Payment source',
        STATUS: 'Status',
        TIME_INITIATED: 'Time initiated',
        TIME_OF_LAST_UPDATE: 'Time of last update',
    },
    CARD_TRANSACTIONS_TABLE: {
        AMOUNT: 'Amount',
        AUTHORIZATION_ID: 'Authorization ID',
        CARD_ID: 'Card ID',
        COMPLETED_TIME: 'Completed time',
        CURRENCY: 'Currency',
        INITIATED_TIME: 'Initiated time',
        MCC: 'MCC',
        MERCHANT_NAME: 'Merchant name',
        MID: 'MID',
        PAYMENT_SOURCE: 'Payment source',
        STATUS: 'Status',
        TRANSACTION_ID: 'Transaction ID',
        TYPE: 'Type',
    },
    CARD_TXS_OVERVIEW_TITLE: 'Card transactions overview',
    SEARCH_OPTION: {
        AUTHORIZATION_ID: 'Authorization ID',
        CARD_ID: 'Card ID',
        TRANSACTION_ID: 'Transaction ID',
    },
    TABS: {
        AUTHORIZATIONS: 'Authorizations',
        AUTHORIZATIONS_EVENTS: 'Authorizations events',
        TRANSACTIONS: 'Transactions',
    },
};
