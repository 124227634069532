import { GenericServiceError } from '@dock/common';
import { CardsCreateVelocityControl, CreateVelocityControlData } from '@dock/types-dock-partner';

import { createCardVCHandler } from '../../../client';

export const createCardVC = async (
    payload: CardsCreateVelocityControl,
    idempotencyKey: string
): Promise<CreateVelocityControlData> => {
    const headers = {
        'idempotency-key': idempotencyKey,
    };

    const errorOrResponse = await createCardVCHandler({ headers, payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
