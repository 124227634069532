import { ProvideAlertNotifications } from '@dock/react';

import { AppRoot } from './AppRoot';
import { ProvideAuth } from './auth/ProvideAuth';
import { ProvideStatusModals } from './common';
import { Routes } from './routes/Routes';

export default function App() {
    return (
        <AppRoot>
            <ProvideAlertNotifications>
                <ProvideStatusModals>
                    <ProvideAuth>
                        <Routes />
                    </ProvideAuth>
                </ProvideStatusModals>
            </ProvideAlertNotifications>
        </AppRoot>
    );
}
