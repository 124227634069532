import {
    AuthCreateChallenge,
    CreateCryptoChallengeData,
    AuthVerifyChallenge,
    VerifyCryptoChallengeData,
} from '@dock/types-dock-partner';

import { getData } from '../../extractors';
import { handleServiceRequest } from '../handleServiceRequest';
import { AuthenticationAPI } from './authenticationInstance';

export const createCryptokeyChallenge = async (
    data: AuthCreateChallenge
): Promise<CreateCryptoChallengeData> =>
    handleServiceRequest(AuthenticationAPI.createCryptoChallenge, data);

export const verifyChallenge = async (
    id: string,
    data: AuthVerifyChallenge
): Promise<VerifyCryptoChallengeData> => {
    const response = await AuthenticationAPI.verifyCryptoChallenge(id, data);

    return getData<VerifyCryptoChallengeData>(response);
};
