import { useMutation } from '@tanstack/react-query';

import { UpdateVelocityControlData } from '@dock/types-dock-partner';

import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { VELOCITY_CONTROL_KEY } from '../../keys';
import { updateCardVelocityControl, UpdateCardVCParams } from '../methods/cards/updateCardVC';
import { updateTokensOnMutate } from '../updateTokensOnMutate';

export function useUpdateCardVC() {
    return useMutation<UpdateVelocityControlData, PartnerServiceError, UpdateCardVCParams>(
        ({ id, idempotencyKey, payload }) =>
            updateCardVelocityControl({
                id,
                idempotencyKey,
                payload,
            }),
        {
            onMutate: updateTokensOnMutate,
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: [VELOCITY_CONTROL_KEY],
                });
            },
        }
    );
}
