import { Breadcrumbs } from '@dock/react';

import { useMatchedPath } from '../hooks';

export function BreadcrumbsWrapper() {
    const matchedPath = useMatchedPath();

    return (
        <Breadcrumbs
            explicitBreadcrumbs={matchedPath?.explicitBreadcrumbs || []}
            explicitLinksForBreadcrumbs={matchedPath?.explicitLinksForBreadcrumbs || []}
        />
    );
}
