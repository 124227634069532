import {
    getCardsList as getCardsListService,
    getCardHoldersList as getCardHoldersListService,
    getVelocityControlList as VelocityControlService,
    getCardsAuthorizations as getCardsAuthorizationsService,
    getCardsAuthorizationsEvents as getCardsAuthorizationsEventsCommonService,
    getCardDetails as getCardDetailsService,
    getCardsTransactions as getCardsTransactionsService,
    createNewCard as createNewCardService,
    updateCardStatus as updateCardStatusService,
    createCardVC as createCardVCService,
    updateCardVC as updateCardVCService,
} from '@dock/services';

import { apiGet, apiPost, apiPut } from './instance';

export const getCardsList = apiGet(getCardsListService);

export const getVelocityControlList = apiGet(VelocityControlService);

export const getCardsAuthorizations = apiGet(getCardsAuthorizationsService);

export const getCardsAuthorizationsEvents = apiGet(getCardsAuthorizationsEventsCommonService);

export const getCardDetails = (id: string) => apiGet(getCardDetailsService(id))({});

export const updateCardStatus = (id?: string) => apiPost(updateCardStatusService(id));

export const getCardsTransactions = apiGet(getCardsTransactionsService);

export const createNewCardHandler = apiPost(createNewCardService);

export const createCardVCHandler = apiPost(createCardVCService);

export const getCardHoldersList = apiGet(getCardHoldersListService);

export const updateCardVC = (id: string) => apiPut(updateCardVCService(id));
