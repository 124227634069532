import {
    A2ATransfersPaymentEventError,
    A2ATransfersPaymentEventKind,
} from '@dock/types-dock-partner';

import { ChipColors } from '../../../../common';

export const a2APaymentEventKindColorMap: Record<
    Exclude<A2ATransfersPaymentEventKind, { ERROR: A2ATransfersPaymentEventError }>,
    ChipColors
> = {
    COMPLETED: ChipColors.success,
    DISABLED: ChipColors.default,
    EXECUTED: ChipColors.warning,
    PENDING: ChipColors.warning,
    SCHEDULED: ChipColors.warning,
};

export const a2APaymentEventKindErrorColorMap: Record<A2ATransfersPaymentEventError, ChipColors> = {
    INSUFFICIENT_FUNDS: ChipColors.error,
    INTERNAL: ChipColors.error,
    LOCKED: ChipColors.error,
};
