import { ResponseWithContinuationToken } from '@dock/services';
import {
    ListTransactionsData,
    AccountsPaymentMethod,
    ListCardTransactionsData,
    BankTransferBankTransferTransaction,
    ListTransactionsParams,
} from '@dock/types-dock-partner';

import { PagedServiceParams } from '../../../common';
import { fetchAccountsList, fetchTransactionsList } from '../../api';
import { filterTransactionsByMethod } from '../filterTransactionsByMethod';
import { getAccountIds } from '../getAccountIds';
import { getTransactionIds } from '../getTransactionIds';
import { ExtendedAccountTransaction, getTXsWithCounterparty } from '../getTXsWithCounterparty';
import {
    ExtendedA2ATransaction,
    mergeA2ATransactionsAndAccountName,
} from '../mergeA2ATransactionsAndAccountName';
import { fetchA2ATransactions } from './fetchA2ATransactions';
import { fetchBankTransfersTransactions } from './fetchBankTransfersTransactions';
import { fetchCardTransactions } from './fetchCardTransactions';

type FetchAccountTransactionsList = PagedServiceParams<ListTransactionsParams>;

export type FetchAccountTXsWithCounterparty = ResponseWithContinuationToken<
    ExtendedAccountTransaction[]
>;

export const fetchAccountTransactionsWithCounterparty = async ({
    pageParam = undefined,
    params,
}: FetchAccountTransactionsList): Promise<FetchAccountTXsWithCounterparty> => {
    let filteredCardTransactions: ListTransactionsData = [];
    let filteredA2ATransactions: ListTransactionsData = [];
    let filteredBankTransferTransactions: ListTransactionsData = [];

    let cardTransactionsData: ListCardTransactionsData = [];
    let a2aTransactionsData: ExtendedA2ATransaction[] = [];
    let btTransactionsData: BankTransferBankTransferTransaction[] = [];

    const { _size: responseSize } = params;

    // 1. Fetch account transactions
    const transactionsOrError = await fetchTransactionsList({
        pageParam,
        params,
    });

    // 2. Filter account transactions by AccountsPaymentMethod
    filteredCardTransactions = filterTransactionsByMethod(
        transactionsOrError.data,
        AccountsPaymentMethod.CARD
    );
    filteredA2ATransactions = filterTransactionsByMethod(
        transactionsOrError.data,
        AccountsPaymentMethod.A2ATRANSFER
    );
    filteredBankTransferTransactions = filterTransactionsByMethod(
        transactionsOrError.data,
        AccountsPaymentMethod.BANK_TRANSFER
    );

    // 3. Fetch transactions details data for each domain
    if (filteredCardTransactions.length) {
        const cardTXIds = getTransactionIds(filteredCardTransactions);
        const cardTxs = await fetchCardTransactions({
            params: { _size: responseSize, id: cardTXIds },
        });
        cardTransactionsData = cardTxs.data;
    }

    if (filteredA2ATransactions.length) {
        const a2aTXIds = getTransactionIds(filteredA2ATransactions);
        const a2aTXs = await fetchA2ATransactions({
            params: { _size: responseSize, id: a2aTXIds },
        });
        // 3.1. Fetch account details data for each a2a transaction (accountName is needed as counterparty)
        const accountIds = getAccountIds(a2aTXs.data);
        const accountsData = await fetchAccountsList({
            params: { _size: responseSize, id: accountIds },
        });
        a2aTransactionsData = mergeA2ATransactionsAndAccountName(a2aTXs.data, accountsData.data);
    }

    if (filteredBankTransferTransactions.length) {
        const btTXIds = getTransactionIds(filteredBankTransferTransactions);
        const btTransactions = await fetchBankTransfersTransactions({
            params: { _size: responseSize, id: btTXIds },
        });
        btTransactionsData = btTransactions.data;
    }

    // 5. Add counterparty field to the final data
    const txsWithCounterparty = getTXsWithCounterparty(
        transactionsOrError.data,
        cardTransactionsData,
        a2aTransactionsData,
        btTransactionsData
    );

    return {
        continuationToken: transactionsOrError.continuationToken,
        data: txsWithCounterparty,
    };
};
