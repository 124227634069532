import { commonBL, getAccessTokenFromStorage } from '@dock/common';
import { is401Error } from '@dock/services';

export const clientBL = {
    shouldRetry: (failureCount: number, error: unknown): boolean =>
        !(is401Error(error) || failureCount >= 1),
    shouldUpdateTokensOnMutate: (): boolean => {
        const accessToken = getAccessTokenFromStorage();

        return !!accessToken && !!accessToken.token && commonBL.isTokenExpired(accessToken);
    },
};
