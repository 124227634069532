import React from 'react';

import { Modal } from '@dock/react';

import commonLang from '../../../lang/common';
import { ErrorStatusContent } from './ErrorStatusContent';

type RequestStatusModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    statusMessages: string[] | React.ReactElement[];
    onChangeClick: () => void;
    onSubmitAgainClick: () => void;
};

export function ErrorStatusModal({
    handleClose,
    isOpen,
    onChangeClick,
    onSubmitAgainClick,
    statusMessages,
}: RequestStatusModalProps) {
    return (
        <Modal
            handleClose={handleClose}
            isOpen={isOpen}
            modalAriaTitle={commonLang.STATUS_MODALS.ARIA_TITLE}
            testId="statusModalError"
            modalStyles={{ minWidth: '450px' }}
        >
            <ErrorStatusContent
                statusMessages={statusMessages}
                onSubmitAgainClick={onSubmitAgainClick}
                onChangeClick={onChangeClick}
            />
        </Modal>
    );
}
