export default {
    AMOUNT_DESCRIPTION: 'Requested merchant amount associated with currency.',
    APPROVED_AMOUNT: 'Approved amount',
    APPROVED_CURRENCY: 'Approved currency',
    AUTH_EVENT_ID: 'Authorization event ID',
    CARD_AUTHORIZATION_EVENT_TITLE: 'Authorization event',
    CARD_AUTHORIZATION_TITLE: 'Authorization',
    CARD_ID: 'Card ID',
    CURRENCY: 'Currency',
    CURRENCY_CONVERSION: 'Currency conversation',
    ID: 'Authorization ID',
    MCC: 'MCC',
    MERCHANT_NAME: 'Merchant name',
    METHOD: 'Payment source',
    MID: 'MID',
    REQUESTED_AMOUNT: 'Requested amount',
    REQUESTED_CURRENCY: 'Requested currency',
    TIME: 'Time initiated',
    TIME_INITIATED: 'Time initiated',
    TIME_TO_LAST_UPDATE: 'Time to last update',
};
