import { Box, ListItemText, Typography } from '@mui/material';

import { AuthUser } from '../../types';

const ellipsis = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export function UserAndEmail({ user }: { user: AuthUser }) {
    return (
        <Box sx={{ padding: '1em' }}>
            <ListItemText
                disableTypography
                primary={
                    <Typography sx={{ ...ellipsis, fontWeight: 'bold' }}>{user.name}</Typography>
                }
            />
            <ListItemText>
                <Typography sx={ellipsis}>{user.email}</Typography>
            </ListItemText>
        </Box>
    );
}
