import {
    BANK_TRANSFERS_HOLDERS_KEY,
    BANK_TRANSFERS_INSTRUMENTS_KEY,
    BANK_TRANSFERS_TRANSACTIONS_KEY,
} from '../../keys';
import {
    fetchBankTransfersHolders,
    fetchBankTransfersInstruments,
    fetchBankTransfersTransactions,
} from '../methods';
import { useInfiniteQueryWith } from './useInfiniteQueryWith';

export const useBankTransferHolders = useInfiniteQueryWith({
    fetcher: fetchBankTransfersHolders,
    queryKey: BANK_TRANSFERS_HOLDERS_KEY,
});

export const useBankTransferInstrumentsList = useInfiniteQueryWith({
    fetcher: fetchBankTransfersInstruments,
    queryKey: BANK_TRANSFERS_INSTRUMENTS_KEY,
});

export const useBankTransferTransactions = useInfiniteQueryWith({
    fetcher: fetchBankTransfersTransactions,
    queryKey: BANK_TRANSFERS_TRANSACTIONS_KEY,
});
