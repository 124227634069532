import { StepType } from '@dock/react';

import commonLang from '../../../../../lang/common';

export const stepsConfig: StepType[] = [
    {
        isOptional: false,
        label: commonLang.STEPS.BASIC_DETAILS,
    },
    {
        isOptional: true,
        label: commonLang.EXTERNAL_REFERENCE,
    },
];
