import type { ListPaymentEventsParams } from '@dock/types-dock-partner';

import { ListPaymentsEventResponse } from '@dock/services';

import { getA2APaymentsEvents } from '../../client';
import { fetcher } from './fetcher';

export const fetchA2APaymentEvents = fetcher<ListPaymentsEventResponse, ListPaymentEventsParams>(
    getA2APaymentsEvents
);
