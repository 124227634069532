import { useState } from 'react';

type ModalParams = {
    messages: string[] | React.ReactElement[];
    isLoading?: boolean;
    onChangeClick?: () => void;
    onSubmitClick?: () => void;
};

export type UseProvideStatusModalsType = {
    notification: ProvideStatusModalsState | null;
    isOpen: boolean;
    showSuccessModal: (params: ModalParams) => void;
    showErrorModal: (params: ModalParams) => void;
    closeModal: () => void;
    handleChange: () => void;
    handleSubmit: () => void;
};

export enum StatusModalType {
    ERROR = 'error',
    SUCCESS = 'success',
}

type ProvideStatusModalsState = ModalParams & {
    type: StatusModalType | null;
};

export function useProvideStatusModals(): UseProvideStatusModalsType {
    const [notification, setNotification] = useState<ProvideStatusModalsState | null>(null);
    const [changeClick, setChangeClick] = useState<() => void>(() => {});
    const [submitClick, setSubmitClick] = useState<() => void>(() => {});

    const showSuccessModal = ({ messages }: ModalParams) => {
        setNotification({ messages, type: StatusModalType.SUCCESS });
    };

    const showErrorModal = ({ messages, onChangeClick, onSubmitClick }: ModalParams) => {
        setNotification({ messages, type: StatusModalType.ERROR });
        if (onSubmitClick) {
            setSubmitClick(() => onSubmitClick);
        }
        if (onChangeClick) {
            setChangeClick(() => onChangeClick);
        }
    };

    const closeModal = () => {
        setNotification(null);
    };

    const handleSubmit = () => {
        closeModal();
        submitClick();
    };

    const handleChange = () => {
        closeModal();
        changeClick();
    };

    return {
        closeModal,
        handleChange,
        handleSubmit,
        isOpen: notification !== null,
        notification,
        showErrorModal,
        showSuccessModal,
    };
}
