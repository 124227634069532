export const CardTransactionDetailColumns = {
    authorizationId: 'Authorization ID',
    cardId: 'Card ID',
    currencyConversionRate: 'Currency conversion rate',
    kind: 'Type',
    paymentSource: 'Payment source',
    status: 'Status',
    timeCompleted: 'Time completed',
    timeInitiate: 'Time initiated',
    totalAmount: 'Amount',
    totalAmountCurrency: 'Currency',
    transactionId: 'Transaction ID',
};
export const CardTransactionMerchantColumns = {
    address: 'Address',
    amount: 'Amount',
    city: 'City',
    country: 'Country',
    currency: 'Currency',
    mcc: 'MCC',
    mid: 'MID',
    name: 'Name',
    state: 'State',
};
export const CardTransactionOtherColumns = {
    acquireAudit: 'Acquirer Audit ID',
    acquireRef: 'Acquirer ref ID',
    acquirerId: 'Acquirer ID',
    approval: 'Approval code',
    network: 'Network ref ID',
};
