export default {
    A2A_DETAILS_TITLE: 'A2A transfer details',
    A2A_HOLDER_ID: 'A2A holder ID',
    A2A_ID: 'A2A transfer ID',
    A2A_PAYMENTS_DETAILS_TITLE: 'A2A transfer payments details',
    A2A_STATUS: 'Status',
    A2A_TABS: {
        HOLDERS: 'A2A transfer holders',
        TRANSFER: 'A2A transfers',
    },
    A2A_TITLE: 'A2A transfers',
    A2A_TRANSACTION_DETAILS_TITLE: 'A2A transfer transaction details',
    CREATE_NEW: {
        ACCOUNT_ID_TOOLTIP: 'Account to which payment instrument is associated.',
        CREATE_A2A_TITLE: 'Create A2A transfer instrument',
        FAILED: 'A2A transfer instrument creation failed. Please try again.',
        HOLDER_ID: 'A2A holder ID',
        HOLDER_ID_TOOLTIP: 'The holder ID associated with the A2A transfer instrument.',
        PRODUCT_ID_TOOLTIP: 'Product ID defines the behaviour of the A2A transfer instrument.',
        SUBMIT_BUTTON: 'Create A2A transfer',
        SUCCESS_POST: ' was created successfully.',
        SUCCESS_PRE: 'New A2A transfer instrument ',
    },
    CREATED_TIME: 'Created time',
    EXTERNAL_REFERENCE: 'External reference',
    HOLDER_LIST: {
        CREATED_TIME: 'Created time',
        ID: 'A2A transfer holder ID',
        LABEL: 'A2A transfer holder label',
        TENANT_ID: 'Tenant ID',
    },
    SEARCH_FOR_ID: 'Search for A2A transfer ID',
    SEARCH_HOLDER_LIST: {
        ID: 'A2A transfer holder ID',
        TENANT_ID: 'Tenant ID',
    },
    STATUS: {
        ACTIVATED: 'Activated',
        LOCKED: 'Locked',
        SUSPENDED: 'Suspended',
        TERMINATED: 'Terminated',
        TERMINATED_OBSOLETE: 'Terminated obsolete',
        UNACTIVATED: 'Unactivated',
    },
    TRANSFER_STATUS: 'Transfer status',
};
