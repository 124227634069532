import { useParams } from 'react-router-dom';

import { PageContainer } from '@dock/react';

import { CardTransactionDetailTable } from './components/CardTransactionDetailTable';

export function CardTransactionDetails() {
    const { id: cardId = '', transactionId = '' } = useParams();
    return (
        <PageContainer testId="cardTransactionDetailsPage">
            <CardTransactionDetailTable cardId={cardId} transactionID={transactionId} />
        </PageContainer>
    );
}
