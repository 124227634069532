import { Typography } from '@mui/material';

import { SimpleTable } from '@dock/react';

import { formatSortOrder } from '../../../common';
import authDetailsLang from '../../../lang/cardAuthorizationDetails';
import { useCardAuthorizationEvents } from '../../../services';
import { useCardAuthsEventsFilters } from '../../CardTransactionsOverview/components/AuthorizationsEventsTable/useCardAuthsEventsFilters';
import { AuthEventRow } from './AuthEventRow';
import { authEventsTableColumns } from './authEventsTableColumns';

type CardAuthorizationDetailTableProps = {
    cardId: string;
    authorizationID: string;
};

export function CardAuthorizationEventsDetail({
    authorizationID,
    cardId,
}: CardAuthorizationDetailTableProps) {
    const { handleSortOrder, sorting } = useCardAuthsEventsFilters();

    const { data, error, isFetching } = useCardAuthorizationEvents({
        params: {
            _order_by: sorting ? formatSortOrder(sorting.direction) : '-id',
            authorization_id: [authorizationID],
            card_id: [cardId],
        },
    });

    return (
        <>
            <Typography variant="h3" sx={{ mb: '8px' }}>
                {authDetailsLang.CARD_AUTHORIZATION_EVENT_TITLE}
            </Typography>
            <SimpleTable
                columns={authEventsTableColumns}
                RowComponent={AuthEventRow}
                isLoading={isFetching}
                rows={data}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
