import React from 'react';
import { useNavigate } from 'react-router-dom';

import { WhiteBackgroundWrapper } from '@dock/react';

import {
    CARD_SPENDING_CONTROLS_ROUTE,
    SuccessStatusContent,
    SuccessStatusMessage,
} from '../../../../common';

type SuccessMessageProps = {
    pre: string;
    post: string;
    content: React.ReactNode;
};

export function SuccessMessage({ content, post, pre }: SuccessMessageProps) {
    const navigate = useNavigate();
    const onCloseClick = () => navigate(CARD_SPENDING_CONTROLS_ROUTE);

    return (
        <WhiteBackgroundWrapper sx={{ p: '24px' }}>
            <SuccessStatusContent
                buttonStyles={{ justifyContent: 'center', width: '443px' }}
                handleClose={onCloseClick}
                statusMessages={[
                    <SuccessStatusMessage preLabel={pre} postLabel={post}>
                        {content}
                    </SuccessStatusMessage>,
                ]}
            />
        </WhiteBackgroundWrapper>
    );
}
