export default {
    ACCOUNT_HOLDER_ID: 'Account holder ID',
    ACCOUNT_HOLDER_TABLE: {
        COLUMNS: {
            ACCOUNT_HOLDER: 'Account holder',
            ACCOUNT_HOLDER_ID: 'Account holder ID',
        },
    },
    ACCOUNT_LABEL: 'Account label',
    ACCOUNT_STATUS: {
        ACTIVATED: 'Activated',
        LOCKED: 'Locked',
        TERMINATED: 'Terminated',
        UNACTIVATED: 'Unactivated',
    },
    ACCOUNT_STATUS_TITLE: 'Account status',
    ACCOUNTS_TABLE: {
        COLUMNS: {
            ACCOUNT_HOLDER_ID: 'Account holder ID',
            ACCOUNT_ID: 'Account ID',
            ACCOUNT_NAME: 'Account label',
            AVAILABLE_BALANCE: 'Available balance',
            CREATED_TIME: 'Created time',
            CURRENCY: 'Currency',
            STATUS: 'Status',
        },
    },
    CREATE_NEW: {
        BASIC_DETAILS_FORM: {
            ACCOUNT_NAME_TOOLTIP: 'Name to associate with the account e.g. “Marketing”.',
            PRODUCT_ID_TOOLTIP: 'Product ID defines the behaviour of the account.',
        },
        CREATE_ACCOUNT: 'Create account',
        CREATE_NEW: 'Create new account',
        FAILED: 'Account creation failed. Please try again.',
        SUCCESS_POST: ' was created successfully.',
        SUCCESS_PRE: 'New Account ',
    },
    SEARCH_FOR_ACCOUNT_ID: 'Search for account ID',
    SEARCH_OPTION: {
        ACCOUNT_ID: 'Account ID',
        EXTERNAL_REFERENCE: 'External reference',
    },
    TABS: {
        ACCOUNT_DETAILS: {
            PAYMENT_INSTRUMENTS: 'Payment instruments',
            TRANSACTIONS: 'Transactions',
        },
        ACCOUNTS: {
            HOLDERS: 'Account holders',
            LIST: 'Account list',
        },
    },
};
