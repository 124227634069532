import { KeysToSnakeCase } from '@dock/mock-server';
import { AuthGrantType, AuthRenewal, AuthToken } from '@dock/types-dock-partner';

import { ENV_VARS } from '../../../common';

type RawFetchTokensParams = {
    authAccessToken: AuthToken | null;
    refreshToken: AuthToken;
};

export const rawFetchTokens = async ({ authAccessToken, refreshToken }: RawFetchTokensParams) => {
    const payload: KeysToSnakeCase<AuthRenewal> = {
        grant_type: AuthGrantType.REFRESH_TOKEN,
        token: refreshToken ? refreshToken.token : '',
    };
    return fetch(`${ENV_VARS.AUTH_API_URL}v0/tokens`, {
        body: JSON.stringify(payload),
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authAccessToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
    });
};
