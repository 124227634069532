export default {
    EVENTS_KIND: {
        COMPLETED: 'Completed',
        DISABLED: 'Disabled',
        ERROR: 'Error',
        EXECUTED: 'Executed',
        INSUFFICIENT_FUNDS: 'Insufficient funds',
        INTERNAL: 'Internal',
        LOCKED: 'Locked',
        PENDING: 'Pending',
        SCHEDULED: 'Scheduled',
    },
    EVENTS_TABLE: {
        CREATED_DATE: 'Created time',
        EVENT_TYPE: 'Event type',
        KIND: 'Kind',
        PAYMENT_EVENT_ID: 'Payment event ID',
    },
    EXTERNAL_REFERENCE: 'External reference',
    FILTER: {
        KIND: {
            CROSSBORDER: 'Crossborder',
            INTERNAL: 'Internal',
            SEPA: 'Sepa',
            TARGET2: 'Target 2',
        },
    },
    PAYMENT_EVENTS: 'Payment events',
    SEARCH_FOR_TRANSACTION_ID: 'Search for Bank transfer transaction ID',
    TAB: {
        TRANSACTIONS: 'Transactions',
    },
    TRANSACTIONS_TABLE: {
        AVAILABLE_AMOUNT: 'Amount',
        BANK_TRANSFER_ID: 'Bank transfer ID',
        COUNTER_PARTY: 'Counterparty',
        CREATED_DATE: 'Created time',
        CURRENCY: 'Currency',
        PARTY: 'Party',
        PAYMENT_DETAILS: 'Transaction details',
        PAYMENT_EVENT_DETAILS: 'A2A transfer payment',
        RECIPIENT: 'Recipient',
        SENDER: 'Sender',
        TRANSACTION_ID: 'Transaction ID',
        TYPE: 'Type',
    },
    TRANSFER_ID: 'Bank transfer ID',
};
