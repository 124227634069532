export default {
    AMOUNT_OF_DAYS: 'Amount of days',
    AMOUNT_OF_MONTHS: 'Amount of months',
    BASIC_DETAILS: 'Basic details',
    CARD: 'Card',
    CARD_ID: 'Card ID',
    CARD_ID_HELP_TEXT: 'The ID of the card to which the spending limit should be linked.',
    CARD_PRODUCT_DEFINITION: 'Card product definition',
    CARD_PRODUCT_DEFINITION_ID: 'Card product ID',
    CATEGORY: 'Category',
    CONFIRM: 'Confirm and create',
    EDIT_AMOUNT: 'Edit Amount',
    ENDING_TIME: 'Effective until',
    FREQUENCY_HELP_TEXT:
        'Window identifies how the spending control is applied. Please note that the spending control is recurrent during the effective period established.',
    GET_FAILED: (name: string) => `Spending control ${name} creation failed. Please, try again.`,
    GET_SUCCESS_PRE: (name: string) => `New spending control ${name} with ID `,
    NAME_HELP_TEXT: 'Human readable label for the spending control. E.g.: 10 Euro per month.',
    SC_EFFECTIVE_PERIOD: 'Effective period (optional)',
    SC_EFFECTIVE_PERIOD_NOTE:
        'Please note that, if effective from field is not set, spending control will go-live right after creation.',
    SC_SUMMARY: 'Spending control summary',
    SCOPE: 'Scope',
    SCOPE_HELP_TEXT:
        'The scope according to which the spending control is being applied (e.g. a specific card ID).',
    SCOPES: {
        INDIVIDUAL: 'Individual',
    },
    SELECT_CATEGORY: 'Select category',
    SPENDING_CONFIGURATION: 'Spending configuration',
    STARTING_TIME: 'Effective from',
    SUCCESS_POST: ' is now created.',
    TENANT_ID: 'Tenant ID',
    TITLE: 'Create new spending control',
    WINDOW: 'Window',
    WINDOW_DAYS_TOOLTIP:
        'e.g. if input is 2 days, the control will be reset at 00:00:00 every 2 days.',
    WINDOW_KIND: {
        DAYS: 'Per days',
        LIFETIME: 'Lifetime',
        MONTHS: 'Per months',
        PER_TRANSACTION: 'Per transaction',
    },
    WINDOW_MONTHS_TOOLTIP:
        'e.g. if input is 2 months, the spending control will be reset at 00:00:00 on the first day of the calendar month, every 2 months.',
    ZERO_AMOUNT_CONFIRMATION: {
        GET_BODY: (currency: string) =>
            `Please note that the amount you set is 0.00 ${currency}, which means that the card has no limit, therefore cannot be used during the period established (if any).`,
        GET_QUESTION: (currency: string) =>
            `Are you sure you want to proceed with 0.00 ${currency} amount?`,
        TITLE: 'Zero amount confirmation',
    },
};
