import { Box, CircularProgress } from '@mui/material';

import { SecondaryTextButton, SecondaryButton } from '@dock/react';

type ChangeStatusModalWrapperProps = {
    secondaryLabel: string;
    primaryLabel: string;
    isLoading: boolean;
    onClose: () => void;
};

export function ChangeStatusModalButtons({
    isLoading,
    onClose,
    primaryLabel,
    secondaryLabel,
}: ChangeStatusModalWrapperProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
                mt: 3,
                width: '100%',
            }}
        >
            <SecondaryTextButton onClick={onClose}>{secondaryLabel}</SecondaryTextButton>
            <SecondaryButton
                type="submit"
                testId="submitButton"
                isLoading={isLoading}
                endIcon={
                    <CircularProgress data-testid="loadingIcon" color="inherit" size="1.25rem" />
                }
            >
                {primaryLabel}
            </SecondaryButton>
        </Box>
    );
}
