import { useMutation } from '@tanstack/react-query';

import type { VerifyCryptoChallengeData, AuthVerifyChallenge } from '@dock/types-dock-partner';

import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { verifyChallenge } from '../../api';

export type UpdateCryptokeyParams = {
    id: string;
    body: AuthVerifyChallenge;
};

export function useVerifyCryptokeyChallengeMutation() {
    return useMutation<VerifyCryptoChallengeData, PartnerServiceError, UpdateCryptokeyParams>(
        ({ body, id }) => verifyChallenge(id, body)
    );
}
