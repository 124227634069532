import { ChangeEvent, useState, Dispatch } from 'react';

type Amount = {
    minAmount: string | undefined;
    maxAmount: string | undefined;
};

export const useAmounts = ({
    maxAmount,
    minAmount,
}: Amount): [Amount, Dispatch<Amount>, (htmlCheck: ChangeEvent<HTMLInputElement>) => void] => {
    const [amount, setAmount] = useState({ maxAmount, minAmount });

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAmount({
            ...amount,
            [event.target.name]: event.target.value,
        });
    };

    return [amount, setAmount, handleAmountChange];
};
