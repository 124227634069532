import snakeCase from 'lodash/snakeCase';

import { PLUS_SIGN, PLUS_ENCODED } from '@dock/common';

const SLASH = '%2F';

export const parseStringValue = (value: unknown): string => {
    if (value instanceof Date) {
        return value.toISOString();
    }
    if (value === '+id') {
        return value.replace(PLUS_SIGN, PLUS_ENCODED);
    }

    return String(value).trim();
};

export const buildUrl = (parameters: object): string =>
    Object.entries(parameters).reduce((query, [key, value]): string => {
        if (value == null) {
            return query;
        }

        if (Array.isArray(value) && value.filter(Boolean).length === 0) {
            return query;
        }

        if (typeof value === 'string' && value.trim() === '') {
            return query;
        }

        let transformedKey: string;
        if (key.startsWith('_')) {
            transformedKey = `_${snakeCase(key)}`;
        } else if (key.includes('/')) {
            transformedKey = key.replace('/', SLASH);
        } else {
            transformedKey = snakeCase(key);
        }

        if (key.includes('amount_'))
            transformedKey = key
                .replace('_min', '')
                .replace('_max', '')
                .split('_')
                .reverse()
                .join(SLASH);

        if (key.includes('from_'))
            transformedKey = key.replace('from', 'datetimes').replace('_', SLASH);

        if (key.includes('to_'))
            transformedKey = key.replace('to', 'datetimes').replace('_', SLASH);

        const addItemToQuery = (val: string): string =>
            `${query}${transformedKey}=${parseStringValue(val)}&`;

        // TODO: should be refactored later. We can create config with keys as query (amount) and values as AND or OR)
        // Define which queries should be sent as separate values (separate means AND, using comma means OR)
        if (
            Array.isArray(value) &&
            (key === 'amount' ||
                key === 'datetimes/value_dated' ||
                key === 'datetimes/created' ||
                key === 'datetimes/completed' ||
                key === 'datetimes/initiated' ||
                key === 'datetimes/starts_at' ||
                key === 'amounts/total/amount' ||
                key === 'amounts/approved/amount' ||
                key === 'amounts/requested/amount' ||
                key === 'balances/available')
        ) {
            return value.filter(Boolean).reduce((arrayQuery, arrayValue) => {
                if (arrayValue === null) {
                    return arrayQuery;
                }

                return `${arrayQuery}${transformedKey}=${parseStringValue(arrayValue)}&`;
            }, query);
        }

        return addItemToQuery(value);
    }, '');

/**
 * @deprecated use serializeParams instead
 */
export const parseParameters = (parameters: object = {}): string => {
    const url = buildUrl(parameters);
    return url.slice(0, -1);
};
