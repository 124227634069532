import { createContext } from 'react';

import { UseProvideAuthType } from './useProvideAuth';

const defaultValue: UseProvideAuthType = {
    isLoading: false,
    isLoggedIn: false,
    login: () => {},
    logout: () => {},
    user: { email: '', id: '', name: '' },
    validationError: false,
};

export const authContext = createContext<UseProvideAuthType>(defaultValue);
