import { flow } from 'fp-ts/lib/function';

import { ListVelocityControlsData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import {
    requestFlow,
    getDataAndHeaders,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type VelocityControlListWithToken = ResponseWithContinuationToken<ListVelocityControlsData>;

// service
export const fetchVelocityControlList = requestFlow(handleErrors)(`v0/cards/velocity_controls`);
// service & mapper
const fetchAndHandle = fetchVelocityControlList<VelocityControlListWithToken>(getDataAndHeaders);

// service & map & and open the monad
export const getVelocityControlList: RequestAndUnwrap<'get', VelocityControlListWithToken> = flow(
    fetchAndHandle,
    unwrapService<VelocityControlListWithToken>
);
