import type { ListPaymentsParams } from '@dock/types-dock-partner';

import { dateAndAmountFilterUrlParamsExtractor } from '../../../../common';
import {
    A2ATransactionFiltersURLParams,
    A2ATransactionSearchFields,
} from './useA2ASchedulePaymentFilters';

const propertyMap: Record<string, keyof ListPaymentsParams> = {
    [A2ATransactionSearchFields.externalReference]: 'external_reference',
    [A2ATransactionSearchFields.paymentId]: 'id',
    [A2ATransactionSearchFields.recipientId]: 'recipient_id',
};

const customKeyUrlParamsExtractor = dateAndAmountFilterUrlParamsExtractor()<ListPaymentsParams>([
    { key: 'sender_id', value: 'instrumentId' },
]);

type ExtendedA2ATransactionFiltersURLParams = A2ATransactionFiltersURLParams & {
    instrumentId?: string | undefined;
};

export const transformA2ASchedulePaymentParams =
    customKeyUrlParamsExtractor<ExtendedA2ATransactionFiltersURLParams>(propertyMap);
