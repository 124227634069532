import type { CardsCardStatus } from '@dock/types-dock-partner';

import cardsLang from '../../../../lang/cards';

export const cardStatusMap: Record<CardsCardStatus, string> = {
    ACTIVATED: cardsLang.CARD_STATUS.ACTIVATED,
    LOCKED: cardsLang.CARD_STATUS.LOCKED,
    SUSPENDED: cardsLang.CARD_STATUS.SUSPENDED,
    TERMINATED: cardsLang.CARD_STATUS.TERMINATED,
    TERMINATED_OBSOLETE: cardsLang.CARD_STATUS.TERMINATED_OBSOLETE,
    UNACTIVATED: cardsLang.CARD_STATUS.UNACTIVATED,
};
