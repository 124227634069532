import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';

import { DEFAULT_THEME } from '@dock/react';

export const dockAdminTheme = createTheme(DEFAULT_THEME);

export function ThemeRoot({ children }: { children: React.ReactNode }) {
    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={dockAdminTheme}>{children}</ThemeProvider>
        </>
    );
}
