import {
    CardsCardStatus,
    CardsChangeCardStatus,
    UsersCapabilityAction,
    UsersCapabilityObject,
} from '@dock/types-dock-partner';

import commonLang from '../../../lang/common';
import { hasCapability } from '../../../services/user/hasCapability';
import { cardStatusMap } from '../components/ChangeStateCardForm/cardStatusMap';
import { cardStatusOptions } from '../components/ChangeStateCardForm/cardStatusOptions';

export const cardDetailsBL = {
    getStatusOptions: (status: CardsCardStatus) => {
        if (status === CardsCardStatus.UNACTIVATED) {
            return cardStatusOptions.filter(({ value }) => value !== CardsCardStatus.LOCKED);
        }

        return cardStatusOptions;
    },
    getWarningText: (status: CardsCardStatus | CardsChangeCardStatus) => {
        if (
            !!status &&
            (status === CardsCardStatus.TERMINATED_OBSOLETE ||
                status === CardsChangeCardStatus.TERMINATED_OBSOLETE)
        ) {
            return commonLang.GET_CHANGE_STATUS_WARNING_MESSAGE(cardStatusMap[status]);
        }

        return '';
    },
    isChangeCardStatusAvailable: () =>
        hasCapability(UsersCapabilityObject.CARD_STATUS, UsersCapabilityAction.UPDATE),
    isEditCardStatusDisabled: (status: CardsCardStatus) =>
        !!status && status === CardsCardStatus.TERMINATED_OBSOLETE,
};
