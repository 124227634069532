import { Box, CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useSidebar } from '@dock/react-hooks';

import { ACCOUNTS_ROUTE, Sidebar } from '../../common';
import { sidebarConfig } from '../../configs/sidebarConfig';
import { SessionExpired } from '../../pages/Login/components/SessionExpiredModal/SessionExpired';
import { AppBar } from './AppBar';

export function Root() {
    const { isOpen, toggle } = useSidebar();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === '/') {
            navigate(ACCOUNTS_ROUTE, { replace: true });
        }
    }, [pathname]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar isOpen={isOpen} toggleDrawer={toggle} items={sidebarConfig} />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[50],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <AppBar />

                <SessionExpired />

                {/* Determine where children should render */}
                <Outlet />
            </Box>
        </Box>
    );
}
