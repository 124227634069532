import { Dispatch, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_SORT_BY } from '@dock/common';
import { DateFilterParams, SortOrder, Sorting } from '@dock/types-common';

import { getSortOrderURLParam, getUrlParam } from '../extractors';
import { BaseFilterParams } from '../types';
import { useWriteSearchParams } from '../useWriteSearchParams';

export type FilterReturnTypes<FiltersURLParams, Params, SearchFields> = {
    filters: FiltersURLParams;
    handleDateFilter: (value: DateFilterParams) => void;
    handlePopUpFilter: (d: Dispatch<boolean>) => (params: Params) => void;
    handleSearch: (value: string) => void;
    handleSearchSelect: (value: SearchFields) => void;
    handleSelect: (value: SearchFields) => void;
    handleSortOrder?: ((property: string) => void) | undefined;
    isPopFilterActive?: boolean;
    sorting?: Sorting;
};

type GetBasicEmptyFiltersParams = {
    params: URLSearchParams;
    sortByKey?: string;
};

export const getBasicEmptyFilters = ({
    params,
    sortByKey = DEFAULT_SORT_BY,
}: GetBasicEmptyFiltersParams): BaseFilterParams<string> => ({
    from: getUrlParam('from', params),
    maxAmount: getUrlParam('maxAmount', params),
    minAmount: getUrlParam('minAmount', params),
    sortBy: getUrlParam('sortBy', params) || sortByKey,
    sortOrder: getSortOrderURLParam(params),
    // TODO: include search by
    text: getUrlParam('text', params),
    to: getUrlParam('to', params),
});

export const commonHandlers = <FilterURLParams extends BaseFilterParams<string>>(
    filters: FilterURLParams,
    handleFilters: (fields: FilterURLParams) => void,
    getSearchParams: (params: URLSearchParams) => FilterURLParams
) => ({
    handleDateFilter: ({ from, to }: DateFilterParams) => {
        handleFilters({
            ...getSearchParams(new URLSearchParams(window.location.search)),
            from,
            to,
        });
    },

    handlePopUpFilter: (setOpen: Dispatch<boolean>) => (fields: FilterURLParams) => {
        setOpen(false);
        handleFilters({
            ...getSearchParams(new URLSearchParams(window.location.search)),
            ...Object.keys(fields).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: fields[key as keyof FilterURLParams],
                }),
                {}
            ),
        });
    },

    handleSearch: (searchValue: string) =>
        handleFilters({
            ...getSearchParams(new URLSearchParams(window.location.search)),
            text: searchValue,
        }),

    handleSearchSelect: <SearchFields>(value: SearchFields) => {
        handleFilters({
            ...getSearchParams(new URLSearchParams(window.location.search)),
            searchBy: value,
        });
    },

    handleSelect: <Method>(value: Method) => {
        handleFilters({
            ...getSearchParams(new URLSearchParams(window.location.search)),
            sourceMethod: value,
        });
    },

    handleSortOrder: (sortingColumn: string) => {
        const isAsc = filters.sortBy === sortingColumn && filters.sortOrder === SortOrder.ASC;

        handleFilters({
            ...getSearchParams(new URLSearchParams(window.location.search)),
            sortBy: sortingColumn,
            sortOrder: isAsc ? SortOrder.DESC : SortOrder.ASC,
        });
    },
});

export const useFilters = <FilterUrlParams extends BaseFilterParams<string>>(
    getEmptyFilters: (params: URLSearchParams) => FilterUrlParams,
    customFieldsExtractor: (fields: FilterUrlParams) => FilterUrlParams
) => {
    const [searchParams] = useSearchParams();

    const initialParams = getEmptyFilters(searchParams);

    const [filters, setFilters] = useState<FilterUrlParams>(initialParams);

    const { from, searchBy, sortBy, sortOrder, text, to, ...popUpFiltersFields } = filters;

    const handleFilters = (fields: FilterUrlParams) => {
        setFilters({
            ...filters,
            from: fields.from,
            maxAmount: fields.maxAmount || '',
            minAmount: fields.minAmount || '',
            searchBy: fields.searchBy || 'id',
            sortBy: fields.sortBy || DEFAULT_SORT_BY,
            sortOrder: fields.sortOrder || SortOrder.DESC,
            text: fields.text || '',
            to: fields.to,

            ...customFieldsExtractor(fields),
        });
    };

    useWriteSearchParams(filters);

    const isPopFilterActive = Object.values(popUpFiltersFields).some((value) =>
        Array.isArray(value) ? !!value.length : !!value
    );

    return {
        filters,
        isPopFilterActive,
        sorting: {
            direction: sortOrder || SortOrder.DESC,
            field: sortBy || DEFAULT_SORT_BY,
        },
        ...commonHandlers(filters, handleFilters, getEmptyFilters),
    };
};
