import { ListTransactionsResponse } from '@dock/services';
import { ListTransactionsParams } from '@dock/types-dock-partner';

import { getBankTransfersTransactions } from '../../client';
import { fetcher } from './fetcher';

export const fetchBankTransfersTransactions = fetcher<
    ListTransactionsResponse,
    ListTransactionsParams
>(getBankTransfersTransactions);
