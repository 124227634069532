import { Box } from '@mui/material';
import React from 'react';

import { Modal } from '@dock/react';

type ChangeStatusModalWrapperProps = {
    isOpen: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    title: string;
    ariaTitle?: string;
};

export function ChangeStatusModalWrapper({
    ariaTitle,
    children,
    handleClose,
    isOpen,
    title,
}: ChangeStatusModalWrapperProps) {
    return (
        <Modal
            handleClose={handleClose}
            isOpen={isOpen}
            modalAriaTitle={ariaTitle || title}
            title={title}
        >
            <Box sx={{ marginTop: '24px', width: '430px' }}>{children}</Box>
        </Modal>
    );
}
