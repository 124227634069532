import { AccountsPaymentMethod } from '@dock/types-dock-partner';

import { useA2AList, useBankTransferInstrumentsList, useCardsList } from '../../../../services';
import {
    AccountPaymentMethodsListProps,
    AccountPaymentMethodsListPropsExtended,
} from './AccountPaymentMethodsRow';
import { transformPaymentMethodsURLParams } from './transformPaymentMethodsURLParams';
import { AccountPaymentMethodFiltersURLParams } from './useAccountPaymentMethodFilters';

type UseAccountPaymentMethods = {
    filters: AccountPaymentMethodFiltersURLParams;
    accountId: string;
};

export const useAccountPaymentMethods = ({ accountId, filters }: UseAccountPaymentMethods) => {
    const isCardsTable = filters.sourceMethod === AccountsPaymentMethod.CARD;
    const isA2ATable = filters.sourceMethod === AccountsPaymentMethod.A2ATRANSFER;
    const isBankTransfersTable = filters.sourceMethod === AccountsPaymentMethod.BANK_TRANSFER;

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useCardsList({
        enabled: isCardsTable,
        params: transformPaymentMethodsURLParams(filters, accountId),
    });

    const {
        data: a2aData,
        error: a2aError,
        fetchNextPage: fetchNextPageA2A,
        isFetching: isFetchingA2A,
        isFetchingNextPage: isFetchingNextPageA2A,
    } = useA2AList({
        enabled: isA2ATable,
        params: transformPaymentMethodsURLParams(filters, accountId),
    });

    const {
        data: btData,
        error: btError,
        fetchNextPage: fetchNextPageBt,
        isFetching: isFetchingBt,
        isFetchingNextPage: isFetchingNextPageBt,
    } = useBankTransferInstrumentsList({
        enabled: isBankTransfersTable,
        params: transformPaymentMethodsURLParams(filters, accountId),
    });

    const isFetchingMap: Record<AccountsPaymentMethod, boolean> = {
        [AccountsPaymentMethod.A2ATRANSFER]: isFetchingA2A,
        [AccountsPaymentMethod.BANK_TRANSFER]: isFetchingBt,
        [AccountsPaymentMethod.CARD]: isFetching,
    };

    const isFetchingNextPageMap: Record<AccountsPaymentMethod, boolean> = {
        [AccountsPaymentMethod.A2ATRANSFER]: isFetchingNextPageA2A,
        [AccountsPaymentMethod.BANK_TRANSFER]: isFetchingNextPageBt,
        [AccountsPaymentMethod.CARD]: isFetchingNextPage,
    };

    const fetchNextPageMap: Record<AccountsPaymentMethod, () => void> = {
        [AccountsPaymentMethod.A2ATRANSFER]: fetchNextPageA2A,
        [AccountsPaymentMethod.BANK_TRANSFER]: fetchNextPageBt,
        [AccountsPaymentMethod.CARD]: fetchNextPage,
    };

    const errorMap: Record<AccountsPaymentMethod, unknown> = {
        [AccountsPaymentMethod.A2ATRANSFER]: a2aError,
        [AccountsPaymentMethod.BANK_TRANSFER]: btError,
        [AccountsPaymentMethod.CARD]: error,
    };

    const dataMap: Record<AccountsPaymentMethod, AccountPaymentMethodsListProps[]> = {
        [AccountsPaymentMethod.A2ATRANSFER]: a2aData,
        [AccountsPaymentMethod.BANK_TRANSFER]: btData,
        [AccountsPaymentMethod.CARD]: data,
    };

    const sourceMethod = filters.sourceMethod || AccountsPaymentMethod.CARD;

    const result: AccountPaymentMethodsListPropsExtended[] = dataMap[sourceMethod].map((el) => ({
        ...el,
        sourceMethod,
    }));

    return {
        data: result,
        error: errorMap[sourceMethod],
        fetchNextPage: fetchNextPageMap[sourceMethod],
        isFetching: isFetchingMap[sourceMethod],
        isFetchingNextPage: isFetchingNextPageMap[sourceMethod],
        sourceMethod,
    };
};
