import { ListBankTransferInstrumentParams } from '@dock/types-dock-partner';

import { basicTransformFilterToParams } from '../../../../common';
import {
    BankTransferInstrumentsSearchFields,
    BankTransfersInstrumentsFiltersURLParams,
} from './useInstrumentsTableFilters';

export const transformBankTransferInstrumentsParams = (
    urlParams: BankTransfersInstrumentsFiltersURLParams
): ListBankTransferInstrumentParams => {
    const { searchBy, text } = urlParams;

    if (searchBy && text) {
        const transformedSearches = {
            [BankTransferInstrumentsSearchFields.ACCOUNT_ID]: {
                account_id: [text],
            },
            [BankTransferInstrumentsSearchFields.IBAN]: {
                iban: [`@>${text}`],
            },
            [BankTransferInstrumentsSearchFields.ID]: {
                id: [text],
            },
        };

        return transformedSearches[searchBy as BankTransferInstrumentsSearchFields];
    }

    return basicTransformFilterToParams()(urlParams);
};
