export default {
    AMOUNT: 'Amount',
    BASIC_DETAIL_COLUMNS: {
        cardId: 'Card ID',
        cardProductId: 'Card product ID',
        category: 'Category',
        label: 'Label',
        Notes: 'Notes',
        scope: 'Scope',
        tenantId: 'Tenant ID',
    },
    CARD_ID: 'Card ID',
    CARD_PRODUCT_DEFINITION_ID: 'Card product ID',
    CATEGORY: 'Category',
    CONFIGURATION_COLUMNS: {
        amount: 'Amount',
        amountOfDays: 'Amount of days',
        amountOfMonths: 'Amount of months',
        currency: 'Currency',
        effectiveFrom: 'Effective from',
        effectiveUntil: 'Effective until',
        lastUpdated: 'Last update time',
        window: 'Window',
    },
    CREATED: 'Created time',
    CURRENCY: 'Currency',
    FREQUENCY_TYPE: 'Window',
    FROM: 'Effective from',
    ID: 'Spending control ID',
    NAME: 'Label',
    SCOPE: 'Scope',
    TABS: {
        BASIC_DETAILS: 'Basic details',
        SPENDING_CONFIGURATION: 'Spending configuration',
        SPENDING_CONTROL: 'Spending controls',
    },
    TENANT_ID: ' Tenant ID',
    UNTIL: 'Effective until',
    WINDOW: {
        DAYS: 'Per day',
        LIFETIME: 'Lifetime',
        MONTHS: 'Per month',
        PER_TRANSACTION: 'Per transaction',
    },
};
