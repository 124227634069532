import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { DockColor, captureException } from '@dock/common';
import { DockLogoIcon } from '@dock/react';

import renderingErrorLang from '../../lang/renderingErrorFallback';
import SomethingWrongImage from './something-wrong.png';

export function RenderingError() {
    const error = useRouteError();

    useEffect(() => {
        if (error) {
            captureException(error);
        }
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    padding: '64px 64px 0 64px',
                }}
            >
                <DockLogoIcon color={DockColor.PRIMARY_500} />
            </Box>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ mb: '20px' }}>
                    <img
                        src={SomethingWrongImage}
                        width={543}
                        height={424}
                        alt={renderingErrorLang.SOMETHING_WENT_WRONG}
                    />
                </Box>
                <Typography variant="h2" sx={{ mb: '8px' }}>
                    {renderingErrorLang.SOMETHING_WENT_WRONG}
                </Typography>
                <Typography variant="h4">{renderingErrorLang.TRY_AGAIN}</Typography>
            </Box>
        </Box>
    );
}
