import { FormControl } from '@mui/material';

import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import { A2ATransfersPaymentEventKindFilter } from '@dock/types-dock-partner';

import {
    CheckItems,
    SimpleDialogProps,
    generateKeys,
    useCheckItems,
    paymentEventKindMap,
} from '../../../../common';
import lang from '../../../../lang/a2aTransactionsDetails';
import commonLang from '../../../../lang/common';
import { SchedulePaymentPopUpFilterParams } from './useA2APaymentEventFilters';

const transactionStatusKeys = generateKeys(paymentEventKindMap);

export function PaymentEventsFilter({
    fields,
    handleClose,
    isOpen,
    onApply,
}: SimpleDialogProps<SchedulePaymentPopUpFilterParams>) {
    const [kind, setKind, onKindCheck] = useCheckItems<A2ATransfersPaymentEventKindFilter>(
        fields.kind || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            kind,
        });
        handleClose();
    };

    const handleReset = () => {
        setKind([]);
        onApply({ kind: [] });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="paymentEventsFilter"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={lang.EVENTS_TABLE.EVENT_TYPE}
                    items={kind}
                    keys={transactionStatusKeys}
                    handleChange={onKindCheck}
                />
            </FormControl>
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
