import React from 'react';

import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';

import { NavLinkTabSwitcher } from '../../common';
import { getTabConfig } from './getTabConfig';

type TransactionsOverviewProps = {
    children: React.ReactNode;
};

export function TransactionsOverview({ children }: TransactionsOverviewProps) {
    const tabs = getTabConfig();

    return (
        <PageContainer testId="cardTransactionsOverviewPage">
            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
