import { flow } from 'fp-ts/lib/function';

import type { BankTransferHolder } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListHoldersResponse = ResponseWithContinuationToken<BankTransferHolder[]>;
export const listBankTransferHoldersHandler = requestFlow(handleErrors)(
    `/v0/bank_transfers/holders`
);

const fetchAndHandle = listBankTransferHoldersHandler<ListHoldersResponse>(getDataAndHeaders);

/**
 * @deprecated
 */
export const listBankTransferHolders: RequestAndUnwrap<'get', ListHoldersResponse> = flow(
    fetchAndHandle,
    unwrapService<ListHoldersResponse>
);
