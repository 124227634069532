export default {
    ACCORDION: {
        HELPTEXT_BIC:
            'BIC (Bank Identifier Code) - We offer 4 different BICs associated with your IBANs. To note that the default is the Luxembourg IBAN (LU) which allows SEPA Instant.',
        HELPTEXT_IBAN:
            'IBAN (Bank Account Number) - We offer 4 different IBANs for your convenience. To note that the default is the Luxembourg (LU) IBAN which allows SEPA Instant.',
        TITLE_BIC: 'BICs',
        TITLE_IBAN: 'IBANs',
    },
    ACCOUNT_ID: 'Account ID',
    CREDIT_TRANSFER: 'Credit transfer',
    DIRECT_DEBIT: 'Direct debit',
    EXTERNAL_REFERENCE: 'External reference',
    FILTER: {
        KIND: {
            CROSSBORDER: 'Crossborder',
            INTERNAL: 'Internal',
            SEPA: 'Sepa',
            TARGET2: 'Target 2',
        },
    },
    INSTANT_CREDIT_TRANSFER: 'Instant credit transfer',
    SEARCH_FOR_TRANSACTION_ID: 'Search for Bank transfer transaction ID',
    TAB: {
        TRANSACTIONS: 'Transactions',
    },
    TRANSACTIONS_TABLE: {
        AVAILABLE_AMOUNT: 'Amount',
        BANK_TRANSFER_ID: 'Bank transfer ID',
        COUNTER_PARTY: 'Counterparty',
        CREATED_DATE: 'Created time',
        CURRENCY: 'Currency',
        PARTY: 'Party',
        PAYMENT_DETAILS: 'Payment details',
        TRANSACTION_ID: 'Transaction ID',
        TYPE: 'Type',
    },
    TRANSFER_ID: 'Bank transfer ID',
};
