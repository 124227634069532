import { ListTransactionsParams } from '@dock/types-dock-partner';

import { filterUrlParamsExtractor } from '../../../../common';
import {
    AccountTransactionFiltersURLParams,
    TransactionsSearchFields,
} from './useAccountTransactionsFilters';

const amountAndDateUrlParamsExtractor = filterUrlParamsExtractor({
    amountKey: 'amount',
    dateKey: 'datetimes/value_dated',
});

const customKeyUrlParamsExtractor = amountAndDateUrlParamsExtractor<ListTransactionsParams>([
    { key: 'source/method', value: 'sourceMethod' },
    { key: 'account_id', value: 'accountId' },
]);

type ExtendedAccountTransactionFiltersURLParams = AccountTransactionFiltersURLParams & {
    accountId: string;
};

const propertyMap: Record<string, keyof ListTransactionsParams> = {
    [TransactionsSearchFields.transactionId]: 'id',
};

export const transformAccountTransactionsParams = (
    urlParams: AccountTransactionFiltersURLParams,
    accountId: string
): ListTransactionsParams =>
    customKeyUrlParamsExtractor<ExtendedAccountTransactionFiltersURLParams>(propertyMap)({
        ...urlParams,
        accountId,
    });
