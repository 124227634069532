import { Box, Grid, Typography } from '@mui/material';

import { ContentTitle, TextWithCopyIcon, NavLink } from '@dock/react';
import { useModal } from '@dock/react-hooks';

import {
    BorderColorIconButton,
    InformationBox,
    StatusChip,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    ChangeStatusModalWrapper,
    interpolateParams,
} from '../../../common';
import a2aDetailsLang from '../../../lang/a2aDetails';
import commonLang from '../../../lang/common';
import { useA2AList } from '../../../services';
import { a2aStatusColorMap } from '../../A2A/components/A2AList/a2aStatusColorMap';
import { a2AStatusMap } from '../../A2A/components/A2AList/a2AStatusMap';
import { a2aDetailsBL } from '../BL/BL';
import { ChangeStatusForm } from './ChangeStatus/ChangeStatusForm';

type A2ADetailsBoxProps = {
    id: string;
};

export function A2ADetailsBox({ id }: A2ADetailsBoxProps) {
    const { isOpen, setClose, setOpen } = useModal();
    const { data, isFetching } = useA2AList({
        params: { id: [id] },
    });
    const a2aData = data[0];

    const accountDetailsLink = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
        id: a2aData?.accountId || '',
    });

    const isEditStatusDisabled = a2aDetailsBL.isEditA2AStatusDisabled(a2aData?.status);

    return (
        <Box sx={{ mb: '40px' }}>
            <InformationBox>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <ContentTitle
                            title={a2aDetailsLang.A2A_TRANSFER_ID}
                            isLoading={isFetching}
                            content={
                                <TextWithCopyIcon text={id}>
                                    <Typography variant="h3">{id}</Typography>
                                </TextWithCopyIcon>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContentTitle
                            title={a2aDetailsLang.ACCOUNT_ID}
                            content={
                                <TextWithCopyIcon text={id}>
                                    <NavLink
                                        to={accountDetailsLink}
                                        label={a2aData?.accountId ?? '-'}
                                        textDecoration="underline"
                                    />
                                </TextWithCopyIcon>
                            }
                            isLoading={isFetching}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContentTitle
                            title={a2aDetailsLang.EXTERNAL_REFERENCE}
                            content={a2aData?.externalReference || ''}
                            isLoading={isFetching}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {a2aData?.status && (
                            <ContentTitle
                                title={commonLang.STATUS}
                                isLoading={isFetching}
                                content={
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            gap: '12px',
                                        }}
                                    >
                                        <StatusChip
                                            color={a2aStatusColorMap[a2aData?.status]}
                                            label={a2AStatusMap[a2aData?.status] || a2aData?.status}
                                        />
                                        {a2aDetailsBL.isChangeA2AStatusAvailable() && (
                                            <BorderColorIconButton
                                                isDisabled={isEditStatusDisabled}
                                                setOpen={setOpen}
                                                testId={
                                                    isEditStatusDisabled
                                                        ? 'disabledChangeStatus'
                                                        : 'changeStatus'
                                                }
                                                ariaLabel="edit"
                                            />
                                        )}
                                    </Box>
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </InformationBox>

            {a2aData?.status && (
                <ChangeStatusModalWrapper
                    handleClose={setClose}
                    isOpen={isOpen}
                    title={a2aDetailsLang.CHANGE_STATUS.TITLE}
                >
                    <ChangeStatusForm onClose={setClose} currentStatus={a2aData.status} />
                </ChangeStatusModalWrapper>
            )}
        </Box>
    );
}
