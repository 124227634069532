import { CardsVelocityControlWindowKind } from '@dock/types-dock-partner';

import {
    getAllTypedParamValues,
    getTypedParamValue,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
} from '../../../../../common';
import { SCListURLParams, SearchFields } from './transformSpendingControlListParams';

export type PopUpFilterParams = Pick<SCListURLParams, 'kind'>;

const getEmptyFilters =
    (defaultSearchBy: SearchFields) =>
    (params: URLSearchParams): SCListURLParams => ({
        ...getBasicEmptyFilters({ params, sortByKey: 'startingTime' }),
        kind: getAllTypedParamValues('kind', params, CardsVelocityControlWindowKind),
        searchBy: getTypedParamValue('searchBy', params, SearchFields, defaultSearchBy),
    });

type SCListFilterReturnType = FilterReturnTypes<SCListURLParams, PopUpFilterParams, SearchFields>;

const customFieldsExtractor = (defaultSearchBy: SearchFields) => (fields: SCListURLParams) => ({
    kind: fields.kind || [],
    searchBy: fields.searchBy || defaultSearchBy,
});

export const useSpendingControlListFilters = (
    defaultSearchBy: SearchFields
): SCListFilterReturnType =>
    useFilters<SCListURLParams>(
        getEmptyFilters(defaultSearchBy),
        customFieldsExtractor(defaultSearchBy)
    );
