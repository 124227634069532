import { TIMEOUT } from '@dock/common';
import { interceptorResponse } from '@dock/http-request';
import { Auth } from '@dock/types-dock-partner';

import { ENV_VARS } from '../../../common';
import { dockPartnerMainInterceptorError } from '../../dockPartnerMainInterceptorError';
import { dockPartnerMainInterceptorRequest } from '../../dockPartnerMainInterceptorRequest';

export const AuthenticationAPI = new Auth({
    baseURL: ENV_VARS.AUTH_API_URL,
    timeout: TIMEOUT,
});

AuthenticationAPI.instance.interceptors.response.use(
    interceptorResponse,
    dockPartnerMainInterceptorError(AuthenticationAPI.instance)
);
AuthenticationAPI.instance.interceptors.request.use(dockPartnerMainInterceptorRequest);
