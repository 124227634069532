import { WhiteBackgroundWrapper, PageContainer, PageActionButtonWrapper } from '@dock/react';

import { NavLinkTabSwitcher } from '../../common';
import { a2aBL } from './BL/BL';
import { A2AListTable } from './components/A2AList';
import { CreateA2ATransferInstrument } from './components/CreateA2ATransferInstrument/CreateA2ATransferInstrument';
import { getA2ATabConfig } from './getTabConfig';

export function A2A() {
    if (!a2aBL.isReadA2ATransferAvailable()) {
        return null;
    }

    const tabs = getA2ATabConfig();

    return (
        <PageContainer testId="a2aPage">
            <PageActionButtonWrapper>
                {a2aBL.isCreateA2ATransferAvailable() && <CreateA2ATransferInstrument />}
            </PageActionButtonWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                <A2AListTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
