import { CardAuthListWithToken } from '@dock/services';
import { ListCardAuthorizationsParams } from '@dock/types-dock-partner';

import { getCardsAuthorizations } from '../../client';
import { fetcher } from './fetcher';

export const fetchCardsAuthorizations = fetcher<
    CardAuthListWithToken,
    ListCardAuthorizationsParams
>(getCardsAuthorizations);
