import { Box } from '@mui/material';
import React from 'react';

type FormWrapperProps = {
    children: React.ReactNode;
    testId?: string;
};

export function FormWrapper({ children, testId = 'formWrapper' }: FormWrapperProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                width: '443px',
            }}
            data-testid={testId}
        >
            {children}
        </Box>
    );
}
