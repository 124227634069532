import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import {
    ACCOUNTS_ROUTE,
    AUDIT_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_ROUTE,
    CARD_LIST_ROUTE,
    IDENTITIES_ROUTE,
    A2A_TRANSACTIONS_ROUTE,
    PRODUCT_DEFINITIONS_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    CARD_OVERVIEW_ROUTE,
    CARD_TRANSACTIONS_OVERVIEW_ROUTE,
    NavbarRoutes,
    CARDS_ROUTE,
    CARD_SPENDING_CONTROLS_ROUTE,
} from '../common';
import commonLang from '../lang/common';
import { a2aBL } from '../pages/A2A/BL/BL';

export const sidebarConfig: NavbarRoutes[] = [
    {
        children: [
            {
                name: commonLang.ROUTES_NAME.CARD_TXS_OVERVIEW,
                route: CARD_TRANSACTIONS_OVERVIEW_ROUTE,
                testId: 'cardTxsOverviewItem',
            },
        ],
        IconElement: LeaderboardOutlinedIcon,
        name: commonLang.ROUTES_NAME.OVERVIEW,
        route: CARD_OVERVIEW_ROUTE,
        testId: 'overviewItem',
    },
    {
        IconElement: ApartmentOutlinedIcon,
        isDisabled: true,
        name: commonLang.ROUTES_NAME.TENANTS,
        route: TENANTS_ROUTE,
        testId: 'tenantsItem',
    },
    {
        IconElement: AccountBalanceOutlinedIcon,
        name: commonLang.ROUTES_NAME.ACCOUNTS,
        route: ACCOUNTS_ROUTE,
        testId: 'accountsItem',
    },
    {
        IconElement: CompareArrowsIcon,
        isVisible: () => a2aBL.isReadA2ATransferAvailable(),
        name: commonLang.ROUTES_NAME.A2A,
        route: A2A_TRANSACTIONS_ROUTE,
        testId: 'a2aTransfersItem',
    },
    {
        IconElement: ReceiptLongOutlinedIcon,
        name: commonLang.ROUTES_NAME.BANK_TRANSFERS,
        route: BANK_TRANSFERS_INSTRUMENTS_ROUTE,
        testId: 'bankTransfersItem',
    },
    {
        children: [
            {
                name: commonLang.ROUTES_NAME.LIST_AND_HOLDERS,
                route: CARD_LIST_ROUTE,
                testId: 'cardsListItem',
            },
            {
                name: commonLang.ROUTES_NAME.SPENDING_CONTROLS,
                route: CARD_SPENDING_CONTROLS_ROUTE,
                testId: 'cardSpendingControl',
            },
        ],
        IconElement: CreditCardOutlinedIcon,
        name: commonLang.ROUTES_NAME.CARDS,
        route: CARDS_ROUTE,
        testId: 'cardsItem',
    },
    {
        IconElement: AccountTreeOutlinedIcon,
        isDisabled: true,
        name: commonLang.ROUTES_NAME.PRODUCT_DEFINITIONS,
        route: PRODUCT_DEFINITIONS_ROUTE,
        testId: 'definitionsItem',
    },
    {
        IconElement: BadgeOutlinedIcon,
        isDisabled: true,
        name: commonLang.ROUTES_NAME.IDENTITIES,
        route: IDENTITIES_ROUTE,
        testId: 'identitiesItem',
    },
    {
        IconElement: GroupsOutlinedIcon,
        isDisabled: true,
        name: commonLang.ROUTES_NAME.USERS,
        route: USERS_ROUTE,
        testId: 'usersItem',
    },
    {
        IconElement: FactCheckOutlinedIcon,
        isDisabled: true,
        name: commonLang.ROUTES_NAME.AUDIT,
        route: AUDIT_ROUTE,
        testId: 'auditItem',
    },
];
