import { A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';

import {
    getAllTypedParamValues,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
} from '../../../../common';
import { A2AListURLParams } from './transformA2AListParams';

export type PopUpFilterParams = Pick<A2AListURLParams, 'status'>;

enum SearchFields {
    instrumentId = 'instrumentId',
}

const getEmptyFilters = (params: URLSearchParams): A2AListURLParams => ({
    ...getBasicEmptyFilters({ params }),
    searchBy: SearchFields.instrumentId,
    status: getAllTypedParamValues('status', params, A2ATransfersInstrumentStatus),
});

export type A2AListFilterReturnType = FilterReturnTypes<
    A2AListURLParams,
    PopUpFilterParams,
    SearchFields
>;
const customFieldsExtractor = (x: A2AListURLParams) => x;

export const useA2AListFilters = (): A2AListFilterReturnType =>
    useFilters<A2AListURLParams>(getEmptyFilters, customFieldsExtractor);
