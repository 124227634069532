export const transactionDetailColumns = {
    amount: 'Amount',
    created: 'Created time',
    description: 'Description',
    externalReference: 'External reference',
    id: 'Payment ID',
    recipientId: 'Recipient ID',
    senderId: 'Sender ID',
};

export const transactionColumns = {
    accountId: 'Account ID',
    accountLabel: 'Account label',
    instrumentId: 'Instrument ID',
};
