import { Column, ColumnAlign } from '@dock/react';

import a2aDetailsLang from '../../../../lang/a2aDetails';

export const a2aTransactionsScheduleTableColumns: Column[] = [
    { key: 'expand', name: '' },
    {
        key: 'paymentEventId',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.PAYMENT_EVENT_ID,
    },
    {
        key: 'recipientId',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.RECIPIENT_ID,
    },
    {
        align: ColumnAlign.RIGHT,
        key: 'amount',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.AMOUNT,
    },
    {
        key: 'currency',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.CURRENCY,
    },
    {
        key: 'createdTime',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.CREATED_DATE,
        sortable: true,
    },
    { key: 'action', name: '' },
];

export const collapsableRowColumns: Column[] = [
    {
        key: 'externalReference',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.EXTERNAL_REFERENCE,
    },
    {
        key: 'description',
        name: a2aDetailsLang.SCHEDULE_PAYMENTS_TABLE.DESCRIPTION,
    },
];
