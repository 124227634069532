import { NavLink, useLocation } from 'react-router-dom';

import { SidebarButtonItem } from './SidebarButtonItem';
import { NavbarRoutes } from './types';

type NavLinkItemProps = {
    isNavbarOpen: boolean;
    item: NavbarRoutes;
};

export function NavLinkItem({ isNavbarOpen, item }: NavLinkItemProps) {
    const { pathname } = useLocation();
    const isCurrentItem = pathname === item.route;

    return (
        <NavLink
            key={item.name}
            to={item.route || ''}
            onClick={(e) => item.isDisabled && e.preventDefault()}
            style={{
                color: 'inherit',
                pointerEvents: item.isDisabled ? 'none' : 'auto',
                textDecoration: 'none',
            }}
        >
            {({ isActive }) => (
                <SidebarButtonItem
                    item={item}
                    isActive={isCurrentItem || isActive}
                    isNavbarOpen={isNavbarOpen}
                />
            )}
        </NavLink>
    );
}
