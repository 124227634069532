import { A2ATransfersTransaction } from '@dock/types-dock-partner';

import { useAccountsList } from '../accounts';
import { useA2ATransactions } from './a2a';

const emptyA2aTransaction = {
    amount: { amount: '-', currency: '-' },
    counterparty: {
        accountId: '-',
        instrumentId: '-',
    },
    datetimes: { created: '-' },
    id: '-',
    instrumentId: '-',
    party: {
        accountId: '-',
        instrumentId: '-',
    },
    paymentId: '-',
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type UseA2aTransactionDetails = {
    data: Optional<A2ATransfersTransaction, 'kind'>;
    isFetching: boolean;
    isError: boolean;
    accountCounterPartyName: string | undefined;
    accountPartyName: string | undefined;
};

export type CardTransactionDetailTableProps = {
    transactionId: string;
};

export const useA2ATransactionsDetails = ({
    transactionId,
}: CardTransactionDetailTableProps): UseA2aTransactionDetails => {
    const {
        data: [a2aTransaction],
        ...a2aTransactionService
    } = useA2ATransactions({
        params: { id: [transactionId] },
    });

    const {
        data: [counterPartyAccount],
        ...counterPartyService
    } = useAccountsList({
        params: { id: [a2aTransaction?.counterparty?.accountId ?? ''] },
    });

    const {
        data: [partyAccount],
        ...partyService
    } = useAccountsList({
        params: { id: [a2aTransaction?.party?.accountId ?? ''] },
    });

    return {
        accountCounterPartyName: counterPartyAccount?.label,
        accountPartyName: partyAccount?.label,
        data: {
            ...emptyA2aTransaction,
            ...a2aTransaction,
        },
        isError:
            counterPartyService.isError || a2aTransactionService.isError || partyService.isError,
        isFetching:
            counterPartyService.isFetching ||
            a2aTransactionService.isFetching ||
            partyService.isFetching,
    };
};
