import { Column, ColumnAlign } from '@dock/react';

import cardOverviewLang from '../../../../lang/cardTransactionsOverview';

export const authorizationsOverviewColumns: Column[] = [
    {
        key: 'authId',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.AUTHORIZATION_ID,
    },
    {
        key: 'cardId',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.CARD_ID,
    },
    { key: 'status', name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.STATUS },
    {
        align: ColumnAlign.RIGHT,
        key: 'amount',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.AMOUNT,
    },
    {
        key: 'currency',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.CURRENCY,
    },
    {
        key: 'paymentSource',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.PAYMENT_SOURCE,
    },
    {
        key: 'merchantName',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.MERCHANT_NAME,
    },
    {
        key: 'initiatedTime',
        name: cardOverviewLang.CARD_AUTHORIZATIONS_TABLE.TIME_INITIATED,
        sortable: true,
    },
    { key: 'actions', name: '' },
];
