import { InternalRequestConfig } from '@dock/common';
import { decamelizeKeysInterceptorRequest } from '@dock/http-request';

import { dockPartnerAppAuthTokenInterceptorRequest } from './dockPartnerAppAuthTokenInterceptorRequest';

export const dockPartnerMainInterceptorRequest = async (
    config: InternalRequestConfig
): Promise<InternalRequestConfig> => {
    const decamelizedConfig = await decamelizeKeysInterceptorRequest(config);
    return dockPartnerAppAuthTokenInterceptorRequest(decamelizedConfig);
};
