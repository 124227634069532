import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import type { z } from 'zod';

import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import { gridInput } from '@dock/react-mui';

import type { CreateCardFormType } from './CreateNewCardModal';

import cardsLang from '../../../../lang/cards';
import commonLang from '../../../../lang/common';
import {
    createCardsBasicDetailsStep,
    createCardsBasicDetailsStepKeys,
} from './createNewCardSchema';

type CreateNewCardFormValues = z.infer<typeof createCardsBasicDetailsStep>;

type BasicDetailsStepStepProps = {
    handleForm: (values: Partial<CreateCardFormType>) => void;
    handleNextStep: () => void;
    defaultValues: CreateNewCardFormValues;
    handleCloseModal: () => void;
};

export function BasicDetailsStep({
    defaultValues,
    handleCloseModal,
    handleForm,
    handleNextStep,
}: BasicDetailsStepStepProps) {
    const methods = useForm<CreateNewCardFormValues>({
        defaultValues,
        resolver: zodResolver(createCardsBasicDetailsStep),
    });
    const { control, handleSubmit, watch } = methods;

    const onSubmitHandler = (values: CreateNewCardFormValues) => {
        handleForm(values);
        handleNextStep();
    };

    const isSubmitButtonDisabled = Object.values(watch()).some((value) => !value);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container data-testid="basicDetailsStep">
                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.ACCOUNT_ID}
                            name={createCardsBasicDetailsStepKeys.accountId}
                            isFullWidth
                            testId="accountIdInput"
                        />
                    </Grid>

                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={cardsLang.BASIC_DETAILS_FORM.ACCOUNT_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={cardsLang.CARD_HOLDER_ID}
                            name={createCardsBasicDetailsStepKeys.cardHolderId}
                            isFullWidth
                            testId="cardHolderIdInput"
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.TENANT_ID}
                            name={createCardsBasicDetailsStepKeys.tenantId}
                            isFullWidth
                            testId="tenantIdInput"
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.PRODUCT_ID}
                            name={createCardsBasicDetailsStepKeys.definitionId}
                            isFullWidth
                            testId="definitionIdInput"
                        />
                    </Grid>

                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={cardsLang.BASIC_DETAILS_FORM.PRODUCT_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>
                </Grid>

                <FormActionButtons
                    onCancelHandler={handleCloseModal}
                    isSubmitDisabled={isSubmitButtonDisabled}
                    submitButtonText={commonLang.NEXT}
                />
            </form>
        </FormProvider>
    );
}
