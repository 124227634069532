import React from 'react';
import { useParams } from 'react-router-dom';

import { WhiteBackgroundWrapper, PageContainer } from '@dock/react';

import { NavLinkTabSwitcher } from '../../common';
import { CardsMainInfo } from './components/CardsMainInfo';
import { getCardDetailsTabConfig } from './getTabConfig';

type CardDetailProps = {
    children: React.ReactNode;
};

export function CardDetails({ children }: CardDetailProps) {
    const { id: cardId = '' } = useParams();
    const tabs = getCardDetailsTabConfig(cardId);

    return (
        <PageContainer testId="cardDetailsPage">
            <CardsMainInfo />

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
