import { OptionType } from '@dock/react';
import { CardsCardStatus } from '@dock/types-dock-partner';

import { cardStatusMap } from './cardStatusMap';

export const cardStatusOptions: OptionType<CardsCardStatus>[] = [
    {
        label: cardStatusMap[CardsCardStatus.ACTIVATED],
        value: CardsCardStatus.ACTIVATED,
    },
    {
        label: cardStatusMap[CardsCardStatus.LOCKED],
        value: CardsCardStatus.LOCKED,
    },
    {
        label: cardStatusMap[CardsCardStatus.TERMINATED_OBSOLETE],
        value: CardsCardStatus.TERMINATED_OBSOLETE,
    },
    {
        isDisabled: true,
        label: cardStatusMap[CardsCardStatus.UNACTIVATED],
        value: CardsCardStatus.UNACTIVATED,
    },

    {
        isDisabled: true,
        label: cardStatusMap[CardsCardStatus.SUSPENDED],
        value: CardsCardStatus.SUSPENDED,
    },
    {
        isDisabled: true,
        label: cardStatusMap[CardsCardStatus.TERMINATED],
        value: CardsCardStatus.TERMINATED,
    },
];
