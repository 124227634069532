import {
    ChartData,
    CoreChartOptions,
    ElementChartOptions,
    PluginChartOptions,
    DatasetChartOptions,
    ScaleChartOptions,
    DoughnutControllerChartOptions,
    TooltipItem,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

import { AccountsAccountBalances } from '@dock/types-dock-partner';

import { dockAdminTheme } from '../../../common';
import lang from '../../../lang/accountDetails';

export const mapDataDoughnut = ({
    available,
    hold,
}: AccountsAccountBalances): ChartData<'doughnut', number[], string> => ({
    datasets: [
        {
            backgroundColor: [
                dockAdminTheme.palette.warning.light,
                dockAdminTheme.palette.secondary.dark,
            ],
            data: [parseFloat(hold.amount), parseFloat(available.amount)],
        },
    ],
    labels: [lang.HOLD_BALANCE, lang.AVAILABLE_BALANCE],
});

export const findFirstNonMatchingValue = (data: number[], amount: number): number =>
    data?.find((d: number) => !Number.isNaN(d) && d !== amount) || 0;

export const addPercentageSign = (data: number[], amount: number): string => {
    const noMatchingValue = findFirstNonMatchingValue(data, amount);
    if (!noMatchingValue || !amount) return '100 %';
    return `${((amount / (noMatchingValue + amount)) * 100)
        .toFixed(3)
        .slice(0, -1)
        .replace(/[.,]00$/, '')} %`;
};
export const optDoughnut: _DeepPartialObject<
    CoreChartOptions<'doughnut'> &
        ElementChartOptions<'doughnut'> &
        PluginChartOptions<'doughnut'> &
        DatasetChartOptions<'doughnut'> &
        ScaleChartOptions<'doughnut'> &
        DoughnutControllerChartOptions
> = {
    circumference: 180,
    cutout: 23,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                boxHeight: 8,
                boxWidth: 8,
                color: 'black',
                font: {
                    lineHeight: 24,
                    size: 18,
                    weight: 'normal',
                },
                padding: 20,
                usePointStyle: true,
            },
            position: 'left',
        },
        tooltip: {
            callbacks: {
                label: ({ dataset: { data }, parsed }: TooltipItem<'doughnut'>) =>
                    addPercentageSign(data, parsed),
            },
        },
    },
    rotation: -90,
};
