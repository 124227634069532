import type { ListInstrumentsParams, ListHoldersParams } from '@dock/types-dock-partner';

import { ListInstrumentsResponse, ListInstrumentsHoldersResponse } from '@dock/services';

import { getA2ATransfers, getA2ATransfersHolders } from '../../client';
import { fetcher } from './fetcher';

export const fetchA2AList = fetcher<ListInstrumentsResponse, ListInstrumentsParams>(
    getA2ATransfers
);

export const fetchA2ATransferHolderList = fetcher<
    ListInstrumentsHoldersResponse,
    ListHoldersParams
>(getA2ATransfersHolders);
