import { AccountsAccountStatus } from '@dock/types-dock-partner';

import {
    getAllTypedParamValues,
    getTypedParamValue,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
} from '../../../../common';
import { AccountListSearchFields, AccountsListURLParams } from './transformAccountsListParams';

export type PopUpFilterParams = Pick<AccountsListURLParams, 'status' | 'minAmount' | 'maxAmount'>;

const getEmptyFilters =
    (defaultSearchBy: AccountListSearchFields) =>
    (params: URLSearchParams): AccountsListURLParams => ({
        ...getBasicEmptyFilters({ params }),
        searchBy: getTypedParamValue('searchBy', params, AccountListSearchFields, defaultSearchBy),
        status: getAllTypedParamValues('status', params, AccountsAccountStatus),
    });

export type AccountsListFilterReturnType = FilterReturnTypes<
    AccountsListURLParams,
    PopUpFilterParams,
    AccountListSearchFields
>;

const customFieldsExtractor =
    (defaultSearchBy: AccountListSearchFields) => (fields: AccountsListURLParams) => ({
        searchBy: fields.searchBy || defaultSearchBy,
        status: fields.status || [],
    });

export const useAccountsListFilters = (
    defaultSearchBy: AccountListSearchFields = AccountListSearchFields.id
): AccountsListFilterReturnType =>
    useFilters<AccountsListURLParams>(
        getEmptyFilters(defaultSearchBy),
        customFieldsExtractor(defaultSearchBy)
    );
