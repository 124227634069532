import { OptionType } from '@dock/react';
import { CardsVelocityControlTargetKind } from '@dock/types-dock-partner';

import { cardsVCTargetKindMap } from '../../../../common';

export const targetKindOptions: OptionType<CardsVelocityControlTargetKind>[] = [
    {
        label: cardsVCTargetKindMap[CardsVelocityControlTargetKind.CARD],
        value: CardsVelocityControlTargetKind.CARD,
    },
];
