import { FormControl } from '@mui/material';

import { FilterModalButtons, FilterModalWrapper } from '@dock/react';
import { AccountsAccountStatus } from '@dock/types-dock-partner';

import {
    AmountInputRange,
    CheckItems,
    generateKeys,
    SimpleDialogProps,
    useAmounts,
    useCheckItems,
} from '../../../../common';
import accountsLang from '../../../../lang/accounts';
import commonLang from '../../../../lang/common';
import { accountStatusLabelMap } from './accountStatusLabelMap';
import { PopUpFilterParams } from './useAccountsListFilters';

const statusKeys = generateKeys(accountStatusLabelMap);

export function AccountFilterModal({
    fields,
    handleClose,
    isOpen,
    onApply,
}: SimpleDialogProps<PopUpFilterParams>) {
    const [{ maxAmount, minAmount }, setAmount, handleAmountChange] = useAmounts({
        maxAmount: fields.maxAmount || '',
        minAmount: fields.minAmount || '',
    });

    const [status, setStatus, onStatusCheck] = useCheckItems<AccountsAccountStatus>(
        fields.status || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            maxAmount: maxAmount || '',
            minAmount: minAmount || '',
            status,
        });
        handleClose();
    };

    const handleReset = () => {
        setStatus([]);
        setAmount({ maxAmount: '', minAmount: '' });
        onApply({
            maxAmount: '',
            minAmount: '',
            status: [],
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="accountsListFilter"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={accountsLang.ACCOUNT_STATUS_TITLE}
                    items={status}
                    handleChange={onStatusCheck}
                    keys={statusKeys}
                />
            </FormControl>
            <AmountInputRange
                label={commonLang.AVAILABLE_BALANCE}
                maxAmount={maxAmount}
                minAmount={minAmount}
                handleChange={handleAmountChange}
                helperText={commonLang.FILTERS.AMOUNT_TEXT}
            />
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
