import {
    CardsMethod,
    CardsTransactionStatus,
    CardsTransactionKind,
    ListCardTransactionsParams,
} from '@dock/types-dock-partner';

import {
    filterUrlParamsExtractor,
    getAllTypedParamValues,
    getTypedParamValue,
} from '../../extractors';
import { CardTransactionsFilterURLParams, CardTransactionsSearchFields } from '../../formatters';
import { getBasicEmptyFilters, FilterReturnTypes, useFilters } from '../../hooks';

export type CardTxsPopUpFilterParams = Partial<
    Pick<
        CardTransactionsFilterURLParams,
        'sourceMethod' | 'kind' | 'status' | 'minAmount' | 'maxAmount'
    >
>;

const getEmptyFilters =
    (defaultSearchBy: CardTransactionsSearchFields) =>
    (params: URLSearchParams): CardTransactionsFilterURLParams => ({
        ...getBasicEmptyFilters({ params, sortByKey: 'completedTime' }),
        kind: getAllTypedParamValues('kind', params, CardsTransactionKind),
        searchBy: getTypedParamValue(
            'searchBy',
            params,
            CardTransactionsSearchFields,
            defaultSearchBy
        ),
        sourceMethod: getAllTypedParamValues('sourceMethod', params, CardsMethod),
        status: getAllTypedParamValues('status', params, CardsTransactionStatus),
    });

type CardTransactionsFilterReturnType = FilterReturnTypes<
    CardTransactionsFilterURLParams,
    CardTxsPopUpFilterParams,
    CardTransactionsSearchFields
>;

const customFieldsExtractor =
    (defaultSearchBy: CardTransactionsSearchFields) =>
    (fields: CardTransactionsFilterURLParams) => ({
        kind: fields.kind || [],
        searchBy: fields.searchBy || defaultSearchBy,
        sourceMethod: fields.sourceMethod || [],
        status: fields.status || [],
    });

export const useCardTransactionsFilters = (
    defaultSearchBy: CardTransactionsSearchFields
): CardTransactionsFilterReturnType =>
    useFilters<CardTransactionsFilterURLParams>(
        getEmptyFilters(defaultSearchBy),
        customFieldsExtractor(defaultSearchBy)
    );

const cardtxsSearchPropertyMap: Record<string, keyof ListCardTransactionsParams> = {
    [CardTransactionsSearchFields.cardId]: 'card_id',
    [CardTransactionsSearchFields.transactionId]: 'id',
};

const amountAndDateUrlParamsExtractor = filterUrlParamsExtractor({
    amountKey: 'amounts/total/amount',
    dateKey: 'datetimes/completed',
});

const customKeyUrlParams = [
    { key: 'status', value: 'status' },
    { key: 'kind', value: 'kind' },
    { key: 'source/method', value: 'sourceMethod' },
];

const cardTransactionsUrlKeyExtractor = amountAndDateUrlParamsExtractor<ListCardTransactionsParams>(
    [{ key: 'card_id', value: 'cardId' }, ...customKeyUrlParams]
);

type ExtendedCardTransactionsFilterURLParams = CardTransactionsFilterURLParams & {
    cardId?: string;
};

export const transformCardTransactionsParams =
    cardTransactionsUrlKeyExtractor<ExtendedCardTransactionsFilterURLParams>(
        cardtxsSearchPropertyMap
    );

export const transformCardOverviewParams =
    amountAndDateUrlParamsExtractor<ListCardTransactionsParams>(
        customKeyUrlParams
    )<ExtendedCardTransactionsFilterURLParams>(cardtxsSearchPropertyMap);
