import { flow } from 'fp-ts/lib/function';

import { GetCardData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

export const fetchCardDetails = (card_id: string) =>
    requestFlow(handleErrors)(`v0/cards/${card_id}`);

const fetchAndMap = (cardId: string) => fetchCardDetails(cardId)<GetCardData>(getData);

export const getCardDetails = (cardId: string): RequestAndUnwrap<'get', GetCardData> =>
    flow(fetchAndMap(cardId), unwrapService<GetCardData>);
