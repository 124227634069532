import { WhiteBackgroundWrapper, PageContainer, PageActionButtonWrapper } from '@dock/react';

import { NavLinkTabSwitcher } from '../../../../common';
import { cardsBL } from '../../BL/cards.BL';
import { getCardTabConfig } from '../../getTabConfig';
import { CreateCard } from '../CreateCard/CreateCard';
import { CardsListTable } from './CardsListTable';

export function Cards() {
    const tabs = getCardTabConfig();

    return (
        <PageContainer testId="cardPage">
            <PageActionButtonWrapper>
                {cardsBL.isCreateCardAvailable() && <CreateCard />}
            </PageActionButtonWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                <CardsListTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
