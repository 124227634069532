import { AccountsAccountStatus } from '@dock/types-dock-partner';

import accountsLang from '../../../../lang/accounts';

export const accountStatusLabelMap: Record<AccountsAccountStatus, string> = {
    ACTIVATED: accountsLang.ACCOUNT_STATUS.ACTIVATED,
    LOCKED: accountsLang.ACCOUNT_STATUS.LOCKED,
    TERMINATED: accountsLang.ACCOUNT_STATUS.TERMINATED,
    UNACTIVATED: accountsLang.ACCOUNT_STATUS.UNACTIVATED,
};
