import { z } from 'zod';

import {
    externalReferenceStepSchema,
    internalNotesSchema,
    requiredUuidValidation,
    zodEnumFromObjKeys,
} from '@dock/validation';
import validationLang from '@dock/validation/src/lang';

const qrCodeContentMaxLength = 50;
const lineMaxLength = 21;

const lineValidation = z
    .string()
    .trim()
    .max(lineMaxLength, validationLang.GET_MAX_LENGTH(lineMaxLength))
    .optional()
    .or(z.literal(''));

export const createCardsBasicDetailsStep = z.object({
    accountId: requiredUuidValidation,
    cardHolderId: requiredUuidValidation,
    definitionId: requiredUuidValidation,
    tenantId: requiredUuidValidation,
});

export const createCardsBasicDetailsStepKeys = zodEnumFromObjKeys(
    createCardsBasicDetailsStep.shape
).Enum;

export const createCardPersonalisationDetailsStep = z.object({
    line2: lineValidation,
    line3: lineValidation,
    qrCodeContent: z
        .string()
        .trim()
        .max(qrCodeContentMaxLength, validationLang.GET_MAX_LENGTH(qrCodeContentMaxLength))
        .optional()
        .or(z.literal('')),
});

export const createCardPersonalisationDetailsStepKeys = zodEnumFromObjKeys(
    createCardPersonalisationDetailsStep.shape
).Enum;

export const createCardAdditionalDetailsStep = z
    .object({})
    .merge(internalNotesSchema)
    .merge(externalReferenceStepSchema);

export const createCardAdditionalDetailsStepKeys = zodEnumFromObjKeys(
    createCardAdditionalDetailsStep.shape
).Enum;
