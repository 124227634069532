import { A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';

import a2aLang from '../../../../lang/a2a';

export const a2aStatusMap: Record<A2ATransfersInstrumentStatus, string> = {
    ACTIVATED: a2aLang.STATUS.ACTIVATED,
    LOCKED: a2aLang.STATUS.LOCKED,
    TERMINATED: a2aLang.STATUS.TERMINATED,
    TERMINATED_OBSOLETE: a2aLang.STATUS.TERMINATED_OBSOLETE,
    UNACTIVATED: a2aLang.STATUS.UNACTIVATED,
};
