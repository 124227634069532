export const ACCOUNT_TXS_DETAILS_KEY = 'accountTransactionsDetails';
export const ACCOUNTS_LIST_KEY = 'accountsList';
export const CARD_TRANSACTIONS_KEY = 'cardTransactions';
export const CARD_DETAILS_KEY = 'cardDetails';
export const CARD_AUTHORIZATION_KEY = 'cardAuthorization';
export const CARD_AUTHORIZATION_EVENT_KEY = 'cardAuthorizationEvents';
export const VELOCITY_CONTROL_KEY = 'VelocityControlList';
export const CARDS_LIST_KEY = 'cardsList';
export const CARD_HOLDERS_LIST_KEY = 'cardHoldersList';
export const A2A_LIST_KEY = 'a2aList';
export const A2A_TRANSACTIONS_KEY = 'a2aTransactions';
export const A2A_PAYMENT_SCHEDULE_KEY = 'a2aPaymentSchedule';
export const A2A_PAYMENT_EVENTS_KEY = 'a2aPaymentEvents';
export const BANK_TRANSFERS_INSTRUMENTS_KEY = 'bankTransfersInstrumentsKey';
export const BANK_TRANSFERS_HOLDERS_KEY = 'bankTransfersHoldersKey';
export const BANK_TRANSFERS_TRANSACTIONS_KEY = 'bankTransfersTransactionsKey';
