import { GetCardsData, ListCardHoldersData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import {
    requestFlow,
    getDataAndHeaders,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type CardsListWithToken = ResponseWithContinuationToken<GetCardsData>;

// service
export const fetchCardsList = requestFlow(handleErrors)(`v0/cards`);
// service & mapper
const fetchAndHandle = fetchCardsList<CardsListWithToken>(getDataAndHeaders);

// service & map & and open the monad
export const getCardsList: RequestAndUnwrap<'get', CardsListWithToken> = (verb) =>
    unwrapService<CardsListWithToken>(fetchAndHandle(verb));

export type CardHolderListWithToken = ResponseWithContinuationToken<ListCardHoldersData>;

export const fetchCardHoldersList = requestFlow(handleErrors)(`v0/cards/holders`);
const fetchHoldersAndHandle = fetchCardHoldersList<CardHolderListWithToken>(getDataAndHeaders);

export const getCardHoldersList: RequestAndUnwrap<'get', CardHolderListWithToken> = (verb) =>
    unwrapService<CardHolderListWithToken>(fetchHoldersAndHandle(verb));
