import { CardAuthorizationEventsWithToken } from '@dock/services';
import { ListCardAuthorizationEventsParams } from '@dock/types-dock-partner';

import { getCardsAuthorizationsEvents } from '../../client';
import { fetcher } from './fetcher';

export const fetchCardsAuthorizationsEventsCommon = fetcher<
    CardAuthorizationEventsWithToken,
    ListCardAuthorizationEventsParams
>(getCardsAuthorizationsEvents);
