import TableCell from '@mui/material/TableCell';

import { AmountCell, TextWithCopyIcon, TextWithCopyIconContent } from '@dock/react';

import {
    TablesTypography,
    CustomVirtualisationWrapper,
    LinkWithCopyIcon,
    ENV_VARS,
} from '../../../../common';
import { AccountTransactionTableRow } from './types';

export function TransactionRow({
    amount,
    balance,
    bookingTime,
    counterparty,
    currency,
    id,
    instrumentId,
    instrumentTxId,
    paymentInstrument,
    transactionDetailLink,
    transactionLink,
}: AccountTransactionTableRow) {
    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="transactionsRows"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell>
                <TablesTypography testId="valueDate" content={bookingTime} />
            </TableCell>
            <TableCell>
                <TextWithCopyIcon text={id}>
                    <TextWithCopyIconContent text={id} testId="key" ellipsis="start" />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell>
                <TablesTypography
                    content={paymentInstrument}
                    testId={`method${paymentInstrument.replace(/\s+/g, '')}`}
                />
            </TableCell>
            <TableCell>
                <TextWithCopyIcon text={instrumentId}>
                    {transactionLink ? (
                        <LinkWithCopyIcon
                            href={transactionLink}
                            text={instrumentId}
                            ellipsis="start"
                            testId="instrumentId"
                        />
                    ) : (
                        <TextWithCopyIconContent
                            text={instrumentId}
                            testId="instrumentId"
                            ellipsis="start"
                        />
                    )}
                </TextWithCopyIcon>
            </TableCell>
            <TableCell>
                <TextWithCopyIcon text={instrumentTxId}>
                    {transactionDetailLink ? (
                        <LinkWithCopyIcon
                            href={transactionDetailLink}
                            text={instrumentTxId}
                            ellipsis="start"
                            testId="instrumentTXId"
                        />
                    ) : (
                        <TextWithCopyIconContent
                            text={instrumentTxId}
                            testId="instrumentTXId"
                            ellipsis="start"
                        />
                    )}
                </TextWithCopyIcon>
            </TableCell>
            <TableCell>
                <TablesTypography content={counterparty} />
            </TableCell>
            <AmountCell amount={amount} />
            <AmountCell amount={balance} testId="balanceCell" />
            <TableCell>
                <TablesTypography testId="currencyCell" content={currency} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
