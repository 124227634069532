import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import type { z } from 'zod';

import { ControlledSingleSelect, ControlledInput, FormActionButtons } from '@dock/react';
import { CardsVelocityControlTargetKind } from '@dock/types-dock-partner';
import { createCardVCBasicDetailsStep, createCardVCBasicDetailsStepKeys } from '@dock/validation';

import commonLang from '../../../../lang/common';
import createCardSCLang from '../../../../lang/createCardSC';
import { CreateCardVCFormType, Mode } from '../types';
import { FormWrapper } from './FormWrapper';
import { scopeOptions } from './scopeOptions';
import { targetKindOptions } from './targetKindOptions';

export type BasicDetailsStepFormValues = z.infer<typeof createCardVCBasicDetailsStep>;

type BasicDetailsStepProps = {
    handleForm: (values: Partial<CreateCardVCFormType>) => void;
    handleNextStep: () => void;
    defaultValues: BasicDetailsStepFormValues;
    mode?: Mode;
};

export function BasicDetailsStep({
    defaultValues,
    handleForm,
    handleNextStep,
    mode = 'create',
}: BasicDetailsStepProps) {
    const methods = useForm<BasicDetailsStepFormValues>({
        defaultValues,
        resolver: zodResolver(createCardVCBasicDetailsStep),
    });

    const { control, handleSubmit } = methods;

    const onSubmitHandler = (values: BasicDetailsStepFormValues) => {
        handleForm({
            label: values.label,
            target: { id: values.targetId, kind: values.targetKind },
            tenantId: values.tenantId,
        });
        handleNextStep();
    };

    const isCard = defaultValues.targetKind === CardsVelocityControlTargetKind.CARD;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <FormWrapper testId="basicDetailsStep">
                    <Typography variant="h4">{commonLang.STEPS.BASIC_DETAILS}</Typography>
                    <ControlledInput
                        control={control}
                        label={commonLang.LABEL}
                        name={createCardVCBasicDetailsStepKeys.label}
                        isFullWidth
                        testId="nameIdInput"
                        helperText={createCardSCLang.NAME_HELP_TEXT}
                        autoFocus
                    />
                    <ControlledSingleSelect
                        label={createCardSCLang.SELECT_CATEGORY}
                        options={targetKindOptions}
                        control={control}
                        key={createCardVCBasicDetailsStepKeys.targetKind}
                        name={createCardVCBasicDetailsStepKeys.targetKind}
                        testId="targetKindSelect"
                        defaultOption={defaultValues.targetKind}
                        disabled
                    />
                    <ControlledInput
                        control={control}
                        label={
                            isCard
                                ? createCardSCLang.CARD_ID
                                : createCardSCLang.CARD_PRODUCT_DEFINITION_ID
                        }
                        name={createCardVCBasicDetailsStepKeys.targetId}
                        isFullWidth
                        testId="targetIdInput"
                        helperText={createCardSCLang.CARD_ID_HELP_TEXT}
                        disabled={mode === 'edit'}
                    />
                    <ControlledSingleSelect
                        label={createCardSCLang.SCOPE}
                        options={scopeOptions}
                        control={control}
                        key={createCardVCBasicDetailsStepKeys.scope}
                        name={createCardVCBasicDetailsStepKeys.scope}
                        testId="scopeSelect"
                        defaultOption={defaultValues.scope}
                        helperText={createCardSCLang.SCOPE_HELP_TEXT}
                        disabled
                    />
                    <ControlledInput
                        control={control}
                        label={createCardSCLang.TENANT_ID}
                        name={createCardVCBasicDetailsStepKeys.tenantId}
                        isFullWidth
                        testId="tenantIdInput"
                        disabled={mode === 'edit'}
                    />

                    <FormActionButtons submitButtonText={commonLang.NEXT} />
                </FormWrapper>
            </form>
        </FormProvider>
    );
}
