import { useMutation } from '@tanstack/react-query';

import type { CardsCreateCard, CreateCardData } from '@dock/types-dock-partner';

import { PayloadWithIdempotencyKey } from '@dock/types-common';

import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { CARDS_LIST_KEY } from '../../keys';
import { createNewCard } from '../methods/cards/createNewCard';
import { updateTokensOnMutate } from '../updateTokensOnMutate';

export function useCreateNewCard() {
    const { data, error, isError, isLoading, isSuccess, mutateAsync } = useMutation<
        CreateCardData,
        PartnerServiceError,
        PayloadWithIdempotencyKey<CardsCreateCard>
    >((variables) => createNewCard(variables.payload, variables.idempotencyKey), {
        onMutate: updateTokensOnMutate,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [CARDS_LIST_KEY],
            });
        },
    });

    return {
        createNewCardMutation: mutateAsync,
        data,
        error,
        isError,
        isLoading,
        isSuccess,
    };
}
