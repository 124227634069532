import { FormControl } from '@mui/material';

import type { CardsVelocityControlWindowKind } from '@dock/types-dock-partner';

import { FilterModalButtons, FilterModalWrapper } from '@dock/react';

import {
    CheckItems,
    useCheckItems,
    SimpleDialogProps,
    generateKeys,
    cardsVCWindowKindMap,
} from '../../../../../common';
import commonLang from '../../../../../lang/common';
import { PopUpFilterParams } from './useSpendingControlListFilters';

const statusKeys = generateKeys(cardsVCWindowKindMap);

export function SpendingControlListFilters({
    fields,
    handleClose,
    isOpen,
    onApply,
}: SimpleDialogProps<PopUpFilterParams>) {
    const [kind, setStatus, onStatusCheck] = useCheckItems<CardsVelocityControlWindowKind>(
        fields.kind || []
    );

    const handleApply = () => {
        onApply({
            ...fields,
            kind,
        });
        handleClose();
    };

    const handleReset = () => {
        setStatus([]);
        onApply({
            kind: [],
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="spendingControlListFilters"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={commonLang.WINDOW}
                    items={kind}
                    handleChange={onStatusCheck}
                    keys={statusKeys}
                />
            </FormControl>
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
