import { Paper, Collapse, Typography } from '@mui/material';

import {
    CollapsableTitle,
    DetailsTitle,
    HeadlessTable,
    SimpleRow,
    TableSkeleton,
    useBoolean,
    TextWithCopyIcon,
    AmountSimpleRow,
} from '@dock/react';
import { transformDate } from '@dock/react-util';

import {
    LinkWithCopyIcon,
    StatusChip,
    transactionMethodsMap,
    cardAuthStatusesMap,
    cardAuthStatusColorMap,
    CARD_DETAILS_AUTHORIZATION_ROUTE,
    interpolateParams,
} from '../../../common';
import authDetailsLang from '../../../lang/cardAuthorizationDetails';
import commonLang from '../../../lang/common';
import { useCardAuthorizations } from '../../../services';

type CardAuthorizationDetailTableProps = {
    cardId: string;
    authorizationID: string;
};

const SKELETON_ROWS = 11;
const SKELETON_COLUMNS = 2;

export function CardAuthorizationDetailTable({
    authorizationID,
    cardId,
}: CardAuthorizationDetailTableProps) {
    const [isAuthOpen, flipAuthCollapse] = useBoolean(true);
    const { data, isFetching } = useCardAuthorizations({
        params: {
            card_id: [cardId],
            id: [authorizationID],
        },
    });

    const shouldShowAuthorizations = Boolean(!isFetching && data.length);

    const authorization = data[0];

    return (
        <Paper sx={{ mb: '40px' }}>
            <CollapsableTitle
                isOpen={isAuthOpen}
                titleElement={<DetailsTitle title={authDetailsLang.CARD_AUTHORIZATION_TITLE} />}
                handleCollapsableElement={flipAuthCollapse}
            />
            <Collapse in={isAuthOpen} timeout="auto" unmountOnExit>
                <HeadlessTable>
                    <TableSkeleton
                        rows={SKELETON_ROWS}
                        columns={SKELETON_COLUMNS}
                        shouldShow={isFetching}
                    />
                    {shouldShowAuthorizations && authorization && (
                        <>
                            <SimpleRow
                                label={authDetailsLang.ID}
                                value={
                                    <TextWithCopyIcon text={authorization.id}>
                                        <Typography>{authorization.id}</Typography>
                                    </TextWithCopyIcon>
                                }
                            />
                            <SimpleRow
                                label={authDetailsLang.CARD_ID}
                                value={
                                    <TextWithCopyIcon text={authorization.cardId}>
                                        <LinkWithCopyIcon
                                            href={interpolateParams(
                                                CARD_DETAILS_AUTHORIZATION_ROUTE,
                                                {
                                                    id: authorization.cardId,
                                                }
                                            )}
                                            text={authorization.cardId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                            <SimpleRow
                                label={commonLang.STATUS}
                                value={
                                    <StatusChip
                                        color={cardAuthStatusColorMap[authorization.status]}
                                        label={
                                            cardAuthStatusesMap[authorization.status] ||
                                            authorization.status
                                        }
                                    />
                                }
                            />
                            <AmountSimpleRow
                                label={commonLang.AMOUNT}
                                amount={authorization.amounts.approved.amount}
                            />
                            <SimpleRow
                                label={authDetailsLang.CURRENCY}
                                value={authorization.amounts.approved.currency}
                            />
                            <SimpleRow
                                label={authDetailsLang.TIME_INITIATED}
                                value={transformDate(new Date(authorization.datetimes.initiated))}
                            />
                            <SimpleRow
                                label={authDetailsLang.TIME_TO_LAST_UPDATE}
                                value={transformDate(new Date(authorization.datetimes.lastUpdated))}
                            />
                            <SimpleRow
                                label={authDetailsLang.METHOD}
                                value={transactionMethodsMap[authorization.source.method]}
                            />
                            <SimpleRow
                                label={authDetailsLang.MERCHANT_NAME}
                                value={authorization.merchant.name || ''}
                            />
                            <SimpleRow
                                label={authDetailsLang.MCC}
                                value={authorization.merchant.mcc || ''}
                            />
                            <SimpleRow
                                label={authDetailsLang.MID}
                                value={authorization.merchant.mid || ''}
                            />
                        </>
                    )}
                </HeadlessTable>
            </Collapse>
        </Paper>
    );
}
