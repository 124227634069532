import { CardsCardStatus } from '@dock/types-dock-partner';

import {
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
    getAllTypedParamValues,
    getTypedParamValue,
    BaseFilterParams,
} from '../../../../common';

export enum CardsListSearchFields {
    cardId = 'cardId',
    externalReference = 'externalReference',
}

export type CardsListFiltersURLParams = BaseFilterParams<CardsListSearchFields> & {
    status?: CardsCardStatus[];
};

export type CardsListPopUpFilterParams = Pick<CardsListFiltersURLParams, 'status'>;

export type CardListFilterReturnType = FilterReturnTypes<
    CardsListFiltersURLParams,
    CardsListPopUpFilterParams,
    CardsListSearchFields
>;

const getEmptyFilters = (params: URLSearchParams): CardsListFiltersURLParams => ({
    ...getBasicEmptyFilters({ params }),
    searchBy: getTypedParamValue(
        'searchBy',
        params,
        CardsListSearchFields,
        CardsListSearchFields.cardId
    ),
    status: getAllTypedParamValues('status', params, CardsCardStatus),
});

const customFieldsExtractor = (fields: CardsListFiltersURLParams) => ({
    status: fields.status || [],
});

export const useCardsListFilters = (): CardListFilterReturnType =>
    useFilters<CardsListFiltersURLParams>(getEmptyFilters, customFieldsExtractor);
