export default {
    BASIC_DETAILS_FORM: {
        ACCOUNT_ID_TOOLTIP:
            'The ID of the account to which card should be linked. Cards must always be linked to the account. In order to spend from card, the account associated needs to have an appropriate balance.',
        PRODUCT_ID_TOOLTIP:
            'Product ID defines the type (virtual, physical) and behavior of a card.',
    },
    CARD_HOLDER_ID: 'Card holder ID',
    CARD_HOLDERS_TABLE: {
        COLUMNS: {
            CARD_HOLDER_ID: 'Card holder ID',
            COUNTRY_CODE: 'Country code',
            IDENTITY_ID: 'Identity ID',
            NAME_ON_CARD: 'Name on card',
            PHONE_NUMBER: 'Phone number',
            TENANT_ID: 'Tenant ID',
        },
    },
    CARD_STATUS: {
        ACTIVATED: 'Activated',
        LOCKED: 'Locked',
        SUSPENDED: 'Suspended',
        TERMINATED: 'Terminated',
        TERMINATED_OBSOLETE: 'Terminated obsolete',
        UNACTIVATED: 'Unactivated',
    },
    CARDS_TABLE: {
        COLUMNS: {
            CARD_HOLDER_ID: 'Card holder ID',
            CARD_ID: 'Card ID',
            CARD_STATUS: 'Status',
            CREATED_TIME: 'Created time',
            EXPIRATION_TIME: 'Expiration time',
            EXTERNAL_REFERENCE: 'External reference',
        },
    },
    CREATE_CARD: 'Create card',
    CREATE_NEW: {
        FAILED: 'Card creation failed. Please try again.',
        SUCCESS_POST: ' was created successfully.',
        SUCCESS_PRE: 'New Card ',
        TITLE: 'Create new card',
    },
    HOLDER_SEARCH_OPTION: {
        EXTERNAL_REFERENCE: 'External reference',
        ID: 'Card holder ID',
        IDENTITY_ID: 'Identity ID',
        TENANT_ID: 'Tenant ID',
    },
    HOLDERS_SEARCH: 'Search for card holder ID',
    KIND: 'Kind',
    LINE_2: 'Line 2',
    LINE_3: 'Line 3',
    PERSONAL_DETAILS: {
        LINE_2_TOOLTIP:
            'Use to add specific data to be printed on the card (e.g. human readable Card ID).',
        LINE_3_TOOLTIP: 'Use to add additional data to be printed on the card.',
        NOTE: 'These fields are currently only available for physical cards. Go to next step if you are creating a virtual card.',
        QR_CODE_CONTENT_TOOLTIP: 'Input content to generate QR code to be printed on the card.',
    },
    PHYSICAL: 'Physical',
    QR_CODE_CONTENT: 'QR code content',
    SEARCH_OPTION: {
        CARD_ID: 'Card ID',
        EXTERNAL_REFERENCE: 'External reference',
        ID: 'Spending control ID',
    },
    SOURCE: 'Payment source',
    TABS: {
        CARD: {
            HOLDERS: 'Card holders',
            LIST: 'Card list',
        },
    },
    TYPE: 'Type',
    VIRTUAL: 'Virtual',
};
