import { Response } from '@dock/http-request';
import { ResponseWithContinuationToken } from '@dock/services';
import { RequestParams } from '@dock/types-dock-partner';

import { getData, getDataAndHeaders } from '../extractors';

export const handleServiceRequestWithToken = async <T, R>(
    apiFunction: (query: T, params: RequestParams) => Promise<Response<R>>,
    query: T,
    params: RequestParams = {}
): Promise<ResponseWithContinuationToken<R>> => {
    const response = await apiFunction(query, params);
    return getDataAndHeaders<R>(response);
};

export const handleServiceRequest = async <T, R>(
    apiFunction: (data: T, params: RequestParams) => Promise<Response<R>>,
    data: T,
    params: RequestParams = {}
): Promise<R> => {
    const response = await apiFunction(data, params);
    return getData<R>(response);
};
