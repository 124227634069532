import { ListBankTransferInstrumentParams } from '@dock/types-dock-partner';

import { bankTransfersInstrumentMapper } from '../mappers/bankTransfersInstrumentMapper';
import { useBankTransferInstrumentsList } from './bankTransfers';

type UseBankTransferInstrumentsType = {
    params: ListBankTransferInstrumentParams;
    enabled?: boolean;
};

export const useBankTransferInstruments = (params: UseBankTransferInstrumentsType) => {
    const { data, ...rest } = useBankTransferInstrumentsList(params);

    return {
        data: bankTransfersInstrumentMapper(data),
        ...rest,
    };
};
