export default {
    AUTHORIZATION_EVENT_STATUS: {
        APPROVED: 'Approved',
        DECLINED: 'Declined',
        PENDING: 'Pending',
    },
    AUTHORIZATION_ID: 'Authorization ID',
    CARD_ACCORDION: {
        CARD_KIND_TITLE: 'Card kind & statuses',
        CARD_STATUS_TITLE: 'Card status',
        EDIT_STATUS: 'Edit status',
        FULFILLMENT_STATUS_TITLE: 'Fulfillment status',
        LINE_2: 'Line 2',
        LINE_3: 'Line 3',
        PERSONALISATION_AND_OTHER_DETAILS: 'Personalisation & other details',
        QR_CODE_CONTENT: 'QR code content',
    },
    CARD_AUTHORIZATION_STATUS: {
        APPROVED: 'Approved',
        CLOSED: 'Closed',
        COMPLETED: 'Completed',
        DECLINED: 'Declined',
        EXPIRED: 'Expired',
        PENDING: 'Pending',
        REVERSED: 'Reversed',
    },
    CARD_AUTHORIZATIONS_TABLE: {
        AMOUNT: 'Amount',
        AUTH_ID: 'Authorization ID',
        CURRENCY: 'Currency',
        INITIATED_TIME: 'Initiated time',
        MERCHANT_NAME: 'Merchant name',
        PAYMENT_SOURCE: 'Payment source',
        STATUS: 'Status',
    },
    CARD_STATUS: 'Card status',
    CARD_TRANSACTION_KIND: {
        CAPTURE: 'Capture',
        CHARGEBACK: 'Chargeback',
        REFUND: 'Refund',
        REPRESENTMENT: 'Representment',
    },
    CARD_TRANSACTION_METHODS: {
        ATM: 'ATM',
        ECOMMERCE: 'E-Commerce',
        MAIL: 'Mail',
        MOTO: 'MOTO',
        OTHER: 'Other',
        PHONE: 'Phone',
        POS: 'POS',
        UNAVAILABLE: 'Unavailable',
    },
    CARD_TRANSACTION_STATUS: {
        COMPLETED: 'Completed',
        PENDING: 'Pending',
    },
    CARD_TRANSACTIONS_TABLE: {
        AMOUNT: 'Amount',
        COMPLETED_TIME: 'Completed time',
        CURRENCY: 'Currency',
        MERCHANT_NAME: 'Merchant name',
        PAYMENT_SOURCE: 'Payment source',
        STATUS: 'Status',
        TX_ID: 'Transaction ID',
        TYPE: 'Type',
    },
    CHANGE_CARD_STATUS: 'Change card status',
    CHANGE_CARD_STATUSES: {
        ACTIVATED: 'Activated',
        LOCKED: 'Locked',
        SUSPENDED: 'Suspended',
        TERMINATED: 'Terminated',
        TERMINATED_OBSOLETE: 'Terminated obsolete',
        UNACTIVATED: 'Unactivated',
    },
    CHANGE_STATUS: 'Change status',
    CURRENT_CARD_STATUS: 'Current card status is:',
    INITIATED_TIME: 'Initiated time',
    KINDS: {
        ADVICE: 'Advice',
        CREATION: 'Creation',
        EXPIRY: 'Expiry',
        INCREASE: 'Increase',
        REVERSAL: 'Reversal',
    },
    MCC: 'MCC',
    MID: 'MID',
    OUTCOME_REASON: {
        ACCOUNT_INSUFFICIENT_FUNDS: 'Account insufficient funds',
        ADDRESS_VERIFICATION_SUCCESS: 'Address verification success',
        APPROVAL: 'Approval',
        AUTHORIZATION_CONTROL_DECLINED: 'Authorization control declined',
        AUTHORIZATION_PARTIALLY_APPROVED: 'Authorization partially approved',
        CARD_ACCOUNT_FUNDING_TRANSACTION_NOT_ALLOWED:
            'Card account funding transaction not allowed',
        CARD_ACCOUNT_VERIFICATION_SUCCESS: 'Card account verification success',
        CARD_ACTIVATION_CHECK_SUCCESS: 'Card activation check success',
        CARD_ADDRESS_VERIFICATION_SYSTEM_NO_INFO: 'Card address verification system no info',
        CARD_ATM_NOT_ALLOWED: 'Card ATM not allowed',
        CARD_CHANNEL_OTHER_NOT_ALLOWED: 'Card channel other not allowed',
        CARD_CVV_LIMIT_EXCEEDED: 'Card CVV limit exceeded',
        CARD_ECOM_TRANSACTION_NOT_ALLOWED: 'Card ECOM transaction not allowed',
        CARD_EXPIRATION_MISMATCH: 'Card expiration mismatch',
        CARD_EXPIRED: 'Card expired',
        CARD_INCORRECT_PIN: 'Card incorrect PIN',
        CARD_INVALID_CVV2: 'Card invalid CVV2',
        CARD_LOCKED: 'Card locked',
        CARD_MAIL_ORDER_TRANSACTION_NOT_ALLOWED: 'Card mail order transaction not allowed',
        CARD_MCC_BLOCKED: 'Card MCC blocked',
        CARD_NETWORK_LOAD_NOT_ALLOWED: 'Card network load not allowed',
        CARD_NETWORK_RESPONSE: 'Card network response',
        CARD_PHONE_ORDER_TRANSACTION_NOT_ALLOWED: 'Card phone order transaction not allowed',
        CARD_QUASI_CASH_TRANSACTION_NOT_ALLOWED: 'Card quasi cash transaction not allowed',
        CARD_SPENDING_EXCEEDED: 'Card spending exceeded',
        CARD_TERMINATED: 'Card terminated',
        CARD_TRANSACTION_ALREADY_REVERSED: 'Card transaction already reversed',
        CARD_TRANSACTION_NOT_PERMITTED: 'Card transaction not permitted',
        CARD_UNACTIVATED: 'Card unactivated',
        CARD_USAGE_COUNT_REACHED: 'Card usage count reached',
        VELOCITY_CONTROL_DECLINED: 'Velocity control declined',
        WALLET_TOKEN_EXPIRED: 'Wallet token expired',
        WALLET_TOKEN_LOCKED: 'Wallet token locked',
        WALLET_TOKEN_NOT_ACTIVE: 'Wallet token not activated',
        WALLET_TOKEN_NOT_FOUND: 'Wallet token not found',
        WALLET_TOKEN_NOT_PRESENT: 'Wallet token not present',
        WALLET_TOKEN_PENDING: 'Wallet token pending',
        WALLET_TOKEN_SUSPENDED: 'Wallet token suspended',
        WALLET_TOKEN_TERMINATED: 'Wallet token terminated',
    },
    PHYSICAL_CARD_STATUS: {
        ISSUED: 'Issued',
        ORDERED: 'Ordered',
    },
    SEARCH_BY_AUTHORIZATION_ID: 'Search by Authorization ID',
    SEARCH_BY_TRANSACTION_ID: 'Search by Transaction ID',
    STATUS_CHANGE_FAILED: "Card status wasn't changed. Please, try again.",
    STATUS_CHANGED: 'Card status was successfully changed.',
    TABS: {
        CARD_DETAILS: {
            AUTHORIZATIONS: 'Authorizations',
            TRANSACTIONS: 'Transactions',
        },
    },
};
