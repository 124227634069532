import {
    TENANTS_ROUTE,
    ACCOUNTS_ROUTE,
    CARD_LIST_ROUTE,
    CARD_SPENDING_CONTROLS_ROUTE,
    CARD_SPENDING_CONTROL_DETAILS_ROUTE,
    PRODUCT_DEFINITIONS_ROUTE,
    IDENTITIES_ROUTE,
    USERS_ROUTE,
    AUDIT_ROUTE,
    LOGIN_ROUTE,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    CARD_TRANSACTION_DETAILS_ROUTE,
    CARD_AUTHORIZATION_DETAILS_ROUTE,
    ACCOUNT_DETAILS_PAYMENT_INST_ROUTE,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    CARD_DETAILS_AUTHORIZATION_ROUTE,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE,
    BANK_TRANSFERS_HOLDERS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_ROUTE,
    CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
    CARD_TRANSACTIONS_OVERVIEW_ROUTE,
    A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
    CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
    A2A_TRANSACTIONS_ROUTE,
    A2A_HOLDERS_ROUTE,
    CARD_HOLDERS_ROUTE,
    CARD_SPENDING_CONTROL_CREATE_ROUTE,
    interpolateParams,
    TitleForSpecificLocation,
    CARD_SPENDING_CONTROL_EDIT_ROUTE,
    BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
} from '../common';
import a2aLang from '../lang/a2a';
import cardOverviewLang from '../lang/cardTransactionsOverview';
import createCardSCLang from '../lang/createCardSC';
import editSpendingControl from '../lang/editSpendingControl';

// TODO: consider to merge this config with router config from packages/dock-partner/src/routes/Routes.tsx
export const titlesForSpecificLocations: TitleForSpecificLocation[] = [
    {
        location: TENANTS_ROUTE,
        title: 'Tenants',
    },
    {
        location: ACCOUNTS_ROUTE,
        title: 'Accounts',
    },
    {
        location: CARD_LIST_ROUTE,
        title: 'Cards',
    },
    {
        location: CARD_HOLDERS_ROUTE,
        title: 'Cards',
    },
    {
        location: CARD_SPENDING_CONTROLS_ROUTE,
        title: 'Cards spending controls',
    },
    {
        location: (params) => interpolateParams(CARD_SPENDING_CONTROL_DETAILS_ROUTE, params),
        title: 'Cards spending controls details',
    },
    {
        location: PRODUCT_DEFINITIONS_ROUTE,
        title: 'Product Definitions',
    },
    {
        location: IDENTITIES_ROUTE,
        title: 'Identities',
    },
    {
        location: USERS_ROUTE,
        title: 'Users',
    },
    {
        location: AUDIT_ROUTE,
        title: 'Audit',
    },
    {
        location: LOGIN_ROUTE,
        title: 'Login',
    },
    {
        location: BANK_TRANSFERS_HOLDERS_ROUTE,
        title: 'Bank transfers',
    },
    {
        location: BANK_TRANSFERS_INSTRUMENTS_ROUTE,
        title: 'Bank transfers',
    },
    {
        location: (params) => interpolateParams(BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE, params),
        title: 'Bank transfer details',
    },
    {
        location: (params) => interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, params),
        title: 'Account details',
    },
    {
        location: (params) => interpolateParams(ACCOUNT_DETAILS_PAYMENT_INST_ROUTE, params),
        title: 'Account details',
    },
    {
        location: (params) => interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, params),
        title: 'Card details',
    },
    {
        location: (params) => interpolateParams(CARD_DETAILS_AUTHORIZATION_ROUTE, params),
        title: 'Card details',
    },
    {
        location: (params) => interpolateParams(CARD_TRANSACTION_DETAILS_ROUTE, params),
        title: 'Transaction details',
    },
    {
        location: (params) => interpolateParams(CARD_AUTHORIZATION_DETAILS_ROUTE, params),
        title: 'Authorization details',
    },
    {
        location: A2A_TRANSACTIONS_ROUTE,
        title: a2aLang.A2A_TITLE,
    },
    {
        location: A2A_HOLDERS_ROUTE,
        title: a2aLang.A2A_TITLE,
    },
    {
        location: (params) => interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, params),
        title: a2aLang.A2A_DETAILS_TITLE,
    },
    {
        location: (params) => interpolateParams(A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE, params),
        title: a2aLang.A2A_TRANSACTION_DETAILS_TITLE,
    },
    {
        location: (params) =>
            interpolateParams(A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE, params),
        title: a2aLang.A2A_PAYMENTS_DETAILS_TITLE,
    },
    {
        location: (params) => interpolateParams(A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE, params),
        title: a2aLang.A2A_DETAILS_TITLE,
    },
    {
        location: CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
        title: cardOverviewLang.CARD_TXS_OVERVIEW_TITLE,
    },
    {
        location: CARD_TRANSACTIONS_OVERVIEW_ROUTE,
        title: cardOverviewLang.CARD_TXS_OVERVIEW_TITLE,
    },
    {
        location: CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
        title: cardOverviewLang.CARD_TXS_OVERVIEW_TITLE,
    },
    {
        location: CARD_SPENDING_CONTROL_CREATE_ROUTE,
        title: createCardSCLang.TITLE,
    },
    {
        location: (params) => interpolateParams(CARD_SPENDING_CONTROL_EDIT_ROUTE, params),
        title: editSpendingControl.TITLE,
    },
    {
        location: (params) => interpolateParams(BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE, params),
        title: 'Bank transfer payment details',
    },
];
