import { Outlet } from 'react-router-dom';

import { Helmet } from '@dock/react';

import { useTitleLocation } from '../../common';
import { titlesForSpecificLocations } from '../../configs/titlesForSpecificLocation';

export function LocalHelmet() {
    const title = useTitleLocation(titlesForSpecificLocations);

    return (
        <>
            <Helmet title={title} />
            <Outlet />
        </>
    );
}
