import { CardDetails } from './CardDetails';
import { CardAuthorizationsTable } from './components/CardAuthorizations/CardAuthorizationsTable';

export function CardDetailsAuthorizationsTable() {
    return (
        <CardDetails>
            <CardAuthorizationsTable />
        </CardDetails>
    );
}
