import { CardDetails } from './CardDetails';
import { CardTransactionsTable } from './components/CardTransactionsTable/CardTransactionsTable';

export function CardDetailsTxsTable() {
    return (
        <CardDetails>
            <CardTransactionsTable />
        </CardDetails>
    );
}
