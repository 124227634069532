import { flow } from 'fp-ts/lib/function';

import { CreateVelocityControlData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import { requestFlow, RequestAndUnwrap, unwrapService, getData } from '../../../../utilities';

const createCardVCHandler = requestFlow(handleErrors)(`v0/cards/velocity_controls`);

const fetchAndHandle = createCardVCHandler<CreateVelocityControlData>(getData);

export const createCardVC: RequestAndUnwrap<'post', CreateVelocityControlData> = flow(
    fetchAndHandle,
    unwrapService<CreateVelocityControlData>
);
