import { fetchAccountsList } from '../../api';
import { ACCOUNTS_LIST_KEY, ACCOUNT_TXS_DETAILS_KEY } from '../../keys';
import { fetchAccountTransactionsWithCounterparty } from '../methods/fetchAccountTransactionsWithCounterparty';
import { useInfiniteQueryWith } from './useInfiniteQueryWith';

export const useAccountsList = useInfiniteQueryWith({
    fetcher: fetchAccountsList,
    queryKey: ACCOUNTS_LIST_KEY,
});

const useAccountDetailsTxsMain = (key: string) =>
    useInfiniteQueryWith({
        fetcher: fetchAccountTransactionsWithCounterparty,
        queryKey: key,
    });

export const useAccountDetailsTxs = useAccountDetailsTxsMain(ACCOUNT_TXS_DETAILS_KEY);
