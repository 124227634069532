import { useMutation } from '@tanstack/react-query';

import type { PayloadWithIdempotencyKey } from '@dock/types-common';
import type { A2ATransfersCreatePayment, A2ATransfersPayment } from '@dock/types-dock-partner';

import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { A2A_TRANSACTIONS_KEY } from '../../keys';
import { payA2A } from '../methods/a2a';
import { updateTokensOnMutate } from '../updateTokensOnMutate';

export function usePayA2A(senderId: string) {
    const { data, error, isError, isLoading, isSuccess, mutateAsync } = useMutation<
        A2ATransfersPayment,
        PartnerServiceError,
        PayloadWithIdempotencyKey<A2ATransfersCreatePayment>
    >((variables) => payA2A(senderId, variables.payload, variables.idempotencyKey), {
        onMutate: updateTokensOnMutate,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [A2A_TRANSACTIONS_KEY],
            });
        },
    });

    return {
        data,
        error,
        isError,
        isLoading,
        isSuccess,
        mutateAsync,
    };
}
