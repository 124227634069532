import { AccountsAccountStatus } from '@dock/types-dock-partner';

import { ChipColors } from '../../../../common';

export const accountStatusColorMap: Record<AccountsAccountStatus, ChipColors> = {
    ACTIVATED: ChipColors.success,
    LOCKED: ChipColors.error,
    TERMINATED: ChipColors.info,
    UNACTIVATED: ChipColors.default,
};
