import { Column, ColumnAlign } from '@dock/react';

import lang from '../../../../lang/bankTransferDetails';

export const tableColumns: Column[] = [
    {
        key: 'transactionId',
        name: lang.TRANSACTIONS_TABLE.TRANSACTION_ID,
    },
    {
        key: 'type',
        name: lang.TRANSACTIONS_TABLE.TYPE,
    },
    {
        align: ColumnAlign.RIGHT,
        key: 'availableAmount',
        name: lang.TRANSACTIONS_TABLE.AVAILABLE_AMOUNT,
    },
    { key: 'currency', name: lang.TRANSACTIONS_TABLE.CURRENCY },
    {
        key: 'createdTime',
        name: lang.TRANSACTIONS_TABLE.CREATED_DATE,
        sortable: true,
    },
    { key: 'paymentDetails', name: lang.TRANSACTIONS_TABLE.PAYMENT_DETAILS },

    { key: 'action', name: '' },
];
