import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import type { z } from 'zod';

import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import { gridInput } from '@dock/react-mui';
import {
    createBankTransferBasicDetailsStepSchema,
    createBankTransferBasicDetailsStepSchemaKeys,
} from '@dock/validation';

import type { CreateBankTransferFormType } from '../CreateBankTransferModal';

import bankTransfersLang from '../../../../../lang/bankTransfers';
import commonLang from '../../../../../lang/common';

export type BasicDetailsStepFormValues = z.infer<typeof createBankTransferBasicDetailsStepSchema>;

type CreateBankTransferBasicDetailsStepProps = {
    handleForm: (values: Partial<CreateBankTransferFormType>) => void;
    handleNextStep: () => void;
    defaultValues: BasicDetailsStepFormValues;
    handleCloseModal: () => void;
};

export function BasicDetailsStep({
    defaultValues,
    handleCloseModal,
    handleForm,
    handleNextStep,
}: CreateBankTransferBasicDetailsStepProps) {
    const methods = useForm({
        defaultValues,
        resolver: zodResolver(createBankTransferBasicDetailsStepSchema),
    });
    const { control, handleSubmit, watch } = methods;

    const onSubmitHandler = (values: BasicDetailsStepFormValues) => {
        handleForm(values);
        handleNextStep();
    };

    const isSubmitButtonDisabled = Object.values(watch()).some((value) => !value);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container data-testid="basicDetailsStep">
                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.ACCOUNT_ID}
                            name={createBankTransferBasicDetailsStepSchemaKeys.accountId}
                            isFullWidth
                            testId="accountIdInput"
                        />
                    </Grid>
                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={bankTransfersLang.CREATE_NEW.ACCOUNT_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>
                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={bankTransfersLang.DEFINITION_ID}
                            name={createBankTransferBasicDetailsStepSchemaKeys.definitionId}
                            isFullWidth
                            testId="definitionIdInput"
                        />
                    </Grid>
                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={bankTransfersLang.CREATE_NEW.DEFINITION_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>
                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={bankTransfersLang.HOLDER_ID}
                            name={createBankTransferBasicDetailsStepSchemaKeys.holderId}
                            isFullWidth
                            testId="holderIdInput"
                        />
                    </Grid>
                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={bankTransfersLang.CREATE_NEW.HOLDER_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>
                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.TENANT_ID}
                            name={createBankTransferBasicDetailsStepSchemaKeys.tenantId}
                            isFullWidth
                            testId="tenantIdInput"
                        />
                    </Grid>
                </Grid>

                <FormActionButtons
                    onCancelHandler={handleCloseModal}
                    isSubmitDisabled={isSubmitButtonDisabled}
                    submitButtonText={commonLang.NEXT}
                />
            </form>
        </FormProvider>
    );
}
