import { useMutation } from '@tanstack/react-query';

import {
    A2ATransfersUpdateInstrumentStatus,
    UpdateInstrumentStatusData,
} from '@dock/types-dock-partner';

import { PartnerServiceError } from '../../../common/PartnerServiceError';
import { queryClient } from '../../client';
import { A2A_LIST_KEY } from '../../keys';
import { changeA2AStatus } from '../methods/a2a/changeA2AStatus';
import { updateTokensOnMutate } from '../updateTokensOnMutate';

export function useChangeA2AStatus(id: string) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        UpdateInstrumentStatusData,
        PartnerServiceError,
        A2ATransfersUpdateInstrumentStatus
    >((payload) => changeA2AStatus({ id, payload }), {
        onMutate: updateTokensOnMutate,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [A2A_LIST_KEY] });
        },
    });

    return {
        data,
        error,
        isError,
        isLoading,
        mutateAsync,
    };
}
