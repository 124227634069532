import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import lang from '../../lang/common';
import {
    A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
    A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    A2A_HOLDERS_ROUTE,
    A2A_TRANSACTIONS_ROUTE,
    ACCOUNT_DETAILS_PAYMENT_INST_ROUTE,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    ACCOUNTS_ROUTE,
    BANK_TRANSFERS_HOLDERS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_ROUTE,
    BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
    CARD_AUTHORIZATION_DETAILS_ROUTE,
    CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
    CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
    CARD_DETAILS_AUTHORIZATION_ROUTE,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    CARD_OVERVIEW_ROUTE,
    CARD_SPENDING_CONTROLS_ROUTE,
    CARD_SPENDING_CONTROL_DETAILS_ROUTE,
    CARD_TRANSACTION_DETAILS_ROUTE,
    CARD_TRANSACTIONS_OVERVIEW_ROUTE,
    CARD_LIST_ROUTE,
    CARD_HOLDERS_ROUTE,
    CARD_SPENDING_CONTROL_CREATE_ROUTE,
    CARD_SPENDING_CONTROL_EDIT_ROUTE,
} from '../constants';

export const useMatchedPath = () => {
    const { pathname } = useLocation();
    const { authorizationId = '', id = '', transactionId = '' } = useParams();

    const breadcrumbsInfo = [
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.ACCOUNTS],
            pathname: ACCOUNTS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.ACCOUNTS, lang.BREADCRUMBS.ACCOUNT_DETAILS],
            explicitLinksForBreadcrumbs: ['accounts', 'accounts details'],
            pathname: ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.ACCOUNTS, lang.BREADCRUMBS.ACCOUNT_DETAILS],
            explicitLinksForBreadcrumbs: ['accounts', 'accounts details'],
            pathname: ACCOUNT_DETAILS_PAYMENT_INST_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARDS],
            explicitLinksForBreadcrumbs: [CARD_LIST_ROUTE],
            pathname: CARD_LIST_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARDS],
            explicitLinksForBreadcrumbs: [CARD_HOLDERS_ROUTE],
            pathname: CARD_HOLDERS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARDS_SPENDING_CONTROL],
            explicitLinksForBreadcrumbs: [CARD_SPENDING_CONTROLS_ROUTE],
            pathname: CARD_SPENDING_CONTROLS_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.CARDS_SPENDING_CONTROL,
                lang.BREADCRUMBS.CARDS_SPENDING_CONTROL_DETAILS,
            ],
            explicitLinksForBreadcrumbs: ['cards/spending-controls', ''],
            pathname: CARD_SPENDING_CONTROL_DETAILS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARDS, lang.BREADCRUMBS.CARD_DETAILS],
            explicitLinksForBreadcrumbs: ['cards/list', `card details`],
            pathname: CARD_DETAILS_TRANSACTIONS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARDS, lang.BREADCRUMBS.CARD_DETAILS],
            explicitLinksForBreadcrumbs: ['cards/list', `card details`],
            pathname: CARD_DETAILS_AUTHORIZATION_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.CARDS,
                lang.BREADCRUMBS.CARD_DETAILS,
                lang.BREADCRUMBS.TRANSACTION_DETAILS,
            ],
            explicitLinksForBreadcrumbs: [
                'cards/list',
                `${id}/transactions`,
                `transactions/${transactionId}`,
            ],
            pathname: CARD_TRANSACTION_DETAILS_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.CARDS,
                lang.BREADCRUMBS.CARD_DETAILS,
                lang.BREADCRUMBS.AUTHORIZATION_DETAILS,
            ],
            explicitLinksForBreadcrumbs: [
                'cards/list',
                `${id}/authorizations`,
                `authorizations/${authorizationId}`,
            ],
            pathname: CARD_AUTHORIZATION_DETAILS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.A2A_TRANSFER],
            explicitLinksForBreadcrumbs: [A2A_TRANSACTIONS_ROUTE],
            pathname: A2A_TRANSACTIONS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.A2A_TRANSFER],
            explicitLinksForBreadcrumbs: [A2A_HOLDERS_ROUTE],
            pathname: A2A_HOLDERS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.A2A_TRANSFER, lang.BREADCRUMBS.A2A_DETAILS],
            explicitLinksForBreadcrumbs: ['a2a/transactions', id],
            pathname: A2A_DETAILS_TRANSACTIONS_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.A2A_TRANSFER,
                lang.BREADCRUMBS.A2A_DETAILS,
                lang.BREADCRUMBS.TRANSACTION_DETAILS,
            ],
            explicitLinksForBreadcrumbs: [
                'a2a/transactions',
                `${id}/transactions`,
                `transactions/${transactionId}`,
            ],
            pathname: A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.A2A_TRANSFER,
                lang.BREADCRUMBS.A2A_DETAILS,
                lang.BREADCRUMBS.A2A_PAYMENTS_DETAILS_TITLE,
            ],
            explicitLinksForBreadcrumbs: [
                'a2a/transactions',
                `${id}/schedule-payments`,
                `schedule-payments/${transactionId}`,
            ],
            pathname: A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.A2A_TRANSFER, lang.BREADCRUMBS.A2A_DETAILS],
            explicitLinksForBreadcrumbs: ['a2a/transactions', id],
            pathname: A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARD_TXS_OVERVIEW],
            explicitLinksForBreadcrumbs: [CARD_OVERVIEW_ROUTE],
            pathname: CARD_TRANSACTIONS_OVERVIEW_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARD_TXS_OVERVIEW],
            explicitLinksForBreadcrumbs: [CARD_OVERVIEW_ROUTE],
            pathname: CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.CARD_TXS_OVERVIEW],
            explicitLinksForBreadcrumbs: [CARD_OVERVIEW_ROUTE],
            pathname: CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.BANK_TRANSFERS],
            explicitLinksForBreadcrumbs: [BANK_TRANSFERS_INSTRUMENTS_ROUTE],
            pathname: BANK_TRANSFERS_INSTRUMENTS_ROUTE,
        },
        {
            explicitBreadcrumbs: [lang.BREADCRUMBS.BANK_TRANSFERS],
            explicitLinksForBreadcrumbs: [BANK_TRANSFERS_HOLDERS_ROUTE],
            pathname: BANK_TRANSFERS_HOLDERS_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.BANK_TRANSFER,
                lang.BREADCRUMBS.BANK_TRANSFER_DETAILS,
            ],
            explicitLinksForBreadcrumbs: [
                'bank-transfers/instruments',
                BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
            ],
            pathname: BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.BANK_TRANSFER,
                lang.BREADCRUMBS.BANK_TRANSFER_DETAILS,
                lang.BREADCRUMBS.BANK_TRANSFER_PAYMENT_DETAILS,
            ],
            explicitLinksForBreadcrumbs: [
                'bank-transfers/instruments',
                `${id}`,
                `${transactionId}`,
            ],
            pathname: BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.CARDS_SPENDING_CONTROL,
                lang.BREADCRUMBS.CREATE_SPENDING_CONTROL,
            ],
            explicitLinksForBreadcrumbs: [
                'cards/spending-controls',
                CARD_SPENDING_CONTROL_CREATE_ROUTE,
            ],
            pathname: CARD_SPENDING_CONTROL_CREATE_ROUTE,
        },
        {
            explicitBreadcrumbs: [
                lang.BREADCRUMBS.CARDS_SPENDING_CONTROL,
                lang.BREADCRUMBS.EDIT_SPENDING_CONTROL,
            ],
            explicitLinksForBreadcrumbs: [
                'cards/spending-controls',
                'cards/edit-spending-controls',
            ],
            pathname: CARD_SPENDING_CONTROL_EDIT_ROUTE,
        },
    ];

    return useMemo(
        () => breadcrumbsInfo.find((item) => matchPath(item.pathname, pathname)),
        [pathname]
    );
};
