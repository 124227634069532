import { Box } from '@mui/material';

import { prefillDatesFromAlways } from '@dock/common';
import {
    SimpleTable,
    MultipleChoiceSearch,
    FilterButton,
    DateRangeDropDown,
    OptionType,
} from '@dock/react';
import { useModal } from '@dock/react-hooks';

import {
    CardAuthsSearchFields,
    transformCardAuthsOverviewParams,
    useCardAuthsFilters,
    CardAuthorizationsFilter,
    TableFiltersWrapper,
    filterWrapperStyles,
} from '../../../../common';
import cardTxsOverviewLang from '../../../../lang/cardTransactionsOverview';
import commonLang from '../../../../lang/common';
import { useCardAuthorizations } from '../../../../services';
import { authorizationsOverviewColumns } from './authorizationsOverviewColumns';
import { AuthorizationsOverviewRow } from './AuthorizationsOverviewRow';

const searchOptions: OptionType<CardAuthsSearchFields>[] = [
    {
        label: cardTxsOverviewLang.SEARCH_OPTION.AUTHORIZATION_ID,
        value: CardAuthsSearchFields.authorizationId,
    },
    {
        label: cardTxsOverviewLang.SEARCH_OPTION.CARD_ID,
        value: CardAuthsSearchFields.cardId,
    },
];

export function AuthorizationsOverviewTable() {
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSearchSelect,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useCardAuthsFilters(CardAuthsSearchFields.authorizationId);

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useCardAuthorizations({
        params: transformCardAuthsOverviewParams(filters),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || CardAuthsSearchFields.authorizationId}
                    options={searchOptions}
                />

                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />

                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <CardAuthorizationsFilter
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                    sourceMethod: filters.sourceMethod || [],
                    status: filters.status || [],
                }}
            />
            <SimpleTable
                columns={authorizationsOverviewColumns}
                RowComponent={AuthorizationsOverviewRow}
                isLoading={isFetching}
                rows={data}
                error={error}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
