import { GenericServiceError } from '@dock/common';
import {
    A2ATransfersUpdateInstrumentStatus,
    UpdateInstrumentStatusData,
} from '@dock/types-dock-partner';

import { generateIdempotencyKey } from '../../../../common';
import { setA2AStatus } from '../../../client';

type ChangeA2AStatusParams = {
    payload: A2ATransfersUpdateInstrumentStatus;
    id: string;
};

export const changeA2AStatus = async ({
    id,
    payload,
}: ChangeA2AStatusParams): Promise<UpdateInstrumentStatusData> => {
    const headers = {
        'idempotency-key': generateIdempotencyKey(),
    };

    const errorOrResponse = await setA2AStatus(id)({ headers, payload });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
