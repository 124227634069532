import { Typography, TypographyVariant } from '@mui/material';
import { Link } from 'react-router-dom';

import { LinkWithCopyIconContent } from './LinkWithCopyIconContent';

type LinkWithCopyIconProps = {
    text: string;
    testId?: string;
    href: string;
    variant?: TypographyVariant;
    ellipsis?: 'start' | 'end';
};

const CONTENT_TEST_ID = 'linkCopyText';
// TODO this should be in react lib
export function LinkWithCopyIcon({
    ellipsis,
    href,
    testId = CONTENT_TEST_ID,
    text,
    variant = 'body1',
}: LinkWithCopyIconProps) {
    if (ellipsis) {
        return (
            <LinkWithCopyIconContent
                variant={variant}
                href={href}
                ellipsis={ellipsis}
                testId={testId}
                text={text}
            />
        );
    }

    return (
        <Typography variant={variant}>
            <Link
                style={{ color: '#111111', fontWeight: 'regular' }}
                to={href}
                data-testid={testId}
            >
                {text}
            </Link>
        </Typography>
    );
}
