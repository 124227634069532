import { FormControl } from '@mui/material';

import type { CardsCardStatus } from '@dock/types-dock-partner';

import { FilterModalButtons, FilterModalWrapper } from '@dock/react';

import { CheckItems, useCheckItems, SimpleDialogProps, generateKeys } from '../../../../common';
import commonLang from '../../../../lang/common';
import { cardStatusMap } from './cardStatusMap';
import { CardsListPopUpFilterParams } from './useCardsListFilters';

const statusKeys = generateKeys(cardStatusMap);

export function CardListFilters({
    fields,
    handleClose,
    isOpen,
    onApply,
}: SimpleDialogProps<CardsListPopUpFilterParams>) {
    const [status, setStatus, onStatusCheck] = useCheckItems<CardsCardStatus>(fields.status || []);

    const handleApply = () => {
        onApply({
            ...fields,
            status,
        });
        handleClose();
    };

    const handleReset = () => {
        setStatus([]);
        onApply({
            status: [],
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="cardListFilters"
        >
            <FormControl component="fieldset" variant="standard">
                <CheckItems
                    title={commonLang.STATUS}
                    items={status}
                    handleChange={onStatusCheck}
                    keys={statusKeys}
                />
            </FormControl>
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
