import { AccountsPaymentMethod } from '@dock/types-dock-partner';

import {
    FilterReturnTypes,
    useFilters,
    getSortOrderURLParam,
    getUrlParam,
    BaseFilterParams,
    getAllTypedParamValues,
} from '../../../../common';

export type AccountTransactionFiltersURLParams = BaseFilterParams<string> & {
    sourceMethod?: AccountsPaymentMethod[];
};

export enum TransactionsSearchFields {
    transactionId = 'id',
}

export type AccTxsPopUpFilterParams = Partial<
    Pick<AccountTransactionFiltersURLParams, 'sourceMethod' | 'minAmount' | 'maxAmount'>
>;

export const getEmptyFilters = (params: URLSearchParams): AccountTransactionFiltersURLParams => ({
    from: getUrlParam('from', params) || '',
    maxAmount: getUrlParam('maxAmount', params),
    minAmount: getUrlParam('minAmount', params),
    searchBy: TransactionsSearchFields.transactionId,
    sortBy: getUrlParam('sortBy', params) || 'valueDate',
    sortOrder: getSortOrderURLParam(params),
    sourceMethod: getAllTypedParamValues('sourceMethod', params, AccountsPaymentMethod),
    text: getUrlParam('text', params),
    to: getUrlParam('to', params) || '',
});

const customFieldsExtractor = (fields: AccountTransactionFiltersURLParams) => ({
    searchBy: fields.searchBy || TransactionsSearchFields.transactionId,
    sourceMethod: fields.sourceMethod || [],
});

export type AccountTransactionsFilterReturnType = FilterReturnTypes<
    AccountTransactionFiltersURLParams,
    AccTxsPopUpFilterParams,
    TransactionsSearchFields
>;

export const useAccountTransactionsFilters = (): AccountTransactionsFilterReturnType =>
    useFilters<AccountTransactionFiltersURLParams>(getEmptyFilters, customFieldsExtractor);
