import { Box, Typography } from '@mui/material';

import loginLang from '../../../../lang/login';

const typographyBaseStyles = {
    textAlign: 'center',
};

const wrapperStyles = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    justifyContent: 'center',
    padding: '8px 72px 24px 72px',
    width: 'full',
};

export function SessionExpiredModalInfo() {
    return (
        <Box sx={wrapperStyles}>
            <Typography
                variant="h3"
                sx={{
                    ...typographyBaseStyles,
                    color: ({ palette }) => palette.grey[800],
                }}
            >
                {loginLang.SESSION_EXPIRED.MAIN_TITLE}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    ...typographyBaseStyles,
                    color: ({ palette }) => palette.grey[600],
                }}
            >
                {loginLang.SESSION_EXPIRED.SECONDARY_TITLE}
            </Typography>
        </Box>
    );
}
