import { flow } from 'fp-ts/lib/function';

import type { ListPaymentsData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListPaymentsResponse = ResponseWithContinuationToken<ListPaymentsData>;

export const listPaymentsHandler = requestFlow(handleErrors)(`v0/a2a_transfers/payments`);

const fetchAndHandle = listPaymentsHandler<ListPaymentsResponse>(getDataAndHeaders);

export const listTransfers: RequestAndUnwrap<'get', ListPaymentsResponse> = flow(
    fetchAndHandle,
    unwrapService<ListPaymentsResponse>
);
