import { SxProps, Theme, Typography } from '@mui/material';

type TablesTypographyProps = {
    content: string;
    styles?: SxProps<Theme>;
    testId?: string;
};

export function TablesTypography({ content, styles, testId }: TablesTypographyProps) {
    return (
        <Typography sx={styles} data-testid={testId}>
            {content}
        </Typography>
    );
}
