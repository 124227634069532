import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { IconButton } from '@mui/material';

type BorderColorIconButtonProps = {
    setOpen: () => void;
    isDisabled?: boolean;
    ariaLabel?: string;
    testId?: string;
};

export function BorderColorIconButton({
    ariaLabel,
    isDisabled = false,
    setOpen,
    testId = 'borderColorIconButton',
}: BorderColorIconButtonProps) {
    return (
        <IconButton
            onClick={setOpen}
            aria-label={ariaLabel}
            disabled={isDisabled}
            data-testid={testId}
        >
            <BorderColorOutlinedIcon
                sx={{
                    color: ({ palette }) => (isDisabled ? palette.grey[200] : palette.grey[800]),
                }}
            />
        </IconButton>
    );
}
