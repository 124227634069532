import { GetCardsParams } from '@dock/types-dock-partner';

import { dateFilterUrlParamsExtractor } from '../../../../common';
import { CardsListFiltersURLParams, CardsListSearchFields } from './useCardsListFilters';

const propertyMap: Record<string, keyof GetCardsParams> = {
    [CardsListSearchFields.cardId]: 'id',
    [CardsListSearchFields.externalReference]: 'external_reference',
};

const customKeyUrlParamsExtractor = dateFilterUrlParamsExtractor<GetCardsParams>([
    { key: 'card_status', value: 'status' },
]);

export const transformCardsListParams =
    customKeyUrlParamsExtractor<CardsListFiltersURLParams>(propertyMap);
