import { KeysToSnakeCase } from '@dock/mock-server';

import cardsLang from '../../../../lang/cards';
import commonLang from '../../../../lang/common';

export type CreateCardPayloadType = {
    accountId: string;
    cardHolderId: string;
    definitionId: string;
    tenantId: string;
    line2: string;
    line3: string;
    qrCodeContent: string;
    externalReference: string;
};

export const responseFieldsMap: Record<keyof KeysToSnakeCase<CreateCardPayloadType>, string> = {
    account_id: commonLang.ACCOUNT_ID,
    card_holder_id: cardsLang.CARD_HOLDER_ID,
    definition_id: commonLang.PRODUCT_ID,
    external_reference: commonLang.EXTERNAL_REFERENCE,
    line_2: cardsLang.LINE_2,
    line_3: cardsLang.LINE_3,
    qr_code_content: cardsLang.QR_CODE_CONTENT,
    tenant_id: commonLang.TENANT_ID,
};
