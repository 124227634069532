import { useMutation } from '@tanstack/react-query';

import type { DockError } from '@dock/common';
import type {
    CardsChangeCardStatusResponse,
    CardsChangeCardStatusRequest,
} from '@dock/types-dock-partner';

import { queryClient } from '../../client';
import { CARD_DETAILS_KEY } from '../../keys';
import { changeCardStatus } from '../methods/cards';
import { updateTokensOnMutate } from '../updateTokensOnMutate';

export function useChangeCardStatus(cardId: string | undefined) {
    const { data, error, isError, isLoading, mutateAsync } = useMutation<
        CardsChangeCardStatusResponse,
        DockError,
        CardsChangeCardStatusRequest
    >((payload) => changeCardStatus(cardId, payload), {
        onMutate: updateTokensOnMutate,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [CARD_DETAILS_KEY],
            });
        },
    });

    return {
        changeCardStatusMutation: mutateAsync,
        data,
        error,
        isError,
        isLoading,
    };
}
