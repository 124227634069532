import { InfiniteData } from '@tanstack/react-query';

import { getEnumValues } from '@dock/common';
import { ResponseWithContinuationToken } from '@dock/services';
import { SortOrder } from '@dock/types-common';

import {
    formatSortOrder,
    formatDateFrom,
    formatDateTo,
    formatMinAmountValue,
    formatMaxAmountValue,
} from './formatters';
import { BaseFilterParams } from './types';

export const getUrlParam = (paramName: string, searchParams: URLSearchParams): string =>
    searchParams.get(paramName) || '';

export const getAllTypedParamValues = <T extends string>(
    paramName: string,
    searchParams: URLSearchParams,
    enumType: Record<string, T>
): T[] => {
    const isValidQueryParam = (value: string): value is T =>
        getEnumValues(enumType).includes(value as T);

    const validateQueryParamValues = (values: string[]): T[] => values.filter(isValidQueryParam);

    return validateQueryParamValues(searchParams.getAll(paramName));
};

export const getTypedParamValue = <T extends string>(
    paramName: string,
    searchParams: URLSearchParams,
    enumType: Record<string, T>,
    defaultValue: T
): T => {
    const isValidQueryParam = (value: string | null): value is T =>
        getEnumValues(enumType).includes(value as T);

    const validateQueryParamValues = (value: string | null): T =>
        isValidQueryParam(value) ? value : defaultValue;

    return validateQueryParamValues(searchParams.get(paramName));
};

export const getSortOrderURLParam = (searchParams: URLSearchParams): SortOrder => {
    const value = searchParams.get('sortOrder');

    return value && Object.values(SortOrder).includes(value as SortOrder)
        ? (value as SortOrder)
        : SortOrder.DESC;
};

export type ExtractorDataFromInfiniteData<TData> =
    | InfiniteData<ResponseWithContinuationToken<TData[]>>
    | undefined;

export const extractDataFromInfiniteData = <TData>(
    data: ExtractorDataFromInfiniteData<TData>
): TData[] => {
    const extractedData: TData[] = [];

    data?.pages.forEach((page) => {
        extractedData.push(...page.data);
    });

    return extractedData;
};

type DateAndAmountParamsKeys = {
    dateKey?: string;
    amountKey?: string;
};
type StandardParams = {
    [key: string]: string[] | string;
};

/** Curried version for transforming filters into params
 * @params data & amount keys params and
 * @return a function that expect custom keys and
 * @return a function that expect custom search key extractors
 * @return a function that expect a filters object
 * @return a params object.
 */
export const filterUrlParamsExtractor =
    ({ amountKey, dateKey }: DateAndAmountParamsKeys) =>
    /** amountAndDateUrlParamsExtractor
     * @returns a function that expect custom keys & values pairs.
     * example: [key]: [urlParams[value as keyof Filters]
     */
    <SearchKey>(customKeys?: { key: string; value: string }[]) =>
    /** customKeyUrlParamsExtractor */
    <Filters extends BaseFilterParams<string>>(
        searchKeysMapper?: Record<string, keyof SearchKey>
    ) =>
    /** transformFilterToParams */
    (urlParams: Filters): StandardParams => {
        const { searchBy, text } = urlParams;
        const propertyName = searchKeysMapper && searchKeysMapper[searchBy ?? ''];

        return {
            _order_by: formatSortOrder(urlParams.sortOrder),
            ...(propertyName && text && { [propertyName]: [text] }),
            ...(dateKey && {
                [dateKey]: [formatDateFrom(urlParams.from), formatDateTo(urlParams.to)].filter(
                    Boolean
                ),
            }),
            ...(amountKey && {
                [amountKey]: [
                    formatMinAmountValue(urlParams.minAmount),
                    formatMaxAmountValue(urlParams.maxAmount),
                ].filter(Boolean),
            }),
            ...(!!customKeys?.length &&
                customKeys
                    .map(({ key, value }) => ({
                        [key]: [urlParams[value as keyof Filters] || []].flat(),
                    }))
                    .reduce((a, b) => ({ ...a, ...b }))),
        };
    };

export const simpleFilterUrlParamsExtractor = filterUrlParamsExtractor({});

export type BasicKey = { [key: string]: string };

export const basicTransformFilterToParams = simpleFilterUrlParamsExtractor<BasicKey>();

const basicTransformFilterToParamsWith = (key: string) =>
    basicTransformFilterToParams({ [key]: key });

export const basicTransformFilterToParamsWithIdSearch = basicTransformFilterToParamsWith('id');

export const dateAndAmountFilterUrlParamsExtractor = (amountKeyValue = 'amount') =>
    filterUrlParamsExtractor({
        amountKey: amountKeyValue,
        dateKey: 'datetimes/created',
    });

export const dateFilterUrlParamsExtractor = filterUrlParamsExtractor({
    dateKey: 'datetimes/created',
});
