import { AuthorizationsOverviewTable } from './components/AuthorizationsTable/AuthorizationsOverviewTable';
import { TransactionsOverview } from './TransactionsOverview';

export function CardAuthsOverviewPage() {
    return (
        <TransactionsOverview>
            <AuthorizationsOverviewTable />
        </TransactionsOverview>
    );
}
