import { DownloadParams, FetchNextPage, useExportModal } from '@dock/react';
import { AccountsPaymentMethod } from '@dock/types-dock-partner';

import { useAccountsList } from '../../../../services';
import { ExtendedAccountTransaction } from '../../../../services/queries/getTXsWithCounterparty';
import { accountTxsExportMapper } from './formatters';

type UseDownloadProps<TNext> = {
    accountId: string;
    onApplyFilterClick: () => void;
    hasNextPage: boolean | undefined;
    fetchNextPage: FetchNextPage<TNext>;
    isError: boolean;
    isFetching: boolean;
    data: ExtendedAccountTransaction[];
};

const emptyData = {
    accountId: '',
    amount: { amount: '', currency: '' },
    balances: {
        totalAfter: { amount: '', currency: '' },
    },
    counterparty: '',
    datetimes: {
        initiated: '',
        valueDated: '',
    },
    id: '',
    source: {
        instrumentId: '',
        method: '' as AccountsPaymentMethod,
        transactionId: '',
    },
    traceId: '',
};

export function useDownload<TNext>({
    accountId,
    data,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetching,
    onApplyFilterClick,
}: UseDownloadProps<TNext>): DownloadParams {
    const { data: accounts } = useAccountsList({ params: { id: [accountId] } });
    const accountInfo = accounts[0];
    const accountLabel = accountInfo?.label || '';

    return useExportModal({
        data,
        emptyData: [emptyData],
        fetchNextPage,
        fileNamePrefix: `account-transactions_${accountLabel}`,
        hasNextPage,
        isError,
        isFetching,
        mapper: accountTxsExportMapper,
        onApplyFilterClick,
    });
}
