import { TableRow, TableCell, Typography } from '@mui/material';

import { transformDate } from '@dock/react-util';

type CollapsableCardTransactionsRowsProps = {
    mcc: string;
    mid: string;
    authorizationId: string;
    initiatedTime: string;
};

export function CollapsableCardTransactionsRows({
    authorizationId,
    initiatedTime,
    mcc,
    mid,
}: CollapsableCardTransactionsRowsProps) {
    const transformedInitiatedTime = transformDate(new Date(initiatedTime));

    return (
        <TableRow>
            <TableCell sx={{ width: '30%' }}>
                <Typography variant="subtitle1">{authorizationId}</Typography>
            </TableCell>
            <TableCell data-testid="mcc">
                <Typography variant="subtitle1">{mcc}</Typography>
            </TableCell>
            <TableCell data-testid="mid">
                <Typography variant="subtitle1">{mid}</Typography>
            </TableCell>
            <TableCell data-testid="initiatedTime">
                <Typography variant="subtitle1">{transformedInitiatedTime}</Typography>
            </TableCell>
        </TableRow>
    );
}
