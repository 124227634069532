import {
    CardsAuthorizationEventKind,
    CardsAuthorizationEventStatus,
} from '@dock/types-dock-partner';

import {
    getAllTypedParamValues,
    getTypedParamValue,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
    BaseFilterParams,
} from '../../../../common';

export enum CardAuthsEventsSearchFields {
    authorizationId = 'authorizationId',
    cardId = 'cardId',
}

export type CardAuthsEventsFilterURLParams = BaseFilterParams<CardAuthsEventsSearchFields> & {
    status?: CardsAuthorizationEventStatus[];
    kind?: CardsAuthorizationEventKind[];
};

export type CardAuthsEventsPopUpFilterParams = Partial<
    Pick<CardAuthsEventsFilterURLParams, 'status' | 'kind' | 'minAmount' | 'maxAmount'>
>;

export type AuthsEventsFilterReturnType = FilterReturnTypes<
    CardAuthsEventsFilterURLParams,
    CardAuthsEventsPopUpFilterParams,
    CardAuthsEventsSearchFields
>;

const getEmptyFilters = (params: URLSearchParams): CardAuthsEventsFilterURLParams => ({
    ...getBasicEmptyFilters({ params, sortByKey: 'initiatedTime' }),
    kind: getAllTypedParamValues('kind', params, CardsAuthorizationEventKind),
    searchBy: getTypedParamValue(
        'searchBy',
        params,
        CardAuthsEventsSearchFields,
        CardAuthsEventsSearchFields.authorizationId
    ),
    status: getAllTypedParamValues('status', params, CardsAuthorizationEventStatus),
});

const customFieldsExtractor = (fields: CardAuthsEventsFilterURLParams) => ({
    kind: fields.kind || [],
    status: fields.status || [],
});

export const useCardAuthsEventsFilters = (): AuthsEventsFilterReturnType =>
    useFilters<CardAuthsEventsFilterURLParams>(getEmptyFilters, customFieldsExtractor);
