import { Column } from '@dock/react';

import cardsLang from '../../../../lang/cards';

export const cardsTableColumns: Column[] = [
    { key: 'cardId', name: cardsLang.CARDS_TABLE.COLUMNS.CARD_ID },
    { key: 'cardHolderId', name: cardsLang.CARDS_TABLE.COLUMNS.CARD_HOLDER_ID },
    { key: 'cardStatus', name: cardsLang.CARDS_TABLE.COLUMNS.CARD_STATUS },
    {
        key: 'externalReference',
        name: cardsLang.CARDS_TABLE.COLUMNS.EXTERNAL_REFERENCE,
    },
    {
        key: 'createdTime',
        name: cardsLang.CARDS_TABLE.COLUMNS.CREATED_TIME,
        sortable: true,
    },
    {
        key: 'expirationTime',
        name: cardsLang.CARDS_TABLE.COLUMNS.EXPIRATION_TIME,
    },
    { key: 'actions', name: '' },
];
