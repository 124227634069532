export default {
    ACCOUNT_HOLDER_ID: 'Account Holder ID',
    ACCOUNT_ID: 'Account ID',
    ACCOUNT_NAME: 'Account label',
    ACCOUNT_STATUS: 'Account status',
    ANALYTICS: 'Analytics',
    ANALYTICS_NEGATIVE_DATA: 'Negative balance: analytics unavailable',
    ANALYTICS_NOT_DATA: 'Insufficient balance: analytics unavailable',
    AVAILABLE_BALANCE: 'Available balance',
    BALANCE: 'Balance',
    CURRENCY: 'Currency',
    DETAILS_STATUS: 'Details & status',
    EXTERNAL_REFERENCE: 'External reference',
    GET_MONEY_BALANCE_ERROR: (accountId: string) =>
        `Ups... we got an issue fetching balances for ${accountId} account`,
    HOLD_BALANCE: 'Hold balance',
    METHOD: 'Method',
    PAYMENT_INSTRUMENT_METHODS: {
        A2A_TRANSFER: 'A2A transfer',
        BANK_TRANSFER: 'Bank transfer',
        CARD: 'Card',
    },
    PAYMENT_METHOD_TABLE: {
        COLUMNS: {
            CREATION_TIME: 'Creation time',
            EXTERNAL_REFERENCE: 'External reference',
            INSTRUMENT_ID: 'Instrument ID',
        },
    },
    PRODUCT_ID: 'Product ID',
    SEARCH: 'Search for transaction ID',
    SELECT_LABEL: 'Select payment instrument method',
    TOTAL_BALANCE: 'Total balance',
    TRANSACTIONS_TABLE: {
        COLUMNS: {
            AMOUNT: 'Amount',
            BALANCE: 'Balance',
            COUNTERPARTY: 'Counterparty',
            CURRENCY: 'Currency',
            INSTRUMENT_ID: 'Instrument ID',
            INSTRUMENT_TX_ID: 'Instrument tx ID',
            PAYMENT_INSTRUMENT: 'Payment instrument',
            TRANSACTION_ID: 'Transaction ID',
            VALUE_DATE: 'Booking time',
        },
    },
};
