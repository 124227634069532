import { A2ATransfersPayment } from '@dock/types-dock-partner';

import { useA2APaymentSchedule } from './a2a';
import { useAccountWithA2A } from './useAccountWithA2A';

const emptyA2aTransaction = {
    amount: { amount: '-', currency: '-' },
    datetimes: { created: '-' },
    id: '-',
    recipientId: '-',
    senderId: '-',
};

interface PaymentDetails extends A2ATransfersPayment {
    counterParty: {
        accountId: string;
        accountLabel: string;
        instrumentId: string;
    };
    party: {
        accountId: string;
        accountLabel: string;
        instrumentId: string;
    };
}

type UseA2APaymentDetails = {
    data: PaymentDetails;
    isFetching: boolean;
    isError: boolean;
};

export type PaymentDetailTableProps = {
    transactionId: string;
};

export const useA2APaymentDetails = ({
    transactionId,
}: PaymentDetailTableProps): UseA2APaymentDetails => {
    const {
        data: [payment],
        ...a2aTransactionService
    } = useA2APaymentSchedule({
        params: { id: [transactionId] },
    });

    const { data: senderAccount, ...senderService } = useAccountWithA2A({
        a2aId: payment?.senderId,
    });

    const { data: recipientAccount, ...recipientService } = useAccountWithA2A({
        a2aId: payment?.recipientId,
    });

    return {
        data: {
            ...emptyA2aTransaction,
            counterParty: {
                accountId: recipientAccount?.id ?? '-',
                accountLabel: recipientAccount?.label ?? '-',
                instrumentId: payment?.recipientId ?? '-',
            },
            party: {
                accountId: senderAccount?.id ?? '-',
                accountLabel: senderAccount?.label ?? '-',
                instrumentId: payment?.senderId ?? '-',
            },
            ...payment,
        },
        isError: senderService.isError || recipientService.isError || a2aTransactionService.isError,
        isFetching:
            senderService.isFetching ||
            recipientService.isFetching ||
            a2aTransactionService.isFetching,
    };
};
