import type {
    BankTransferInputBankTransferInstrument,
    BankTransferBankTransferInstrument,
} from '@dock/types-dock-partner';

import { GenericServiceError } from '@dock/common';

import { createBankTransferInstrument } from '../../../client';

export const newBankTransferInstrument = async (
    payload: BankTransferInputBankTransferInstrument,
    idempotencyKey: string
): Promise<BankTransferBankTransferInstrument> => {
    const headers = {
        'idempotency-key': idempotencyKey,
    };

    const errorOrResponse = await createBankTransferInstrument({
        headers,
        payload,
    });

    if (errorOrResponse instanceof GenericServiceError) {
        throw errorOrResponse;
    }

    return errorOrResponse;
};
