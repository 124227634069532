import { useParams } from 'react-router-dom';

import { PageContainer } from '@dock/react';

import { CardSpendingControlDetailTable } from './components/CardSpendingControlDetailTable';

export function CardSpendingControlDetails() {
    const { id: spendingControlId = '' } = useParams();
    return (
        <PageContainer testId="cardSpendingControlDetails">
            <CardSpendingControlDetailTable id={spendingControlId} />
        </PageContainer>
    );
}
