import { Chip, Typography } from '@mui/material';

import { ChipColors } from '../../enums';

type StatusChipProps = {
    label: string;
    color: ChipColors;
};

export function StatusChip({ color, label }: StatusChipProps) {
    const isWhiteLabel =
        color !== ChipColors.default && color !== ChipColors.warning && color !== ChipColors.info;

    return (
        <Chip
            component="span"
            label={
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {label}
                </Typography>
            }
            color={color}
            size="medium"
            data-testid={`statusChip${label.replace(/\s+/g, '')}`}
            sx={{
                '.MuiChip-label': {
                    color: isWhiteLabel ? ({ palette }) => palette.background.default : '',
                },
                padding: '0 4px',
            }}
        />
    );
}
