import { flow } from 'fp-ts/lib/function';

import { ListBankTransferInstrumentData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import {
    getDataAndHeaders,
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
} from '../../../../utilities';

export type ListBankTransferInstrumentsResponse =
    ResponseWithContinuationToken<ListBankTransferInstrumentData>;
export const listBankTransferInstrumentsHandler = requestFlow(handleErrors)(`v0/bank_transfers`);

export const fetchAndHandleBanktransferInstruments = <T>() =>
    listBankTransferInstrumentsHandler<T>(getDataAndHeaders);

/**
 * @deprecated
 */
export const listBankTransferInstruments: RequestAndUnwrap<
    'get',
    ListBankTransferInstrumentsResponse
> = flow(
    fetchAndHandleBanktransferInstruments<ListBankTransferInstrumentsResponse>(),
    unwrapService<ListBankTransferInstrumentsResponse>
);
