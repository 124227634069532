import { ListBankTransferInstrumentsResponse } from '@dock/services';
import { ListBankTransferInstrumentParams } from '@dock/types-dock-partner';

import { getBankTransfersInstruments } from '../../client';
import { fetcher } from './fetcher';

export const fetchBankTransfersInstruments = fetcher<
    ListBankTransferInstrumentsResponse,
    ListBankTransferInstrumentParams
>(getBankTransfersInstruments);
