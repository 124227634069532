import { Link } from '@mui/material';
import { useCallback } from 'react';

import { ActionModalWrapper } from '@dock/react';
import { IdempotencyKey } from '@dock/types-common';
import { BankTransferInputBankTransferInstrument } from '@dock/types-dock-partner';

import {
    ExternalReferenceStep,
    generateErrorText,
    generateIdempotencyKey,
    interpolateParams,
    useCreateModal,
    useStatusModals,
    SuccessStatusMessage,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
} from '../../../../common';
import bankTransfersLang from '../../../../lang/bankTransfers';
import commonLang from '../../../../lang/common';
import { useCreateBankTransferInstrument } from '../../../../services';
import { BasicDetailsStep } from './Form/BasicDetailsStep';
import { responseFieldsMap } from './responseFieldsMap';

type CreateBankTransferModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    setOpenFormModal: () => void;
};

export type CreateBankTransferFormType = BankTransferInputBankTransferInstrument & IdempotencyKey;

const steps = [
    {
        isOptional: false,
        label: commonLang.STEPS.BASIC_DETAILS,
    },
    {
        isOptional: true,
        label: commonLang.EXTERNAL_REFERENCE,
    },
];

export function CreateBankTransferModal({
    handleClose,
    isOpen,
    setOpenFormModal,
}: CreateBankTransferModalProps) {
    const defaultValues: CreateBankTransferFormType = {
        accountId: '',
        definitionId: '',
        externalReference: '',
        holderId: '',
        idempotencyKey: generateIdempotencyKey(),
        tenantId: '',
    };
    const { showErrorModal, showSuccessModal } = useStatusModals();
    const { isLoading, mutateAsync } = useCreateBankTransferInstrument();

    const {
        formValues,
        handleChangeData,
        handleCloseModal,
        handleErrorResponse,
        handleForm,
        stepper,
    } = useCreateModal<CreateBankTransferFormType>({
        closeFormModal: handleClose,
        defaultValues,
        openFormModal: setOpenFormModal,
    });

    const { activeStep, handleBack, handleNext } = stepper;

    const { accountId, definitionId, externalReference, holderId, idempotencyKey, tenantId } =
        formValues;

    const handleChangeDataClick = () =>
        handleChangeData({ idempotencyKey: generateIdempotencyKey() });

    const handleFormSubmit = async (extReference: string) => {
        try {
            const response = await mutateAsync({
                idempotencyKey,
                payload: {
                    accountId,
                    definitionId,
                    externalReference: extReference,
                    holderId,
                    tenantId,
                },
            });

            handleCloseModal();
            const href = interpolateParams(BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE, {
                id: response.id,
            });
            showSuccessModal({
                messages: [
                    <SuccessStatusMessage
                        preLabel={bankTransfersLang.CREATE_NEW.SUCCESS_PRE}
                        postLabel={bankTransfersLang.CREATE_NEW.SUCCESS_POST}
                    >
                        <Link href={href}>{response.id}</Link>
                    </SuccessStatusMessage>,
                ],
            });
        } catch (e) {
            handleErrorResponse();

            showErrorModal({
                messages: [
                    bankTransfersLang.CREATE_NEW.FAILED,
                    generateErrorText(e, responseFieldsMap),
                ],
                onChangeClick: handleChangeDataClick,
                onSubmitClick: () => handleFormSubmit(externalReference || ''),
            });
        }
    };

    const handleOnSubmitClick = async (value: string) => {
        handleForm({ externalReference: value });
        await handleFormSubmit(value);
    };

    const renderCorrectStep = useCallback(() => {
        if (activeStep === 0) {
            return (
                <BasicDetailsStep
                    handleNextStep={handleNext}
                    handleForm={handleForm}
                    defaultValues={{
                        accountId,
                        definitionId,
                        holderId,
                        tenantId,
                    }}
                    handleCloseModal={handleCloseModal}
                />
            );
        }

        return (
            <ExternalReferenceStep
                handleBackStep={handleBack}
                defaultValues={{ externalReference }}
                handleFormSubmit={handleOnSubmitClick}
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                submitButtonText={bankTransfersLang.CREATE_NEW.SUBMIT}
            />
        );
    }, [activeStep, isLoading]);

    return (
        <ActionModalWrapper
            activeStep={activeStep}
            steps={steps}
            handleClose={handleCloseModal}
            isOpen={isOpen}
            title={bankTransfersLang.CREATE_NEW.TITLE}
            testId="createBankTransferModal"
        >
            {renderCorrectStep()}
        </ActionModalWrapper>
    );
}
