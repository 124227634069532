import { WhiteBackgroundWrapper, PageContainer, PageActionButtonWrapper } from '@dock/react';

import { NavLinkTabSwitcher } from '../../common';
import { CreateBankTransferInstrument } from './components/CreateBankTransferInstrument/CreateBankTransferInstrument';
import { InstrumentsTable } from './components/InstrumentsTable/InstrumentsTable';
import { getBankTransfersTabConfig } from './getTabConfig';

export function BankTransfer() {
    const tabs = getBankTransfersTabConfig();

    return (
        <PageContainer testId="bankTransferPage">
            <PageActionButtonWrapper>
                <CreateBankTransferInstrument />
            </PageActionButtonWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                <InstrumentsTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
