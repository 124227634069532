import { Box } from '@mui/material';

import {
    WhiteBackgroundWrapper,
    PageContainer,
    PageActionButtonWrapper,
    CommonLinkAsButton,
} from '@dock/react';

import { NavLinkTabSwitcher, CARD_SPENDING_CONTROL_CREATE_ROUTE } from '../../../common';
import commonLang from '../../../lang/common';
import { SpendingControlListTable } from './components/SpendingControlList';
import { tabConfig } from './getTabConfig';

export function CardSpendingControl() {
    return (
        <PageContainer testId="sCPage">
            <PageActionButtonWrapper>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CommonLinkAsButton
                        label={commonLang.CREATE_NEW}
                        testId="createSC"
                        to={CARD_SPENDING_CONTROL_CREATE_ROUTE}
                    />
                </Box>
            </PageActionButtonWrapper>

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabConfig()} />
                <SpendingControlListTable />
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
