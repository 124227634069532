import type { ListPaymentsParams } from '@dock/types-dock-partner';

import { ListPaymentsResponse } from '@dock/services';

import { getA2APayments } from '../../client';
import { fetcher } from './fetcher';

export const fetchA2APaymentSchedule = fetcher<ListPaymentsResponse, ListPaymentsParams>(
    getA2APayments
);
