import TableCell from '@mui/material/TableCell';

import type { BankTransferBankTransferTransaction } from '@dock/types-dock-partner';

import { DASH } from '@dock/common';
import { AmountCell, TextWithCopyIcon } from '@dock/react';
import { transformDate } from '@dock/react-util';

import {
    CustomVirtualisationWrapper,
    LinkWithCopyIcon,
    TablesTypography,
    BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
    interpolateParams,
    ENV_VARS,
} from '../../../../common';

export type BankTransferTransactionsTableRowProps = Pick<
    BankTransferBankTransferTransaction,
    'id' | 'kind' | 'amount' | 'datetimes' | 'paymentDetails' | 'bankTransferId'
>;

export function BankTransferTransactionsTableRow({
    amount,
    bankTransferId,
    datetimes,
    id,
    kind,
    paymentDetails,
}: BankTransferTransactionsTableRowProps) {
    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="transactionsTableRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="transactionId">
                <TextWithCopyIcon text={id}>
                    <LinkWithCopyIcon
                        text={id}
                        ellipsis="start"
                        href={interpolateParams(BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE, {
                            id: bankTransferId ?? '',
                            transactionId: id,
                        })}
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="type">
                <TablesTypography content={kind} />
            </TableCell>
            <AmountCell amount={amount.amount} />
            <TableCell>
                <TablesTypography content={amount.currency} />
            </TableCell>
            <TableCell data-testid="createdDate">
                <TablesTypography
                    content={datetimes.created ? transformDate(new Date(datetimes.created)) : DASH}
                />
            </TableCell>
            <TableCell>
                <TablesTypography content={paymentDetails || ''} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
