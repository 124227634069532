import TableCell from '@mui/material/TableCell';

import { TextWithCopyIcon, TextWithCopyIconContent } from '@dock/react';
import { transformDate } from '@dock/react-util';
import { AccountsPaymentMethod } from '@dock/types-dock-partner';

import {
    TablesTypography,
    CustomVirtualisationWrapper,
    LinkWithCopyIcon,
    getPaymentMethodLink,
    ENV_VARS,
} from '../../../../common';

export type AccountPaymentMethodsListProps = {
    id: string;
    externalReference?: string | null;
    datetimes: {
        created: string;
    };
};

export type AccountPaymentMethodsListPropsExtended = AccountPaymentMethodsListProps & {
    sourceMethod: AccountsPaymentMethod;
};

export function AccountPaymentMethodRow({
    datetimes,
    externalReference,
    id,
    sourceMethod,
}: AccountPaymentMethodsListPropsExtended) {
    const createdTime = transformDate(new Date(datetimes.created));
    const href = getPaymentMethodLink(sourceMethod, id);

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="accountPaymentRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell data-testid="instrumentIdCell">
                <TextWithCopyIcon text={id}>
                    {href ? (
                        <LinkWithCopyIcon href={href} text={id} ellipsis="start" />
                    ) : (
                        <TextWithCopyIconContent text={id} />
                    )}
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="externalReferenceCell">
                <TablesTypography content={externalReference || ''} />
            </TableCell>
            <TableCell data-testid="createdTimeCell">
                <TablesTypography content={createdTime} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
