import { Typography, Grid, Box } from '@mui/material';

import { TextWithCopyIcon, ContentTitle } from '@dock/react';
import { AccountsAccount } from '@dock/types-dock-partner';

import { StatusChip } from '../../../common';
import accountDetailsLang from '../../../lang/accountDetails';
import { accountStatusColorMap } from '../../Accounts/components/AccountsTable/accountStatusColorMap';
import { accountStatusLabelMap } from '../../Accounts/components/AccountsTable/accountStatusLabelMap';

interface AccountDetailsProps {
    data: AccountsAccount;
    isLoading: boolean;
}

export function AccountDetails({ data, isLoading }: AccountDetailsProps) {
    return (
        <Box sx={{ backgroundColor: ({ palette }) => palette.background.paper }}>
            <Grid container spacing={4}>
                <Grid item xs={6} md={4}>
                    <ContentTitle
                        title={accountDetailsLang.ACCOUNT_ID}
                        content={
                            <TextWithCopyIcon text={data.id}>
                                <Typography variant="h3">{data.id}</Typography>
                            </TextWithCopyIcon>
                        }
                        isLoading={isLoading}
                        contentTestId="accountId"
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <ContentTitle
                        title={accountDetailsLang.ACCOUNT_NAME}
                        content={data.label}
                        isLoading={isLoading}
                        contentTestId="accountName"
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <ContentTitle
                        title={accountDetailsLang.EXTERNAL_REFERENCE}
                        content={data.externalReference ?? '-'}
                        isLoading={isLoading}
                        contentTestId="externalReference"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ marginTop: 3 }}>
                <Grid item xs={6} md={4}>
                    <ContentTitle
                        title={accountDetailsLang.ACCOUNT_HOLDER_ID}
                        content={
                            <TextWithCopyIcon text={data.accountHolderId}>
                                <Typography variant="h3">{data.accountHolderId}</Typography>
                            </TextWithCopyIcon>
                        }
                        isLoading={isLoading}
                        contentTestId="accountHolderId"
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <ContentTitle
                        title={accountDetailsLang.ACCOUNT_STATUS}
                        content={
                            <StatusChip
                                color={accountStatusColorMap[data.status]}
                                label={accountStatusLabelMap[data.status] || data.status}
                            />
                        }
                        isLoading={isLoading}
                        contentTestId="accountStatus"
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <ContentTitle
                        title={accountDetailsLang.PRODUCT_ID}
                        content={
                            <TextWithCopyIcon text={data.definitionId}>
                                <Typography variant="h3">{data.definitionId}</Typography>
                            </TextWithCopyIcon>
                        }
                        isLoading={isLoading}
                        contentTestId="productId"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
