import { Box } from '@mui/material';
import { Control } from 'react-hook-form';

import { TypographyWithTooltip, ControlledInput } from '@dock/react';
import { createCardVCConfigurationStepKeys } from '@dock/validation';

import createCardSCLang from '../../../../lang/createCardSC';
import { SpendingConfigurationStepFormValues } from '../types';

type WindowInputsProps = {
    control: Control<SpendingConfigurationStepFormValues, unknown>;
    isDays: boolean;
};

export function WindowInputs({ control, isDays }: WindowInputsProps) {
    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <ControlledInput
                control={control}
                type="amount"
                isFullWidth
                label={isDays ? createCardSCLang.AMOUNT_OF_DAYS : createCardSCLang.AMOUNT_OF_MONTHS}
                name={
                    isDays
                        ? createCardVCConfigurationStepKeys.windowDays
                        : createCardVCConfigurationStepKeys.windowMonths
                }
                testId={isDays ? 'windowDaysInput' : 'windowMonthsInput'}
                sx={{ width: '45%' }}
                key={
                    isDays
                        ? createCardVCConfigurationStepKeys.windowDays
                        : createCardVCConfigurationStepKeys.windowMonths
                }
            />
            <Box sx={{ marginTop: '18px' }}>
                <TypographyWithTooltip
                    text={
                        isDays
                            ? createCardSCLang.WINDOW_DAYS_TOOLTIP
                            : createCardSCLang.WINDOW_MONTHS_TOOLTIP
                    }
                    variant="body2"
                    isWithIcon
                />
            </Box>
        </Box>
    );
}
