import { flow } from 'fp-ts/lib/function';

import { ListCardAuthorizationEventsData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import {
    requestFlow,
    RequestAndUnwrap,
    unwrapService,
    ResponseWithContinuationToken,
    getDataAndHeaders,
} from '../../../../utilities';

export const fetchCardsAuthorizationsEvents = requestFlow(handleErrors)(
    `v0/cards/authorizations/events`
);

export type CardAuthorizationEventsWithToken =
    ResponseWithContinuationToken<ListCardAuthorizationEventsData>;

const fetchAndMapWithHeaders =
    fetchCardsAuthorizationsEvents<CardAuthorizationEventsWithToken>(getDataAndHeaders);

export const getCardsAuthorizationsEvents: RequestAndUnwrap<
    'get',
    CardAuthorizationEventsWithToken
> = flow(fetchAndMapWithHeaders, unwrapService<CardAuthorizationEventsWithToken>);
