import { SignJWT, importPKCS8 } from 'jose';

import type { JWTPayload } from 'jose';

export { JWTPayload };

export const signJwt = async (pkcs8key: string, claims: JWTPayload): Promise<string | Error> => {
    const alg = 'ES512';
    try {
        const privateKey = await importPKCS8(pkcs8key, alg);
        return await new SignJWT({ ...claims }).setProtectedHeader({ alg }).sign(privateKey);
    } catch (error) {
        return error as Error;
    }
};
