export const transactionDetailColumns = {
    amount: 'Amount',
    datetimes: 'Time created',
    description: 'Description',
    externalReference: 'External reference',
    id: 'Transaction ID',
    kind: 'Kind',
    paymentId: 'Payment ID',
};

export const transactionCounterPartyColumns = {
    accountCounterPartyName: 'Account label',
    accountId: 'Account ID',
    accountPartyName: 'Account label',
    instrumentId: 'Instrument ID',
};
