import { TIMEOUT, serializeParams } from '@dock/common';
import { interceptorResponse } from '@dock/http-request';
import { Accounts } from '@dock/types-dock-partner';

import { ENV_VARS } from '../../../common';
import { dockPartnerMainInterceptorError } from '../../dockPartnerMainInterceptorError';
import { dockPartnerMainInterceptorRequest } from '../../dockPartnerMainInterceptorRequest';

export const AccountsAPI = new Accounts({
    baseURL: ENV_VARS.API_URL,
    paramsSerializer: {
        serialize: serializeParams,
    },
    timeout: TIMEOUT,
});

AccountsAPI.instance.interceptors.response.use(
    interceptorResponse,
    dockPartnerMainInterceptorError(AccountsAPI.instance)
);
AccountsAPI.instance.interceptors.request.use(dockPartnerMainInterceptorRequest);
