import { Box } from '@mui/material';

import { prefillDatesFromAlways } from '@dock/common';
import {
    SimpleTable,
    MultipleChoiceSearch,
    DateRangeDropDown,
    OptionType,
    FilterButton,
} from '@dock/react';
import { useModal } from '@dock/react-hooks';

import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import cardTxsOverviewLang from '../../../../lang/cardTransactionsOverview';
import commonLang from '../../../../lang/common';
import { useCardAuthorizationEvents } from '../../../../services';
import { authorizationsEventsOverviewColumns } from './authorizationsEventsOverviewColumns';
import { AuthorizationsEventsOverviewRow } from './AuthorizationsEventsOverviewRow';
import { CardAuthorizationsEventsFilter } from './CardAuthorizationsEventsFilter';
import { transformCardAuthsEventsParams } from './transformCardAuthEventParams';
import {
    useCardAuthsEventsFilters,
    CardAuthsEventsSearchFields,
} from './useCardAuthsEventsFilters';

const searchOptions: OptionType<CardAuthsEventsSearchFields>[] = [
    {
        label: cardTxsOverviewLang.SEARCH_OPTION.AUTHORIZATION_ID,
        value: CardAuthsEventsSearchFields.authorizationId,
    },
    {
        label: cardTxsOverviewLang.SEARCH_OPTION.CARD_ID,
        value: CardAuthsEventsSearchFields.cardId,
    },
];

export function AuthorizationsEventsOverviewTable() {
    const { isOpen, setClose, setOpen } = useModal();
    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSearchSelect,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useCardAuthsEventsFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } =
        useCardAuthorizationEvents({
            params: transformCardAuthsEventsParams(filters),
        });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || CardAuthsEventsSearchFields.authorizationId}
                    options={searchOptions}
                />
                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />

                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <CardAuthorizationsEventsFilter
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    kind: filters.kind || [],
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                    status: filters.status || [],
                }}
            />
            <SimpleTable
                columns={authorizationsEventsOverviewColumns}
                RowComponent={AuthorizationsEventsOverviewRow}
                isLoading={isFetching}
                rows={data}
                error={error}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
