import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton } from '@mui/material';

type ExpandButtonProps = {
    isOpen: boolean;
    toggleDrawer: () => void;
};

export function ExpandButton({ isOpen, toggleDrawer }: ExpandButtonProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute',
                right: '-20px',
                top: '80%',
                zIndex: 1,
            }}
        >
            <IconButton
                data-testid="toggleSidebar"
                onClick={toggleDrawer}
                sx={{
                    '&:hover': {
                        backgroundColor: 'primary.light',
                    },
                    backgroundColor: 'background.default',
                    border: ({ palette }) => `1px solid ${palette.primary.main}`,
                    color: 'primary.main',
                }}
            >
                {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </Box>
    );
}
