import { CardTransactionListWithToken } from '@dock/services';
import { ListCardTransactionsParams } from '@dock/types-dock-partner';

import { getCardsTransactions } from '../../client';
import { fetcher } from './fetcher';

export const fetchCardTransactions = fetcher<
    CardTransactionListWithToken,
    ListCardTransactionsParams
>(getCardsTransactions);
