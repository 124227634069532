import { TransactionsOverviewTable } from './components/TransactionsTable/TransactionsOverviewTable';
import { TransactionsOverview } from './TransactionsOverview';

export function CardTxsOverviewPage() {
    return (
        <TransactionsOverview>
            <TransactionsOverviewTable />
        </TransactionsOverview>
    );
}
