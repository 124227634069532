import { zodResolver } from '@hookform/resolvers/zod';
import { Typography, Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
    Input,
    ControlledSingleSelect,
    ControlledInput,
    ControlledDateTimePicker,
    FormActionButtons,
} from '@dock/react';
import { useModal } from '@dock/react-hooks';
import { DATE_TIME_FORMAT } from '@dock/react-util';
import { Currency } from '@dock/types-common';
import { CardsVelocityControlWindowKind } from '@dock/types-dock-partner';
import {
    createCardVCConfigurationStepRefinedSchema,
    createCardVCConfigurationStepKeys,
} from '@dock/validation';

import a2aDetailsLang from '../../../../lang/a2aDetails';
import commonLang from '../../../../lang/common';
import createCardSCLang from '../../../../lang/createCardSC';
import { createSpendingControlBL } from '../BL';
import { CreateCardVCFormType, SpendingConfigurationStepFormValues } from '../types';
import { windowKindOptions } from '../windowKindOptions';
import { FormWrapper } from './FormWrapper';
import { WindowInputs } from './WindowInputs';
import { ZeroAmountConfirmation } from './ZeroAmountConfirmation';

type SpendingConfigurationStepProps = {
    defaultValues: SpendingConfigurationStepFormValues;
    handleForm: (values: Partial<CreateCardVCFormType>) => void;
    handleBack: () => void;
    handleNextStep: () => void;
};

type SubmitHandlerParams = {
    values: SpendingConfigurationStepFormValues;
    skipAmountCheck?: boolean;
};

const ZERO_AMOUNT = '0';

export function SpendingConfigurationStep({
    defaultValues,
    handleBack,
    handleForm,
    handleNextStep,
}: SpendingConfigurationStepProps) {
    const { isOpen, setClose, setOpen } = useModal();
    const methods = useForm<SpendingConfigurationStepFormValues>({
        defaultValues,
        resolver: zodResolver(createCardVCConfigurationStepRefinedSchema),
    });
    const { control, handleSubmit, watch } = methods;

    const onSubmitHandler = ({ skipAmountCheck, values }: SubmitHandlerParams) => {
        if (!skipAmountCheck && values.amount === ZERO_AMOUNT) {
            setOpen();
            return;
        }

        handleForm({
            datetimes: {
                effectiveFrom: values.effectiveFrom
                    ? new Date(values.effectiveFrom).toISOString()
                    : '',
                effectiveUntil: values.effectiveUntil
                    ? new Date(values.effectiveUntil).toISOString()
                    : '',
            },
            velocity: {
                spendingLimit: {
                    amount: values.amount,
                    currency: values.currency,
                },
            },
            window: {
                days: values.windowDays ? Number(values.windowDays) : null,
                kind: values.windowKind,
                months: values.windowMonths ? Number(values.windowMonths) : null,
            },
        });

        handleNextStep();
    };

    const currentWindowKind = watch().windowKind;
    const currentCurrency = watch().currency;
    const isDays = currentWindowKind === CardsVelocityControlWindowKind.DAYS;
    const isMonths = currentWindowKind === CardsVelocityControlWindowKind.MONTHS;

    const handleFormOnSubmit = handleSubmit((data: SpendingConfigurationStepFormValues) =>
        onSubmitHandler({
            values: createSpendingControlBL.transformFormValues(data),
        })
    );

    const handleZeroAmountConfirmationSubmit = handleSubmit(
        (data: SpendingConfigurationStepFormValues) =>
            onSubmitHandler({
                skipAmountCheck: true,
                values: createSpendingControlBL.transformFormValues(data),
            })
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleFormOnSubmit}>
                <FormWrapper>
                    <Typography variant="h4">{createCardSCLang.SPENDING_CONFIGURATION}</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ width: '75%' }}>
                            <ControlledInput
                                control={control}
                                label={a2aDetailsLang.PAY.AMOUNT}
                                name={createCardVCConfigurationStepKeys.amount}
                                isFullWidth
                                type="amount"
                                testId="amountInput"
                                autoFocus
                            />
                        </Box>
                        <Box sx={{ width: '25%' }}>
                            <Input
                                label={commonLang.CURRENCY}
                                name={createCardVCConfigurationStepKeys.currency}
                                value={Currency.EUR}
                                onChange={() => {}}
                                disabled
                            />
                        </Box>
                    </Box>
                    <ControlledSingleSelect
                        label={createCardSCLang.WINDOW}
                        options={windowKindOptions}
                        control={control}
                        key={createCardVCConfigurationStepKeys.windowKind}
                        name={createCardVCConfigurationStepKeys.windowKind}
                        helperText={createCardSCLang.FREQUENCY_HELP_TEXT}
                        testId="windowKindSelect"
                        defaultOption={defaultValues.windowKind}
                    />
                    {(isDays || isMonths) && <WindowInputs isDays={isDays} control={control} />}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography variant="h4">{createCardSCLang.SC_EFFECTIVE_PERIOD}</Typography>
                        <Typography variant="body2">
                            {createCardSCLang.SC_EFFECTIVE_PERIOD_NOTE}
                        </Typography>
                    </Box>
                    <ControlledDateTimePicker
                        control={control}
                        label={createCardSCLang.STARTING_TIME}
                        key={createCardVCConfigurationStepKeys.effectiveFrom}
                        name={createCardVCConfigurationStepKeys.effectiveFrom}
                        inputFormat={DATE_TIME_FORMAT}
                        testId="startsAtInput"
                    />
                    <ControlledDateTimePicker
                        control={control}
                        label={createCardSCLang.ENDING_TIME}
                        key={createCardVCConfigurationStepKeys.effectiveUntil}
                        name={createCardVCConfigurationStepKeys.effectiveUntil}
                        inputFormat={DATE_TIME_FORMAT}
                        testId="endsAtInput"
                    />
                    <FormActionButtons
                        onBackHandler={handleBack}
                        submitButtonText={commonLang.NEXT}
                    />
                </FormWrapper>
                <ZeroAmountConfirmation
                    isOpen={isOpen}
                    onClose={setClose}
                    currency={currentCurrency}
                    onPrimaryClick={handleZeroAmountConfirmationSubmit}
                />
            </form>
        </FormProvider>
    );
}
