import { Box } from '@mui/material';

import { SidebarItemIcon } from '../SidebarItemIcon';
import { SidebarItemText } from '../SidebarItemText';
import { NavbarRoutes } from '../types';

type MenuTitleProps = {
    item: NavbarRoutes;
};

export function MenuTitle({ item }: MenuTitleProps) {
    return (
        <Box sx={{ display: 'flex', mb: '16px' }}>
            {item.IconElement && (
                <SidebarItemIcon Icon={<item.IconElement />} iconStyles={{ marginRight: '20px' }} />
            )}
            <SidebarItemText label={item.name} />
        </Box>
    );
}
