import { transformDate } from '@dock/react-util';

import { getPaymentMethodLink, getPaymentMethodTransactionLink } from '../../../../common';
import { ExtendedAccountTransaction } from '../../../../services/queries/getTXsWithCounterparty';
import { paymentMethodMap } from '../paymentMethodMap';
import { AccountTransactionCsv, AccountTransactionTableRow } from './types';

const formatTx = (tx: ExtendedAccountTransaction): AccountTransactionCsv => ({
    amount: tx.amount.amount,
    balance: tx.balances.totalAfter.amount,
    bookingTime: tx.datetimes.valueDated ? transformDate(new Date(tx.datetimes.valueDated)) : '',
    counterparty: tx.counterparty,
    currency: tx.amount.currency,
    id: tx.id,
    instrumentId: tx.source.instrumentId,
    instrumentTxId: tx.source.transactionId,
    paymentInstrument: paymentMethodMap[tx.source.method] || tx.source.method,
});

export const accountTxsExportMapper = (data: ExtendedAccountTransaction[]) => data.map(formatTx);

// This is the mapper for table row only. Because it should have some additional data as links etc
export const accountTxsRowMapper = (
    txs: ExtendedAccountTransaction[]
): AccountTransactionTableRow[] =>
    txs.map((tx) => {
        const transactionLink = getPaymentMethodLink(tx.source.method, tx.source.instrumentId);

        const transactionDetailLink = getPaymentMethodTransactionLink({
            ...tx.source,
        });
        return {
            ...formatTx(tx),
            transactionDetailLink,
            transactionLink,
        };
    });
