import { A2ATransfersPaymentEventKindFilter } from '@dock/types-dock-partner';

import {
    getAllTypedParamValues,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
    BaseFilterParams,
} from '../../../../common';

export type A2APaymentFiltersURLParams = BaseFilterParams<string> & {
    kind?: A2ATransfersPaymentEventKindFilter[];
};

export type SchedulePaymentPopUpFilterParams = Partial<Pick<A2APaymentFiltersURLParams, 'kind'>>;
export enum A2ATransferSearchFields {
    id = 'id',
}
type UseA2ATransferTxsFilter = FilterReturnTypes<
    A2APaymentFiltersURLParams,
    SchedulePaymentPopUpFilterParams,
    A2ATransferSearchFields
>;

export const getEmptyFilters = (params: URLSearchParams): A2APaymentFiltersURLParams => ({
    ...getBasicEmptyFilters({ params }),
    kind: getAllTypedParamValues('kind', params, A2ATransfersPaymentEventKindFilter),
    searchBy: A2ATransferSearchFields.id,
});

const customFieldsExtractor = (fields: A2APaymentFiltersURLParams) => ({
    kind: fields.kind || [],
});

export const useA2APaymentEventFilters = (): UseA2ATransferTxsFilter =>
    useFilters<A2APaymentFiltersURLParams>(getEmptyFilters, customFieldsExtractor);
