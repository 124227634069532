import React from 'react';

import { Modal } from '@dock/react';

import commonLang from '../../../lang/common';
import { SuccessStatusContent } from './SuccessStatusContent';

type SuccessStatusModalProps = {
    handleClose: () => void;
    isOpen: boolean;
    statusMessages: string[] | React.ReactElement[];
};

export function SuccessStatusModal({
    handleClose,
    isOpen,
    statusMessages,
}: SuccessStatusModalProps) {
    return (
        <Modal
            handleClose={handleClose}
            isOpen={isOpen}
            modalAriaTitle={commonLang.STATUS_MODALS.ARIA_TITLE}
            testId="statusModalSuccess"
            modalStyles={{ minWidth: '450px' }}
        >
            <SuccessStatusContent statusMessages={statusMessages} handleClose={handleClose} />
        </Modal>
    );
}
