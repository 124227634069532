import TableCell from '@mui/material/TableCell';

import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    TextWithCopyIcon,
    ViewDetailsLink,
    NavLink,
} from '@dock/react';
import { transformDate } from '@dock/react-util';
import { CardsAuthorization } from '@dock/types-dock-partner';

import {
    cardAuthStatusColorMap,
    cardAuthStatusesMap,
    transactionMethodsMap,
    CARD_AUTHORIZATION_DETAILS_ROUTE,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
    ENV_VARS,
    LinkWithCopyIcon,
    TablesTypography,
    CustomVirtualisationWrapper,
    StatusChip,
} from '../../../../common';

export type CardAuthorizationsRowProps = Pick<
    CardsAuthorization,
    'id' | 'cardId' | 'status' | 'amounts' | 'datetimes' | 'merchant' | 'source'
>;

export function AuthorizationsOverviewRow({
    amounts,
    cardId,
    datetimes,
    id,
    merchant,
    source,
    status,
}: CardAuthorizationsRowProps) {
    const initiatedTime = transformDate(new Date(datetimes.initiated));
    const method = transactionMethodsMap[source.method] || source.method;

    const cardLink = interpolateParams(CARD_DETAILS_TRANSACTIONS_ROUTE, {
        id: cardId,
    });

    const cardAuthorizationLink = interpolateParams(CARD_AUTHORIZATION_DETAILS_ROUTE, {
        authorizationId: id,
        id: cardId,
    });

    const AuthorizationTableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={cardAuthorizationLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    return (
        <CustomVirtualisationWrapper
            index={id}
            testId="authorizationRow"
            isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
        >
            <TableCell>
                <TextWithCopyIcon text={id}>
                    <LinkWithCopyIcon
                        href={cardAuthorizationLink}
                        ellipsis="start"
                        text={id}
                        testId="authIdCell"
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell>
                <TextWithCopyIcon text={cardId}>
                    <LinkWithCopyIcon
                        href={cardLink}
                        ellipsis="start"
                        text={cardId}
                        testId="cardIdCell"
                    />
                </TextWithCopyIcon>
            </TableCell>
            <TableCell data-testid="statusCell">
                <StatusChip
                    color={cardAuthStatusColorMap[status]}
                    label={cardAuthStatusesMap[status] || status}
                />
            </TableCell>
            <AmountCell amount={amounts.approved.amount} />
            <TableCell data-testid="currencyCell">
                <TablesTypography content={amounts.approved.currency} />
            </TableCell>
            <TableCell data-testid="methodCell">
                <TablesTypography content={method} />
            </TableCell>
            <TableCell data-testid="merchantNameCell">
                <TablesTypography content={merchant?.name || ''} />
            </TableCell>
            <TableCell data-testid="initiatedTimeCell">
                <TablesTypography content={initiatedTime} />
            </TableCell>
            <TableCell>
                <ActionsMenu menuItems={AuthorizationTableActions} />
            </TableCell>
        </CustomVirtualisationWrapper>
    );
}
