import React from 'react';
import { useParams } from 'react-router-dom';

import { WhiteBackgroundWrapper, PageContainer, PageActionButtonWrapper } from '@dock/react';

import { NavLinkTabSwitcher } from '../../common';
import { a2aDetailsBL } from './BL/BL';
import { A2ADetailsBox } from './components/A2ADetailsBox';
import { PayA2ATransfer } from './components/PayA2ATransfer/PayA2ATransfer';
import { getA2ADetailsTabConfig } from './getTabConfig';

type A2ADetailsProps = {
    children: React.ReactNode;
};

export function A2ADetails({ children }: A2ADetailsProps) {
    if (!a2aDetailsBL.isReadA2APaymentAvailable()) {
        return null;
    }

    const { id: a2aId = '' } = useParams();

    const tabs = getA2ADetailsTabConfig(a2aId);

    return (
        <PageContainer testId="a2aDetailsPage">
            <PageActionButtonWrapper>
                {a2aDetailsBL.isCreateA2APaymentAvailable() && <PayA2ATransfer />}
            </PageActionButtonWrapper>

            <A2ADetailsBox id={a2aId} />

            <WhiteBackgroundWrapper>
                <NavLinkTabSwitcher tabs={tabs} />
                {children}
            </WhiteBackgroundWrapper>
        </PageContainer>
    );
}
