import { AppBarMapper } from '@dock/react';

import { useAuth } from '../../auth';
import {
    LocalAndUtcDate,
    UserAndEmail,
    LegalItems,
    LogOutItem,
    BreadcrumbsWrapper,
} from '../../common';

export function AppBar() {
    const { logout, user } = useAuth();

    return (
        <AppBarMapper
            breadcrumbs={<BreadcrumbsWrapper />}
            navItems={[
                { component: <LegalItems />, id: '0' },
                {
                    component: <LogOutItem logout={logout} />,
                    id: '1',
                },
            ]}
            dropdownTitle={<UserAndEmail user={user} />}
            dateUTC={<LocalAndUtcDate />}
            user={user.name}
        />
    );
}
