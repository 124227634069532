import { cardTransactionsDetailMapper } from '../mappers';
import { useCardTransactions } from './cards';

export const useCardTransactionsDetails = (cardId: string, id: string) => {
    const { data, error, isFetching } = useCardTransactions({
        params: { card_id: [cardId], id: [id] },
    });

    return {
        data: cardTransactionsDetailMapper(data),
        error,
        isFetching,
    };
};
