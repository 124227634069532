import type { ListCardTransactionsData } from '@dock/types-dock-partner';

import { transformDate } from '@dock/react-util';

import type {
    CardTransactionDetailColumns,
    CardTransactionMerchantColumns,
    CardTransactionOtherColumns,
} from '../../../pages/CardTransactionDetails/components/CardTransactionDetailColumns';

import {
    transactionKindMap,
    transactionMethodsMap,
    transactionStatusColorMap,
    transactionStatusesMap,
    StatusChip,
} from '../../../common';

export type DetailsTransactionsColumns = {
    [k in keyof typeof CardTransactionDetailColumns]: string;
};
export type MerchantColumns = {
    [k in keyof typeof CardTransactionMerchantColumns]: string;
};
export type OtherColumns = {
    [k in keyof typeof CardTransactionOtherColumns]: string;
};

export interface CardTransactionDetailProps {
    details: DetailsTransactionsColumns | Record<string, unknown>;
    merchant: MerchantColumns | Record<string, unknown>;
    other: OtherColumns | Record<string, unknown>;
}

export const emptyCardTransactionDetail = {
    details: {},
    merchant: {},
    other: {},
};
export const cardTransactionsDetailMapper = (
    data: ListCardTransactionsData | undefined
): CardTransactionDetailProps[] =>
    !data
        ? [emptyCardTransactionDetail]
        : data.map(
              ({
                  amounts,
                  authorizationId,
                  cardId,
                  currencyConversion,
                  datetimes,
                  id,
                  kind,
                  merchant,
                  source,
                  status,
              }): CardTransactionDetailProps => ({
                  details: {
                      authorizationId,
                      cardId,
                      currencyConversionRate: currencyConversion.conversionRate,
                      kind: transactionKindMap[kind] || kind,
                      paymentSource: transactionMethodsMap[source.method] || source.method,
                      status: (
                          <StatusChip
                              label={transactionStatusesMap[status] || status}
                              color={transactionStatusColorMap[status]}
                          />
                      ),
                      timeCompleted: datetimes?.completed
                          ? transformDate(new Date(datetimes?.completed))
                          : '',
                      timeInitiate: datetimes?.initiated
                          ? transformDate(new Date(datetimes?.initiated))
                          : '',
                      totalAmount: amounts.total.amount,
                      totalAmountCurrency: amounts.total.currency,
                      transactionId: id,
                  },
                  merchant: {
                      address: '-',
                      amount: '-',
                      city: '-',
                      country: '-',
                      currency: '-',
                      mcc: merchant.mcc,
                      mid: merchant.mid,
                      name: merchant.name,
                      state: '-',
                  },
                  other: {
                      acquireAudit: '-',
                      acquireRef: '-',
                      acquirerId: '-',
                      approval: '-',
                      network: '-',
                  },
              })
          );
