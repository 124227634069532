import { StepType } from '@dock/react';

import commonLang from '../../../lang/common';
import createCardSC from '../../../lang/createCardSC';
import { Mode } from './types';

export const getStepsConfig = (mode: Mode = 'create'): StepType[] => [
    {
        isOptional: false,
        label: commonLang.STEPS.BASIC_DETAILS,
    },
    {
        isOptional: false,
        label: createCardSC.SPENDING_CONFIGURATION,
    },
    {
        isHidden: mode === 'edit',
        isOptional: false,
        label: createCardSC.SC_SUMMARY,
    },
];
