import { Column, ColumnAlign } from '@dock/react';

import a2aDetailsLang from '../../../../lang/a2aDetails';

export const a2aTransactionsTableColumns: Column[] = [
    { key: 'expand', name: '' },
    {
        key: 'transactionId',
        name: a2aDetailsLang.TRANSACTIONS_TABLE.TRANSACTION_ID,
    },
    {
        align: ColumnAlign.RIGHT,
        key: 'availableAmount',
        name: a2aDetailsLang.TRANSACTIONS_TABLE.AVAILABLE_AMOUNT,
    },
    { key: 'currency', name: a2aDetailsLang.TRANSACTIONS_TABLE.CURRENCY },
    {
        key: 'createdTime',
        name: a2aDetailsLang.TRANSACTIONS_TABLE.CREATED_DATE,
        sortable: true,
    },
    {
        key: 'externalReference',
        name: a2aDetailsLang.TRANSACTIONS_TABLE.EXTERNAL_REFERENCE,
    },
    { key: 'action', name: '' },
];

export const collapsableRowColumns: Column[] = [
    {
        key: 'instrumentId',
        name: a2aDetailsLang.TRANSACTIONS_TABLE.INSTRUMENT_ID,
    },
    { key: 'paymentId', name: a2aDetailsLang.TRANSACTIONS_TABLE.PAYMENT_ID },
    { key: 'description', name: a2aDetailsLang.TRANSACTIONS_TABLE.DESCRIPTION },
];
