import { flow } from 'fp-ts/lib/function';

import { UpdateVelocityControlData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import { requestFlow, RequestAndUnwrap, unwrapService, getData } from '../../../../utilities';

const updateVelocityControl = (id: string) =>
    requestFlow(handleErrors)(`v0/cards/velocity_controls/${id}`);

const update = (id: string) => updateVelocityControl(id)<UpdateVelocityControlData>(getData);

export const updateCardVC = (id: string): RequestAndUnwrap<'put', UpdateVelocityControlData> =>
    flow(update(id), unwrapService<UpdateVelocityControlData>);
