import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { prefillDatesFromAlways } from '@dock/common';
import { DateRangeDropDown, FilterButton, SearchBox, SimpleTable } from '@dock/react';
import { useModal } from '@dock/react-hooks';

import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import lang from '../../../../lang/a2aDetails';
import { useA2APaymentEvents } from '../../../../services';
import { a2aPaymentEventsTableColumns } from './a2aPaymentEventsTableColumns';
import { A2APaymentEventsTableRow } from './A2APaymentEventsTableRow';
import { PaymentEventsFilter } from './PaymentEventsFilter';
import { transformA2APaymentEventParams } from './transformA2APaymentEventParams';
import { useA2APaymentEventFilters } from './useA2APaymentEventFilters';

export function TransactionsPaymentEventTable() {
    const { transactionId = '' } = useParams();
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useA2APaymentEventFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useA2APaymentEvents({
        params: transformA2APaymentEventParams(filters, transactionId),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={lang.SEARCH_FOR_EVENT_ID}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />
                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <PaymentEventsFilter
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    kind: filters.kind || [],
                }}
            />
            <SimpleTable
                columns={a2aPaymentEventsTableColumns}
                RowComponent={A2APaymentEventsTableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                onSortClick={handleSortOrder}
                sorting={sorting}
            />
        </>
    );
}
