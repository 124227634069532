import { Typography, Box } from '@mui/material';

import { Modal, FormActionButtons } from '@dock/react';

import commonLang from '../../../../lang/common';
import createCardSC from '../../../../lang/createCardSC';

type ZeroAmountConfirmationProps = {
    onClose: () => void;
    onPrimaryClick: () => void;
    isOpen: boolean;
    currency: string;
};

export function ZeroAmountConfirmation({
    currency,
    isOpen,
    onClose,
    onPrimaryClick,
}: ZeroAmountConfirmationProps) {
    return (
        <Modal
            handleClose={onClose}
            isOpen={isOpen}
            modalAriaTitle={createCardSC.ZERO_AMOUNT_CONFIRMATION.TITLE}
            modalStyles={{ width: '458px' }}
            testId="zeroAmountConfirmationModal"
        >
            <Box sx={{ mb: '30px', textAlign: 'center' }}>
                <Typography sx={{ mb: '18px' }}>
                    {createCardSC.ZERO_AMOUNT_CONFIRMATION.GET_BODY(currency)}
                </Typography>
                <Typography>
                    {createCardSC.ZERO_AMOUNT_CONFIRMATION.GET_QUESTION(currency)}
                </Typography>
            </Box>
            <FormActionButtons
                onBackHandler={onClose}
                submitButtonText={commonLang.PROCEED}
                type="button"
                onClick={onPrimaryClick}
                backButtonText={createCardSC.EDIT_AMOUNT}
                secondaryLightTestId="proceedButton"
            />
        </Modal>
    );
}
