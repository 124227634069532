import { flow } from 'fp-ts/lib/function';

import type { CardsChangeCardStatusResponse } from '@dock/types-dock-partner';

import { handleErrors } from '../../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../../utilities';

const updateWithErrorHandler = requestFlow(handleErrors);

export const updateChangeCardStatus = (cardId: string | undefined) =>
    updateWithErrorHandler(`v0/cards/${cardId}/status`);

const fetchAndMap = (cardId?: string) =>
    updateChangeCardStatus(cardId)<CardsChangeCardStatusResponse>(getData);

export const updateCardStatus = (
    cardId?: string
): RequestAndUnwrap<'post', CardsChangeCardStatusResponse> =>
    flow(fetchAndMap(cardId), unwrapService<CardsChangeCardStatusResponse>);
