export default {
    A2A_TRANSFER_ID: 'A2A transfer ID',
    ACCOUNT_ID: 'Account ID',
    CHANGE_STATUS: {
        TITLE: 'Change status',
    },
    EXTERNAL_REFERENCE: 'External reference',
    PAY: {
        AMOUNT: 'Amount',
        BUTTON: 'Pay',
        CURRENCY: 'Currency',
        FAILED: 'Your submission for payment failed. Please try again.',
        RECIPIENT_ID: 'Recipient ID',
        RECIPIENT_ID_TOOLTIP: 'ID of the A2A transfer instrument to receive the payment amount.',
        SUBMIT_BUTTON: 'Complete payment',
        SUCCESS_POST: ' has been scheduled.',
        SUCCESS_PRE: 'Your payment ',
        TITLE: 'Pay to another A2A instrument',
    },
    SCHEDULE_PAYMENTS_TABLE: {
        AMOUNT: 'Amount',
        CREATED_DATE: 'Created time',
        CURRENCY: 'Currency',
        DESCRIPTION: 'Description',
        EVENT_TYPE: 'Instrument ID',
        EXTERNAL_REFERENCE: 'External reference',
        PAYMENT_EVENT_ID: 'Payment ID',
        PAYMENT_ID: 'Payment ID',
        RECIPIENT_ID: 'Recipient ID',
    },
    SEARCH_FOR_EVENT_ID: 'Search for payment event ID',
    SEARCH_OPTION: {
        EXTERNAL_REFERENCE: 'External reference',
        PAYMENT_ID: 'Payment ID',
        RECIPIENT_ID: 'Recipient ID',
        TRANSACTION_ID: 'Transaction ID',
    },
    STATUS_CHANGE_FAILED: "A2A status wasn't changed. Please, try again.",
    STATUS_CHANGED: 'A2A status was successfully changed.',
    TAB: {
        SCHEDULE: 'Payments',
        TRANSACTIONS: 'Transactions',
    },
    TRANSACTIONS_TABLE: {
        AVAILABLE_AMOUNT: 'Amount',
        CREATED_DATE: 'Created time',
        CURRENCY: 'Currency',
        DESCRIPTION: 'Description',
        EXTERNAL_REFERENCE: 'External reference',
        INSTRUMENT_ID: 'Instrument ID',
        KIND: 'Kind',
        PAYMENT_ID: 'Payment ID',
        TRANSACTION_ID: 'Transaction ID',
    },
    TRANSFER_TRANSACTION_KIND: {
        PULL: 'Pull',
        PUSH: 'Push',
    },
};
