import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { prefillDatesFromAlways } from '@dock/common';
import {
    DateRangeDropDown,
    FilterButton,
    OptionType,
    MultipleChoiceSearch,
    SimpleTable,
} from '@dock/react';
import { useModal } from '@dock/react-hooks';

import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import lang from '../../../../lang/a2aDetails';
import commonLang from '../../../../lang/common';
import { useA2ATransactions } from '../../../../services';
import { A2ATransactionsFilters } from './A2ATransactionsFilter';
import { a2aTransactionsTableColumns } from './a2aTransactionsTableColumns';
import { A2ATransactionsTableRow } from './A2ATransactionsTableRow';
import {
    A2ATransactionsSearchFields,
    transformA2ATransactionsParams,
} from './transformA2ATransactionsParams';
import { useA2ATransferTxsFilters } from './useA2ATransferTxsFilters';

const searchOptions: OptionType<A2ATransactionsSearchFields>[] = [
    {
        label: lang.SEARCH_OPTION.TRANSACTION_ID,
        value: A2ATransactionsSearchFields.transactionId,
    },
    {
        label: lang.SEARCH_OPTION.PAYMENT_ID,
        value: A2ATransactionsSearchFields.paymentId,
    },
    {
        label: lang.SEARCH_OPTION.EXTERNAL_REFERENCE,
        value: A2ATransactionsSearchFields.externalReference,
    },
];

export function TransactionsTable() {
    const { id: instrumentId = '' } = useParams();
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSearchSelect,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useA2ATransferTxsFilters(A2ATransactionsSearchFields.transactionId);

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useA2ATransactions({
        params: transformA2ATransactionsParams({
            ...filters,
            instrumentId,
        }),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || A2ATransactionsSearchFields.transactionId}
                    options={searchOptions}
                />
                <Box
                    sx={{
                        ...filterWrapperStyles,
                    }}
                >
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />

                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>

            <A2ATransactionsFilters
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                }}
            />

            <SimpleTable
                columns={a2aTransactionsTableColumns}
                RowComponent={A2ATransactionsTableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
