import { flow } from 'fp-ts/lib/function';

import { UpdateInstrumentStatusData } from '@dock/types-dock-partner';

import { handleErrors } from '../../../errorService';
import { requestFlow, getData, RequestAndUnwrap, unwrapService } from '../../../utilities';

const updateWithErrorHandler = requestFlow(handleErrors);

export const updateStatus = (instrumentId: string | undefined) =>
    updateWithErrorHandler(`v0/a2a_transfers/${instrumentId}/status`);

const fetchAndMap = (instrumentId?: string) =>
    updateStatus(instrumentId)<UpdateInstrumentStatusData>(getData);

export const setA2AStatus = (
    instrumentId?: string
): RequestAndUnwrap<'post', UpdateInstrumentStatusData> =>
    flow(fetchAndMap(instrumentId), unwrapService<UpdateInstrumentStatusData>);
