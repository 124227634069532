import { FilterModalButtons, FilterModalWrapper } from '@dock/react';

import { SimpleDialogProps, useAmounts, AmountInputRange } from '../../../../common';
import commonLang from '../../../../lang/common';
import { SchedulePaymentPopUpFilterParams } from './useA2ASchedulePaymentFilters';

export function SchedulePaymentFilters({
    fields,
    handleClose,
    isOpen,
    onApply,
}: SimpleDialogProps<SchedulePaymentPopUpFilterParams>) {
    const [{ maxAmount, minAmount }, setAmount, handleAmountChange] = useAmounts({
        maxAmount: fields.maxAmount,
        minAmount: fields.minAmount,
    });

    const handleApply = () => {
        onApply({
            ...fields,
            maxAmount: maxAmount || '',
            minAmount: minAmount || '',
        });
        handleClose();
    };

    const handleReset = () => {
        setAmount({ maxAmount: '', minAmount: '' });
        onApply({
            maxAmount: '',
            minAmount: '',
        });
        handleClose();
    };

    return (
        <FilterModalWrapper
            handleClose={handleClose}
            isOpen={isOpen}
            title={commonLang.FILTER}
            testId="schedulePaymentFilters"
        >
            <AmountInputRange
                label={commonLang.AMOUNT}
                maxAmount={maxAmount}
                minAmount={minAmount}
                handleChange={handleAmountChange}
                helperText={commonLang.FILTERS.AMOUNT_TEXT}
            />
            <FilterModalButtons
                applyText={commonLang.APPLY}
                resetText={commonLang.RESET_FILTERS}
                onApplyClick={handleApply}
                onResetClick={handleReset}
            />
        </FilterModalWrapper>
    );
}
