import { GenericServiceError } from '@dock/common';
import { parseParameters, Getter } from '@dock/services';

import { PagedServiceParams } from '../../../common';

/**
 * @deprecated
 */
export const fetcher =
    <Response, Params>(getter: Getter<Response>) =>
    async ({ pageParam, params }: PagedServiceParams<Params>): Promise<Response> => {
        const parsedParams = parseParameters({
            ...params,
            _continuationToken: pageParam,
        });

        const SuccessOrError = await getter<Params>({
            params: parsedParams,
        });

        if (SuccessOrError instanceof GenericServiceError) {
            throw SuccessOrError;
        }

        return SuccessOrError;
    };
