import { TableCell } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
    ActionMenuItemProps,
    ActionsMenu,
    AmountCell,
    CollapsableTableElement,
    TextWithCopyIcon,
    useBoolean,
    NavLink,
    ExpandRow,
    ViewDetailsLink,
} from '@dock/react';
import { transformDate } from '@dock/react-util';
import { CardsTransaction } from '@dock/types-dock-partner';

import {
    TablesTypography,
    LinkWithCopyIcon,
    CustomVirtualisationWrapper,
    StatusChip,
    transactionMethodsMap,
    transactionKindMap,
    transactionStatusesMap,
    transactionStatusColorMap,
    CARD_TRANSACTION_DETAILS_ROUTE,
    interpolateParams,
    ENV_VARS,
} from '../../../../common';
import { CollapsableCardTransactionsRows } from './CollapsableCardTransactionsRows';
import { collapsableRowColumns } from './collapsableRowColumns';

export type CardTransactionsRowProps = Pick<
    CardsTransaction,
    'id' | 'amounts' | 'status' | 'merchant' | 'source' | 'datetimes' | 'authorizationId' | 'kind'
>;

export function CardTransactionsRow({
    amounts,
    authorizationId,
    datetimes,
    id,
    kind,
    merchant,
    source,
    status,
}: CardTransactionsRowProps) {
    const [isOpen, handleCollapsableElement] = useBoolean(false);

    const { id: cardId = '' } = useParams();

    const transactionDetailsLink = interpolateParams(CARD_TRANSACTION_DETAILS_ROUTE, {
        id: cardId,
        transactionId: id,
    });

    const CardTransactionsTableActions: ActionMenuItemProps[] = [
        {
            content: <NavLink to={transactionDetailsLink} label={<ViewDetailsLink />} />,
            id: '0',
        },
    ];

    const transformedCompletedTime = transformDate(new Date(datetimes.completed));

    const method = transactionMethodsMap[source.method] || source.method;

    const type = transactionKindMap[kind] || source.method;

    return (
        <>
            <CustomVirtualisationWrapper
                index={id}
                testId="cardTransactionsRows"
                isDisableVirtualisation={ENV_VARS.IS_TESTING_MODE}
            >
                <TableCell>
                    <ExpandRow isOpen={isOpen} onClick={handleCollapsableElement} />
                </TableCell>
                <TableCell>
                    <TextWithCopyIcon text={id}>
                        <LinkWithCopyIcon
                            testId="contentCopyText"
                            href={transactionDetailsLink}
                            ellipsis="start"
                            text={id}
                        />
                    </TextWithCopyIcon>
                </TableCell>
                <TableCell>
                    <StatusChip
                        label={transactionStatusesMap[status] || status}
                        color={transactionStatusColorMap[status]}
                    />
                </TableCell>
                <AmountCell amount={amounts.total.amount} />
                <TableCell data-testid="currency">
                    <TablesTypography content={amounts.total.currency} />
                </TableCell>
                <TableCell data-testid="timeCell">
                    <TablesTypography content={transformedCompletedTime} />
                </TableCell>
                <TableCell data-testid={`method${method}`}>
                    <TablesTypography content={method} />
                </TableCell>
                <TableCell data-testid="merchantName">
                    <TablesTypography content={merchant.name || ''} />
                </TableCell>
                <TableCell data-testid={`kind${type}`}>
                    <TablesTypography content={type} />
                </TableCell>
                <TableCell>
                    <ActionsMenu menuItems={CardTransactionsTableActions} />
                </TableCell>
            </CustomVirtualisationWrapper>

            <CollapsableTableElement
                isOpen={isOpen}
                columns={collapsableRowColumns}
                RowComponent={
                    <CollapsableCardTransactionsRows
                        authorizationId={authorizationId || ''}
                        mcc={merchant.mcc || ''}
                        mid={merchant.mid || ''}
                        initiatedTime={datetimes.initiated}
                    />
                }
            />
        </>
    );
}
