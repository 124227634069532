import React from 'react';

import { useAuth } from '../../auth';
import { LoginForm } from './components/LoginForm';

export function Login() {
    const { isLoading, login, validationError } = useAuth();
    const [pass, setPass] = React.useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPass(event.target.value);
    };

    const handleLogin = () => login(pass);

    return (
        <LoginForm
            value={pass}
            isValidationError={validationError}
            isLoading={isLoading}
            onChange={handleChange}
            onButtonClick={handleLogin}
        />
    );
}
