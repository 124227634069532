import { ListTransactionsParams } from '@dock/types-dock-partner';

import { dateAndAmountFilterUrlParamsExtractor, BasicKey } from '../../../../common';
import { BankTransferTransactionFiltersURLParams } from './useBankTransferTransferTxsFilters';

const customKeyUrlParamsExtractor = dateAndAmountFilterUrlParamsExtractor()<BasicKey>([
    { key: 'bank_transfer_id', value: 'bankTransferID' },
    { key: 'kind', value: 'kind' },
]);

type ExtendedBankTransferTransactionFiltersURLParams = BankTransferTransactionFiltersURLParams & {
    bankTransferID: string;
};

const transformFiltersToParams =
    customKeyUrlParamsExtractor<ExtendedBankTransferTransactionFiltersURLParams>({ id: 'id' });

export const transformBankTransferTransactionsParams = (
    urlParams: BankTransferTransactionFiltersURLParams,
    bankTransferID: string
): ListTransactionsParams & {
    _order_by?: string;
} =>
    transformFiltersToParams({
        ...urlParams,
        bankTransferID,
    });
