import React from 'react';
import RenderIfVisible from 'react-render-if-visible';

type CustomVirtualisationProps = {
    index: string;
    children: React.ReactNode;
    estimatedRowHeight?: number;
    testId: string;
};

export function CustomVirtualisation({
    children,
    estimatedRowHeight = 75,
    index,
    testId,
}: CustomVirtualisationProps) {
    return (
        <RenderIfVisible
            key={index}
            defaultHeight={estimatedRowHeight}
            rootElement="tr"
            placeholderElement="td"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ width: '100%' }}
            data-testid={testId}
        >
            {children}
        </RenderIfVisible>
    );
}
