import { BankTransferTransactionKind } from '@dock/types-dock-partner';

import {
    getAllTypedParamValues,
    FilterReturnTypes,
    getBasicEmptyFilters,
    useFilters,
    BaseFilterParams,
} from '../../../../common';

export type BankTransferTransactionFiltersURLParams = BaseFilterParams<string> & {
    kind?: BankTransferTransactionKind[];
};

export type BankTransferTransactionPopUpFilterParams = Partial<
    Pick<BankTransferTransactionFiltersURLParams, 'kind' | 'minAmount' | 'maxAmount'>
>;

const getEmptyFilters = (params: URLSearchParams): BankTransferTransactionFiltersURLParams => ({
    ...getBasicEmptyFilters({ params }),
    kind: getAllTypedParamValues('status', params, BankTransferTransactionKind),
    searchBy: 'id',
});

enum SearchByIdField {
    id = 'id',
}

const customFieldsExtractor = (fields: BankTransferTransactionFiltersURLParams) => ({
    kind: fields.kind || [],
    searchBy: fields.searchBy || SearchByIdField.id,
});

export type UseBankTransferTransferTxsFilter = FilterReturnTypes<
    BankTransferTransactionFiltersURLParams,
    BankTransferTransactionPopUpFilterParams,
    SearchByIdField
>;

export const useBankTransferTransferTxsFilters = (): UseBankTransferTransferTxsFilter =>
    useFilters<BankTransferTransactionFiltersURLParams>(getEmptyFilters, customFieldsExtractor);
