import { createContext } from 'react';

import { UseProvideStatusModalsType } from './useProvideStatusModals';

const defaultValue: UseProvideStatusModalsType = {
    closeModal: () => {},
    handleChange: () => {},
    handleSubmit: () => {},
    isOpen: false,
    notification: { messages: [''], type: null },
    showErrorModal: () => {},
    showSuccessModal: () => {},
};

export const statusModalsContext = createContext<UseProvideStatusModalsType>(defaultValue);
