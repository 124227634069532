import { useLocation, useParams } from 'react-router-dom';

import { getTitle, TitleForSpecificLocation } from '../getTitle';

export function useTitleLocation(config: TitleForSpecificLocation[]) {
    const { pathname } = useLocation();
    const params = useParams();

    return getTitle(config, pathname, params);
}
