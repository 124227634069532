import { BankTransferTransactionKind } from '@dock/types-dock-partner';

import lang from '../../../../lang/bankTransferDetails';

export const transactionKindMap: Record<BankTransferTransactionKind, string> = {
    CROSSBORDER: lang.FILTER.KIND.CROSSBORDER,
    INTERNAL: lang.FILTER.KIND.INTERNAL,
    SEPA: lang.FILTER.KIND.SEPA,
    TARGET2: lang.FILTER.KIND.TARGET2,
};
