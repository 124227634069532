import { Column, ColumnAlign } from '@dock/react';

import accountsLang from '../../../../lang/accounts';

export const accountsTableColumns: Column[] = [
    {
        key: 'accountName',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.ACCOUNT_NAME,
    },
    {
        key: 'accountId',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.ACCOUNT_ID,
    },
    {
        key: 'accountHolderId',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.ACCOUNT_HOLDER_ID,
    },
    {
        key: 'status',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.STATUS,
    },
    {
        key: 'createdTime',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.CREATED_TIME,
        sortable: true,
    },
    {
        align: ColumnAlign.RIGHT,
        key: 'availableBalance',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.AVAILABLE_BALANCE,
    },
    {
        key: 'currency',
        name: accountsLang.ACCOUNTS_TABLE.COLUMNS.CURRENCY,
    },
    { key: 'actions', name: '' },
];
