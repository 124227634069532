import { useParams } from 'react-router-dom';

import { PageContainer } from '@dock/react';

import { BankTransferTransactionDetailTable } from './components/BankTransferTransactionDetailTable';

export function BankTransferTransactionDetails() {
    const { id = '', transactionId = '' } = useParams();

    return (
        <PageContainer testId="bTTransactionDetailsPage">
            <BankTransferTransactionDetailTable id={id} transactionID={transactionId} />
        </PageContainer>
    );
}
