import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import type { z } from 'zod';

import { Input, TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import { gridInput } from '@dock/react-mui';
import { Currency } from '@dock/types-common';
import { A2ATransfersCreatePayment } from '@dock/types-dock-partner';
import { payA2ASchema, payA2ASchemaKeys } from '@dock/validation';

import a2aDetailsLang from '../../../../../lang/a2aDetails';
import commonLang from '../../../../../lang/common';

export type BasicDetailsStepFormValues = z.infer<typeof payA2ASchema>;

type BasicDetailsStepProps = {
    handleForm: (values: Partial<A2ATransfersCreatePayment>) => void;
    handleNextStep: () => void;
    defaultValues: BasicDetailsStepFormValues;
    handleCloseModal: () => void;
};

export function BasicDetailsStep({
    defaultValues,
    handleCloseModal,
    handleForm,
    handleNextStep,
}: BasicDetailsStepProps) {
    const methods = useForm<BasicDetailsStepFormValues>({
        defaultValues,
        resolver: zodResolver(payA2ASchema),
    });
    const { control, handleSubmit, watch } = methods;

    const onSubmitHandler = (values: BasicDetailsStepFormValues) => {
        handleForm({
            amount: { amount: values.amount, currency: values.currency },
            description: '',
            recipientId: values.recipientId,
        });
        handleNextStep();
    };

    const isSubmitButtonDisabled = Object.values(watch()).some((value) => !value);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container data-testid="basicDetailsStep">
                    <Grid item xs={8} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={a2aDetailsLang.PAY.AMOUNT}
                            name={payA2ASchemaKeys.amount}
                            isFullWidth
                            type="amount"
                            testId="amountInput"
                        />
                    </Grid>

                    <Grid item xs={3} sx={gridInput}>
                        <Input
                            label={commonLang.CURRENCY}
                            name={commonLang.CURRENCY}
                            value={Currency.EUR}
                            onChange={() => {}}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={a2aDetailsLang.PAY.RECIPIENT_ID}
                            name={payA2ASchemaKeys.recipientId}
                            isFullWidth
                            testId="recipientId"
                        />
                    </Grid>

                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={a2aDetailsLang.PAY.RECIPIENT_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>
                </Grid>

                <FormActionButtons
                    onCancelHandler={handleCloseModal}
                    isSubmitDisabled={isSubmitButtonDisabled}
                    submitButtonText={commonLang.NEXT}
                />
            </form>
        </FormProvider>
    );
}
