import { Grid, Paper } from '@mui/material';

import { DASH } from '@dock/common';
import {
    SimpleRow,
    CollapsableElement,
    useBoolean,
    TextWithCopyIcon,
    TextWithCopyIconContent,
    AmountSimpleRow,
} from '@dock/react';
import { transformDate } from '@dock/react-util';

import {
    LinkWithCopyIcon,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    interpolateParams,
} from '../../../common';
import lang from '../../../lang/a2aTransactionsDetails';
import { useA2APaymentDetails } from '../../../services';
import { transactionDetailColumns, transactionColumns } from './A2APaymentsDetailColumns';

const detailColumnsKeys = Object.keys(transactionDetailColumns);
const counterPartyColumnsKeys = Object.keys(transactionColumns);

export function A2APaymentDetailTable({ transactionId }: { transactionId: string }) {
    const [isDetailCollapsed, flipDetailCollapsable] = useBoolean(true);
    const [isCounterPartyCollapsed, flipCounterPartyCollapsable] = useBoolean(true);
    const [isPartyCollapsed, flipPartyCollapsable] = useBoolean(true);

    const { data, isFetching } = useA2APaymentDetails({
        transactionId,
    });

    const { amount, counterParty, datetimes, party, ...details } = data;
    const { id, recipientId, senderId, ...otherDetails } = details;
    const partyAccountLInk = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
        id: party.accountId,
    });
    const partyInstrumentLInk = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
        id: party.instrumentId,
    });
    const counterPartyAccountLInk = interpolateParams(ACCOUNT_DETAILS_TRANSACTIONS_ROUTE, {
        id: counterParty.accountId,
    });
    const counterPartyInstrumentLInk = interpolateParams(A2A_DETAILS_TRANSACTIONS_ROUTE, {
        id: counterParty.instrumentId,
    });

    const createdDate =
        datetimes.created === '-' ? DASH : transformDate(new Date(datetimes?.created));

    return (
        <>
            <Paper sx={{ mb: '40px' }}>
                <CollapsableElement
                    isCollapsed={isDetailCollapsed}
                    title={lang.TRANSACTIONS_TABLE.PAYMENT_EVENT_DETAILS}
                    flip={flipDetailCollapsable}
                    columns={detailColumnsKeys.length}
                    isLoading={isFetching}
                >
                    <SimpleRow
                        label={transactionDetailColumns.id}
                        value={
                            <TextWithCopyIcon text={id}>
                                <TextWithCopyIconContent text={id} />
                            </TextWithCopyIcon>
                        }
                    />
                    {Object.entries({ recipientId, senderId }).map(
                        ([key, value]) =>
                            !!value && (
                                <SimpleRow
                                    key={key}
                                    label={
                                        transactionDetailColumns[
                                            key as keyof typeof transactionDetailColumns
                                        ]
                                    }
                                    value={
                                        <TextWithCopyIcon text={value}>
                                            <LinkWithCopyIcon
                                                href={interpolateParams(
                                                    A2A_DETAILS_TRANSACTIONS_ROUTE,
                                                    {
                                                        id: value,
                                                    }
                                                )}
                                                text={value}
                                            />
                                        </TextWithCopyIcon>
                                    }
                                />
                            )
                    )}

                    {Object.entries({
                        ...otherDetails,
                    }).map(
                        ([key, value]) =>
                            !!value && (
                                <SimpleRow
                                    key={key}
                                    label={
                                        transactionDetailColumns[
                                            key as keyof typeof transactionDetailColumns
                                        ]
                                    }
                                    value={value}
                                />
                            )
                    )}
                    <AmountSimpleRow
                        label={transactionDetailColumns.amount}
                        amount={amount.amount}
                        currency={amount.currency}
                    />
                    <SimpleRow label={transactionDetailColumns.created} value={createdDate} />
                </CollapsableElement>
            </Paper>
            <Grid sx={{ mb: '40px' }} container spacing="16px">
                <Grid item xs={6}>
                    <Paper sx={{ my: '16px' }}>
                        <CollapsableElement
                            isCollapsed={isCounterPartyCollapsed}
                            title={lang.TRANSACTIONS_TABLE.SENDER}
                            flip={flipCounterPartyCollapsable}
                            columns={counterPartyColumnsKeys.length}
                            isLoading={isFetching}
                        >
                            <SimpleRow
                                label={transactionColumns.accountLabel}
                                value={party.accountLabel}
                            />
                            <SimpleRow
                                label={transactionColumns.accountId}
                                value={
                                    <TextWithCopyIcon text={party.accountId}>
                                        <LinkWithCopyIcon
                                            href={partyAccountLInk}
                                            text={party.accountId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                            <SimpleRow
                                label={transactionColumns.instrumentId}
                                value={
                                    <TextWithCopyIcon text={party.instrumentId}>
                                        <LinkWithCopyIcon
                                            href={partyInstrumentLInk}
                                            text={party.instrumentId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                        </CollapsableElement>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ my: '16px' }}>
                        <CollapsableElement
                            isCollapsed={isPartyCollapsed}
                            title={lang.TRANSACTIONS_TABLE.RECIPIENT}
                            flip={flipPartyCollapsable}
                            columns={counterPartyColumnsKeys.length}
                            isLoading={isFetching}
                        >
                            <SimpleRow
                                label={transactionColumns.accountLabel}
                                value={counterParty.accountLabel}
                            />
                            <SimpleRow
                                label={transactionColumns.accountId}
                                value={
                                    <TextWithCopyIcon text={counterParty.accountId}>
                                        <LinkWithCopyIcon
                                            href={counterPartyAccountLInk}
                                            text={counterParty.accountId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                            <SimpleRow
                                label={transactionColumns.instrumentId}
                                value={
                                    <TextWithCopyIcon text={counterParty.instrumentId}>
                                        <LinkWithCopyIcon
                                            href={counterPartyInstrumentLInk}
                                            text={counterParty.instrumentId}
                                        />
                                    </TextWithCopyIcon>
                                }
                            />
                        </CollapsableElement>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
