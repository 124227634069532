import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import type { CreateInstrumentData } from '@dock/types-dock-partner';
import type { z } from 'zod';

import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import { gridInput } from '@dock/react-mui';
import {
    createA2ABasicDetailsStepSchema,
    createA2ABasicDetailsStepSchemaKeys,
} from '@dock/validation';

import a2aLang from '../../../../../lang/a2a';
import commonLang from '../../../../../lang/common';

export type BasicDetailsStepFormValues = z.infer<typeof createA2ABasicDetailsStepSchema>;

type BasicDetailsStepProps = {
    handleForm: (values: Partial<CreateInstrumentData>) => void;
    handleNextStep: () => void;
    defaultValues: BasicDetailsStepFormValues;
    handleCloseModal: () => void;
};

export function BasicDetailsStep({
    defaultValues,
    handleCloseModal,
    handleForm,
    handleNextStep,
}: BasicDetailsStepProps) {
    const methods = useForm<BasicDetailsStepFormValues>({
        defaultValues,
        resolver: zodResolver(createA2ABasicDetailsStepSchema),
    });
    const { control, handleSubmit, watch } = methods;

    const onSubmitHandler = (values: BasicDetailsStepFormValues) => {
        handleForm(values);
        handleNextStep();
    };

    const isSubmitButtonDisabled = Object.values(watch()).some((value) => !value);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container data-testid="basicDetailsStep">
                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.ACCOUNT_ID}
                            name={createA2ABasicDetailsStepSchemaKeys.accountId}
                            isFullWidth
                            testId="accountIdInput"
                        />
                    </Grid>

                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={a2aLang.CREATE_NEW.ACCOUNT_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.PRODUCT_ID}
                            name={createA2ABasicDetailsStepSchemaKeys.definitionId}
                            isFullWidth
                            testId="productIdInput"
                        />
                    </Grid>

                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={a2aLang.CREATE_NEW.PRODUCT_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={a2aLang.CREATE_NEW.HOLDER_ID}
                            name={createA2ABasicDetailsStepSchemaKeys.holderId}
                            isFullWidth
                            testId="holderIdInput"
                        />
                    </Grid>

                    <Grid item xs={1} sx={gridInput}>
                        <TypographyWithTooltip
                            text={a2aLang.CREATE_NEW.HOLDER_ID_TOOLTIP}
                            variant="body2"
                            isWithIcon
                        />
                    </Grid>

                    <Grid item xs={11} sx={gridInput}>
                        <ControlledInput
                            control={control}
                            label={commonLang.TENANT_ID}
                            name={createA2ABasicDetailsStepSchemaKeys.tenantId}
                            isFullWidth
                            testId="tenantIdInput"
                        />
                    </Grid>
                </Grid>

                <FormActionButtons
                    onCancelHandler={handleCloseModal}
                    isSubmitDisabled={isSubmitButtonDisabled}
                    submitButtonText={commonLang.NEXT}
                />
            </form>
        </FormProvider>
    );
}
