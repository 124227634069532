import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
    ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
    ACCOUNTS_ROUTE,
    LOGIN_ROUTE,
    CARD_TRANSACTION_DETAILS_ROUTE,
    CARD_AUTHORIZATION_DETAILS_ROUTE,
    ACCOUNT_DETAILS_PAYMENT_INST_ROUTE,
    CARD_DETAILS_TRANSACTIONS_ROUTE,
    CARD_DETAILS_AUTHORIZATION_ROUTE,
    A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_ROUTE,
    BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
    BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
    CARD_TRANSACTIONS_OVERVIEW_ROUTE,
    CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
    A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
    A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
    CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
    A2A_TRANSACTIONS_ROUTE,
    CARD_LIST_ROUTE,
    A2A_DETAILS_TRANSACTIONS_ROUTE,
    CARD_SPENDING_CONTROLS_ROUTE,
    CARD_SPENDING_CONTROL_CREATE_ROUTE,
    CARD_SPENDING_CONTROL_DETAILS_ROUTE,
    CARD_SPENDING_CONTROL_EDIT_ROUTE,
} from '../common';
import {
    A2APaymentScheduleTransactionsTable,
    A2ADetailsTransactionsTable,
    A2ATransactionDetails,
    Login,
    NotFound,
    Accounts,
    AccountDetailsTransactionsTable,
    AccountDetailsPayMethodsTable,
    Cards,
    CardAuthorizationDetails,
    CardTransactionDetails,
    CardDetailsTxsTable,
    CardDetailsAuthorizationsTable,
    RenderingError,
    BankTransfer,
    BankTransferTransactionDetails,
    BankTransferDetailsTransactionsTable,
    CardTxsOverviewPage,
    A2APaymentDetails,
    CardAuthsOverviewPage,
    A2A,
    CardAuthsEventsOverviewPage,
    CreateCardSpendingControlPage,
    CardSpendingControl,
    CardSpendingControlDetails,
    EditCardSpendingControlPage,
} from '../pages';
import { IsUserRedirectRoute, ProtectedRoute } from './components/CustomRoutes';
import { LocalHelmet } from './components/LocalHelmet';
import { Root } from './components/Root';

const router = createBrowserRouter([
    {
        children: [
            {
                children: [
                    {
                        element: (
                            <ProtectedRoute>
                                <Accounts />
                            </ProtectedRoute>
                        ),
                        path: ACCOUNTS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <AccountDetailsTransactionsTable />
                            </ProtectedRoute>
                        ),
                        path: ACCOUNT_DETAILS_TRANSACTIONS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <AccountDetailsPayMethodsTable />
                            </ProtectedRoute>
                        ),
                        path: ACCOUNT_DETAILS_PAYMENT_INST_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <BankTransfer />
                            </ProtectedRoute>
                        ),
                        path: BANK_TRANSFERS_INSTRUMENTS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <BankTransferDetailsTransactionsTable />
                            </ProtectedRoute>
                        ),
                        path: BANK_TRANSFERS_INSTRUMENTS_DETAILS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <BankTransferTransactionDetails />
                            </ProtectedRoute>
                        ),
                        path: BANK_TRANSFERS_TRANSACTION_DETAIL_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <Cards />
                            </ProtectedRoute>
                        ),
                        path: CARD_LIST_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardSpendingControl />
                            </ProtectedRoute>
                        ),
                        path: CARD_SPENDING_CONTROLS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardSpendingControlDetails />
                            </ProtectedRoute>
                        ),
                        path: CARD_SPENDING_CONTROL_DETAILS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardDetailsTxsTable />
                            </ProtectedRoute>
                        ),
                        path: CARD_DETAILS_TRANSACTIONS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardDetailsAuthorizationsTable />
                            </ProtectedRoute>
                        ),
                        path: CARD_DETAILS_AUTHORIZATION_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardTransactionDetails />
                            </ProtectedRoute>
                        ),
                        path: CARD_TRANSACTION_DETAILS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardAuthorizationDetails />
                            </ProtectedRoute>
                        ),
                        path: CARD_AUTHORIZATION_DETAILS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <A2A />
                            </ProtectedRoute>
                        ),
                        path: A2A_TRANSACTIONS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <A2ADetailsTransactionsTable />
                            </ProtectedRoute>
                        ),
                        path: A2A_DETAILS_TRANSACTIONS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <A2APaymentScheduleTransactionsTable />
                            </ProtectedRoute>
                        ),
                        path: A2A_DETAILS_SCHEDULE_PAYMENTS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <A2ATransactionDetails />
                            </ProtectedRoute>
                        ),
                        path: A2A_DETAILS_TRANSACTIONS_DETAILS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <A2APaymentDetails />
                            </ProtectedRoute>
                        ),
                        path: A2A_DETAILS_SCHEDULE_PAYMENTS_DETAILS_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardTxsOverviewPage />
                            </ProtectedRoute>
                        ),
                        path: CARD_TRANSACTIONS_OVERVIEW_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardAuthsOverviewPage />
                            </ProtectedRoute>
                        ),
                        path: CARD_AUTHORIZATIONS_OVERVIEW_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CardAuthsEventsOverviewPage />
                            </ProtectedRoute>
                        ),
                        path: CARD_AUTHORIZATION_EVENTS_OVERVIEW_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <CreateCardSpendingControlPage />
                            </ProtectedRoute>
                        ),
                        path: CARD_SPENDING_CONTROL_CREATE_ROUTE,
                    },
                    {
                        element: (
                            <ProtectedRoute>
                                <EditCardSpendingControlPage />
                            </ProtectedRoute>
                        ),
                        path: CARD_SPENDING_CONTROL_EDIT_ROUTE,
                    },
                ],
                element: (
                    <ProtectedRoute>
                        <Root />
                    </ProtectedRoute>
                ),
                path: '/',
            },
            {
                element: (
                    <IsUserRedirectRoute>
                        <Login />
                    </IsUserRedirectRoute>
                ),
                path: LOGIN_ROUTE,
            },
            {
                element: <NotFound />,
                path: '*',
            },
        ],
        element: <LocalHelmet />,
        errorElement: <RenderingError />,
    },
]);

export function Routes() {
    return <RouterProvider router={router} />;
}
