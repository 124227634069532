import { ListItemIcon, SxProps } from '@mui/material';
import React from 'react';

type SidebarItemIconProps = {
    Icon: React.ReactNode;
    isNavbarOpen?: boolean | undefined;
    iconStyles?: SxProps;
};

export function SidebarItemIcon({ Icon, iconStyles, isNavbarOpen = true }: SidebarItemIconProps) {
    return (
        <ListItemIcon
            sx={{
                color: 'background.default',
                minWidth: 4,
                mr: isNavbarOpen ? 3 : 0,
                ...iconStyles,
            }}
        >
            {Icon}
        </ListItemIcon>
    );
}
