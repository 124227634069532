import { useAccountsList } from '../accounts';
import { useA2AList } from './a2a';

type UseAccountWithA2A = {
    a2aId?: string | undefined;
};

export function useAccountWithA2A({ a2aId = '' }: UseAccountWithA2A) {
    const {
        data: [a2aInstrument],
        ...a2aInstrumentService
    } = useA2AList({
        params: { id: [a2aId] },
    });

    const {
        data: [data],
        ...accountService
    } = useAccountsList({
        enabled: !!a2aInstrument?.accountId,
        params: { id: [a2aInstrument?.accountId ?? ''] },
    });

    return {
        data,
        isError: a2aInstrumentService.isError || accountService.isError,
        isFetching: a2aInstrumentService.isFetching || accountService.isFetching,
    };
}
