import { Box } from '@mui/material';

import { prefillDatesFromAlways } from '@dock/common';
import {
    DateRangeDropDown,
    FilterButton,
    OptionType,
    MultipleChoiceSearch,
    SimpleTable,
} from '@dock/react';
import { useModal } from '@dock/react-hooks';

import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import cardsLang from '../../../../lang/cards';
import commonLang from '../../../../lang/common';
import { useCardsList } from '../../../../services';
import { CardListFilters } from './CardListFilter';
import { cardsTableColumns } from './cardsTableColumn';
import { CardsTableRow } from './CardsTableRow';
import { transformCardsListParams } from './transformCardsListParams';
import { useCardsListFilters, CardsListSearchFields } from './useCardsListFilters';

const searchOptions: OptionType<CardsListSearchFields>[] = [
    {
        label: cardsLang.SEARCH_OPTION.CARD_ID,
        value: CardsListSearchFields.cardId,
    },
    {
        label: cardsLang.SEARCH_OPTION.EXTERNAL_REFERENCE,
        value: CardsListSearchFields.externalReference,
    },
];

export function CardsListTable() {
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSearchSelect,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useCardsListFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useCardsList({
        params: transformCardsListParams(filters),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <MultipleChoiceSearch
                    label={commonLang.SEARCH}
                    initValue={filters.text || ''}
                    handleSearch={handleSearch}
                    onChange={handleSearchSelect}
                    searchBy={filters.searchBy || CardsListSearchFields.cardId}
                    options={searchOptions}
                />

                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />
                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <CardListFilters
                onApply={handlePopUpFilter(setOpen)}
                handleClose={setClose}
                isOpen={isOpen}
                fields={{
                    status: filters.status || [],
                }}
            />
            <SimpleTable
                columns={cardsTableColumns}
                RowComponent={CardsTableRow}
                isLoading={isFetching}
                rows={data}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                onSortClick={handleSortOrder}
                sorting={sorting}
            />
        </>
    );
}
